import _ from 'lodash'
import { EnumType } from 'json-to-graphql-query'
import { feature } from '@turf/helpers'
import { JSONSchema7 } from 'json-schema'

// constants
import {
  ISSUE_STATUS,
  DEFAULT_ISSUE_MAP_LAYER_SETTINGS,
  ISSUE_FILTER_FIELDS,
  ISSUE_LIST_ALLOWED_TYPES,
  X_FORM_DATA_FORMAT_TYPE,
  ISSUE_FILTER,
  ISSUE_TASK_TYPES,
  ISSUE_TYPES,
  ISSUE_SUB_TASK_STATUS_CLOSED_LABEL,
  ISSUE_STATUS_IN_PROGRESS,
} from 'constants/issue'
import { URLS } from 'constants/route'
import {
  GALLERY_LIST_FILTER_TYPES,
  ENTITIES,
  SPEC_PARAMETERS_TYPES,
  NO_VALUE_PLACEHOLDER,
} from 'constants/common'
import { GEOJSON_TYPES } from 'constants/map'
import { DEFAULT_QUICK_DATETIME_PICKER_VALUE } from 'constants/datetime'
import {
  DATE_RANGE_FILTER_VALUES,
  ISSUE_FORM_FILTER_TYPE,
  PROPERTY_VARIABLE_FORMATS,
} from 'constants/filter'
import { FORM_QUESTION_TYPES } from 'constants/formBuilder'

// utils
import {
  getRouteUrlWithValues,
  getFlattenedObject,
  sanitizeString,
  switchcaseF,
  displayTime,
  getConnectedStringFromArray,
  switchcase,
  isInvalidValue,
} from 'helpers/utils'
import { showCrudResponseMessage } from 'helpers/message'
import { reportErrors } from 'helpers/log'
import { colourToColourArr } from 'helpers/colour'
import { getUserOptionLabel } from 'components/common/UsersPicker'
import { getCommonFilterSpecs, transformDateFilterValue } from 'helpers/filter'
import { findUser, listEnumTypeDecorator } from 'helpers/graphql'
import { validateGeojson } from 'helpers/geojson'
import { getDeckLayerClass } from 'components/map/layers/deckLayers'
import { getFormBuilderWidgetType, getEnumOptions } from 'helpers/formBuilder'

import type { Timezone } from 'types/datetime'
import type {
  IssueFlattenedProperties,
  XFormSpec,
  IssueTaskType,
  AssigneeOption,
  Issue,
  SeverityOption,
  StatesParameter,
  IssueTrigger,
  IssueSubject,
  IssueAnnotations,
  IssueAssignee,
  IssueGeojson,
  IssueFormValue,
  IssueResultItem,
  JSONFormBody,
  XFormReference,
  MediaKey,
  DataCollectionFormMedia,
  DataCollectionFormMediaView,
  IssueType,
  IssueStatus,
  StatusOption,
  SubjectRules,
  IssueStateParameter,
  IssueStateData,
  DataCollectionForm,
} from 'types/issue'
import type { Filters, FilterSpec } from 'types/filter'
import type {
  Option,
  Options,
  Payload,
  SpecParams,
  SpecificationParameters,
} from 'types/common'
import type { User } from 'types/user'
import type {
  FormQuestionType,
  JSONFormSchemaProperties,
  JSONFormWidgetUiSchema,
} from 'types/formBuilder'
import type Layer from 'components/map/layers/deckLayers/baseLayer'
import type { MapLayerProfileHandler } from 'types/map'
import { IssueTaskForms } from 'recoilStore/issuesStore'
import {
  IssueStateDataCollectionParameter,
  IssueTaskDataCollectionForm,
} from 'types/graphql'
import {
  ColumnsWithGroupable,
  ColumnWithGroupable,
} from 'components/common/DataTable/useDataTableColumns'
import {
  FORM_RESPONSE_PROPERTIES_FIELD_KEY,
  GROUP_BY_FORM_FIELD,
} from 'routers/pages/IssueGallery/constants'

export const isIssueTask = (issueType?: IssueType): boolean =>
  issueType === ISSUE_TYPES.TASK

export const isIssueSubTask = (issueType?: IssueType): boolean =>
  issueType === ISSUE_TYPES.SUBTASK

export const getIssueStatusTypes = (
  issueType?: IssueType,
  dataCollectionFormComplete?: boolean
): Record<IssueStatus, string> => {
  return {
    [ISSUE_STATUS.open]:
      isIssueSubTask(issueType) || isIssueTask(issueType)
        ? dataCollectionFormComplete
          ? ISSUE_SUB_TASK_STATUS_CLOSED_LABEL
          : 'Start'
        : 'Open',
    [ISSUE_STATUS.closed]:
      isIssueSubTask(issueType) || isIssueTask(issueType)
        ? ISSUE_SUB_TASK_STATUS_CLOSED_LABEL
        : 'Closed',
    [ISSUE_STATUS.data_collection]:
      dataCollectionFormComplete && isIssueTask(issueType)
        ? ISSUE_SUB_TASK_STATUS_CLOSED_LABEL
        : ISSUE_STATUS_IN_PROGRESS,
    [ISSUE_STATUS.sub_tasks]: ISSUE_STATUS_IN_PROGRESS,
  }
}

export const getIssueStatusOptions = (
  issueType?: IssueType
): StatusOption[] => {
  const isSubTask = isIssueSubTask(issueType)
  const issueStatusTypes = getIssueStatusTypes(issueType)
  const inProgressOption = {
    label: ISSUE_STATUS_IN_PROGRESS,
    colour: '#3BCDA8',
    ...(isSubTask
      ? { value: ISSUE_STATUS.data_collection }
      : {
          value: ISSUE_STATUS_IN_PROGRESS,
          variables: [ISSUE_STATUS.data_collection, ISSUE_STATUS.sub_tasks],
        }),
  }

  return [
    {
      value: ISSUE_STATUS.open,
      label: issueStatusTypes[ISSUE_STATUS.open],
      colour: '#3BCDA8',
    },
    inProgressOption,
    {
      value: ISSUE_STATUS.closed,
      label: issueStatusTypes[ISSUE_STATUS.closed],
      colour: '#10466F',
    },
  ]
}

export const getIssueStatusLabel = (
  status: IssueStatus,
  issueType?: IssueType,
  dataCollectionFormComplete?: boolean
): string | undefined => {
  if (!status) return undefined

  const issueStatusTypes = getIssueStatusTypes(
    issueType,
    dataCollectionFormComplete
  )

  return issueStatusTypes[status] || status
}

export const getIssueStatusOption = (
  status: IssueStatus,
  issueType: IssueType,
  dataCollectionFormComplete: boolean
): Option | undefined => {
  if (!status) return undefined

  const label = getIssueStatusLabel(
    status,
    issueType,
    dataCollectionFormComplete
  )
  const statusOptions = getIssueStatusOptions(issueType)
  return _.find(statusOptions, { label })
}

export const getIssueStatusFilterValue = (
  values: IssueStatus[]
): IssueStatus[] => {
  const issueStatusOptions = _.keyBy(getIssueStatusOptions(), 'value')
  return _.flatten(values).flatMap(v => issueStatusOptions[v]?.variables || v)
}

export const getIssueFilterConditions = (
  filters: Filters
): { conditions: Filters } => {
  return {
    conditions: _(filters)
      .map((value, key) => {
        const field = ISSUE_FILTER_FIELDS[key]
        if (!field) return undefined

        const payload =
          key === ISSUE_FILTER.modifiedAfter ? value : { [key]: value }
        return { field: new EnumType(field), ...payload }
      })
      .compact()
      .value(),
  }
}

export const getSeverityOption = ({
  options,
  severity,
  key = 'value',
  strict = true,
  issue,
}: {
  options: SeverityOption[]
  severity: number
  key?: string
  strict?: boolean
  issue: Issue
}): SeverityOption => {
  const sortedSeverityOptions = _.sortBy(options, [key])
  const minSeverityOption = _.first(sortedSeverityOptions) || {}
  const maxSeverityOption = _.last(sortedSeverityOptions) || {}
  const isValueKey = key === 'value'
  const value = isValueKey ? _.toInteger(severity) : severity

  const isValueInvalid = _.some(sortedSeverityOptions, { value })

  if (strict && isValueKey && isValueInvalid) {
    reportErrors(
      `[Issue]Severity value (${severity}) is invalid. ${JSON.stringify(issue)}`
    )
  }

  let result = _.find(sortedSeverityOptions, { [key]: value })

  if (!result) {
    if (value < minSeverityOption.value) {
      result = minSeverityOption
    } else if (value > maxSeverityOption.value) {
      result = maxSeverityOption
    }
  }
  return result || {}
}

export const getIssueWorkflowState = (
  issue: Issue
): { workflowName: string; workflowId: string; workflowViewUrl: string } => {
  const ruleReference = _.get(issue, 'trigger.triggerContext.rule') || {}
  const { id: workflowId, name: workflowName } = ruleReference || {}

  const workflowViewUrl = workflowId
    ? getRouteUrlWithValues(URLS.WORKFLOW_VIEW, {
        id: workflowId,
      })
    : ''

  return { workflowName, workflowId, workflowViewUrl }
}

const getAssigneesOption = (
  assignees: AssigneeOption[],
  assignee: IssueAssignee
): AssigneeOption => {
  const { userReference } = assignee || {}
  const { userId } = userReference || {}
  return findUser(assignees, userId)
}

export const getNormalizedProperties = (properties: Payload): Payload => {
  return _.reduce(
    properties,
    (result, value, key) => {
      return {
        ...result,
        ...(!_.isNil(value) && {
          [key]: _.isString(value) ? sanitizeString(value) : value,
        }),
      }
    },
    {}
  )
}

/**
 * Transforms 'displayName' of a property.
 * Important to transform it in the same way in all places where we need it
 */
const getFormResponsePropertyName = (displayName: string) =>
  _.camelCase(displayName)

/** Gets value from the form response using provided xFormRef */
const getFormResponseValue = (
  xFormRef: string,
  formResponse?: Record<string, string>
) => {
  const path = xFormRef.split('/').slice(2)
  return _.get(formResponse, path)
}

const getIssueRow = ({
  issue,
  issueSeverityOptions,
  issueAssigneesOptions,
  xFormsSpecs,
}: {
  issue: Issue
  issueSeverityOptions: SeverityOption[]
  issueAssigneesOptions: AssigneeOption[]
  xFormsSpecs?: IssueTaskForms
}): {
  taskType: string
  statesData?: IssueStateData[]
  stateData?: IssueStateData
  statesParameter: StatesParameter
  stateParameter?: IssueStateParameter
  trigger: IssueTrigger
  subject: IssueSubject
  properties: Payload
  id: string
  assigneeOption: AssigneeOption
  severityOption: SeverityOption
  annotations: IssueAnnotations
  workflowViewUrl: string
  deleted: boolean
  formResponseProperties?: Record<string, string>
} => {
  const {
    id,
    lastModifiedAt,
    subject,
    severity,
    annotations,
    assignee,
    statesData,
    statesParameter,
    trigger,
    taskType,
    deleted,
    ...rest
  } = issue

  const subjectReference = subject?.reference

  // TODO: revisit this when the severity options have been changed, display severity with value [4..100] as “Critical” for now https://sensorup.atlassian.net/browse/SEN-3405

  const severityOption = getSeverityOption({
    options: issueSeverityOptions,
    severity,
    strict: false,
    issue,
  })
  const { acknowledged, acknowledgedAt } = assignee || {}
  const assigneeOption = getAssigneesOption(issueAssigneesOptions, assignee)

  const { workflowId, workflowName, workflowViewUrl } =
    getIssueWorkflowState(issue)

  const properties = {
    ...rest,
    name: id,
    time: lastModifiedAt,
    subjectReference,
    asset: subject?.assetReference?.asset?.displayName,
    workflowName,
    workflowId,
    assignee: assigneeOption?.label,
    acknowledged,
    acknowledgedAt,
    severity: severityOption?.label,
    statusLabel: getIssueStatusLabel(rest.status),
  }

  const validAnnotations = _.reject(annotations, { deleted: true })
  const stateData = _.first(statesData)
  const stateParameter = _.first(statesParameter)

  // Getting the form info
  const { filterQuestions } =
    xFormsSpecs?.[
      (stateParameter as IssueStateDataCollectionParameter)
        ?.dataCollectionFormReference
    ] || {}

  // If it's a subtask, try to get the list of user answers from the form response data.
  // Then we will store these answers in a subtask to display on a table
  const formResponseProperties = (filterQuestions || []).reduce(
    (acc, questionData) => {
      const { displayName, xFormRef } = questionData
      const propertyKey = getFormResponsePropertyName(displayName)

      return {
        ...acc,
        [propertyKey]: getFormResponseValue(
          xFormRef,
          stateData?.dataCollectionResponses
        ),
      }
    },
    {} as Record<string, string>
  )

  return {
    deleted,
    taskType,
    statesData,
    statesParameter,
    // Adding new fields so we can use them in the DataTable
    stateData,
    stateParameter,
    trigger,
    subject,
    properties: getNormalizedProperties(properties),
    id,
    assigneeOption,
    severityOption,
    annotations: validAnnotations || [],
    workflowViewUrl,
    assetId: subject?.assetReference?.asset?.id,
    // Save user answers inside the subtask
    ...(!_.isEmpty(formResponseProperties) && {
      [FORM_RESPONSE_PROPERTIES_FIELD_KEY]: formResponseProperties,
    }),
  }
}

export const getIssueGeojsonRow =
  (
    issueAssigneesOptions: AssigneeOption[],
    issueSeverityOptions: SeverityOption[],
    xFormsSpecs?: IssueTaskForms
  ) =>
  (issue: Issue): IssueGeojson | undefined => {
    if (_.isEmpty(issue)) return undefined

    const { subject, properties, severityOption, trigger, ...rest } =
      getIssueRow({
        issue,
        issueSeverityOptions,
        issueAssigneesOptions,
        xFormsSpecs,
      })

    const geometry = _.get(trigger, 'location')
    const geojsonValidationErrors = validateGeojson(feature(geometry))

    if (geojsonValidationErrors.length) {
      reportErrors(
        `The issue-${issue.id} doesn't have a valid trigger location(${geojsonValidationErrors[0].message})`,
        { issue: _.pick(issue, ['id', 'trigger']), geojsonValidationErrors }
      )
      return undefined
    }

    const observation = _.get(subject, 'assetReference.asset.observation')
    const { properties: observationProperties = {} } = observation || {}

    return {
      ...rest,
      type: GEOJSON_TYPES.Feature,
      properties,
      additionalProperties: {
        ...getFlattenedObject(observationProperties),
      },
      fillColour: colourToColourArr(severityOption?.colour),
      severityOption,
      geometry,
    }
  }

const getIssueRows =
  (getIssueFn: {
    (
      issueAssigneesOptions: AssigneeOption[],
      issueSeverityOptions: SeverityOption[],
      xFormsSpecs?: IssueTaskForms
    ): (issue: Issue) => IssueGeojson | undefined
  }) =>
  ({
    issueAssigneesOptions,
    issueSeverityOptions,
    issues,
    xFormsSpecs,
  }: {
    issueAssigneesOptions: AssigneeOption[]
    issueSeverityOptions: SeverityOption[]
    issues: Issue[]
    xFormsSpecs?: IssueTaskForms
  }): IssueGeojson[] =>
    _(issues)
      .map(getIssueFn(issueAssigneesOptions, issueSeverityOptions, xFormsSpecs))
      .compact()
      .value()

export const getIssueGeojsonRows = getIssueRows(getIssueGeojsonRow)

export const getIssueLayerId = (id: string): string => `issue-${id}`

export const generateIssueDeckLayer = ({
  layerId,
  layerData,
  issueSettings,
  profileHandler,
  timezone,
  highlightedObjectIndex,
}: {
  layerId: string
  layerData: IssueGeojson[]
  profileHandler: MapLayerProfileHandler
  issueSettings?: Payload
  timezone?: Timezone
  highlightedObjectIndex?: number
}): Layer => {
  const {
    name,
    isVisible,
    timeliness,
    type: layerType,
    profile: { type },
    ...rest
  } = _.defaults({}, issueSettings, DEFAULT_ISSUE_MAP_LAYER_SETTINGS)
  const id = getIssueLayerId(layerId)
  const mapLayer = {
    id,
    name,
    isVisible,
    type: layerType,
    timeliness,
    profile: { type },
    style: {
      [layerType]: rest,
    },
  }

  const newLayer = getDeckLayerClass({ mapLayer })
  return newLayer.renderLayer({
    layerData,
    profileHandler,
    timezone,
    highlightedObjectIndex,
  })
}

const getFilterKey = (key: string, keyAliases: Payload<string>): string => {
  return keyAliases[key] || key
}

export const getIssueFiltersSpecs = ({
  issueAssigneesOptions = [],
  issueSeverityOptions = [],
  allowedFilters = [],
  keyAliases = {},
  customizedSpecs = {},
  isSubtasksMode = false,
}: Partial<{
  issueAssigneesOptions: AssigneeOption[]
  issueSeverityOptions: SeverityOption[]
  allowedFilters: string[]
  keyAliases: Payload
  customizedSpecs: Payload
  isSubtasksMode?: boolean
}>): FilterSpec[] => {
  const specs = [
    {
      key: GALLERY_LIST_FILTER_TYPES.modifiedDatetime,
      enable: false,
      isClearable: false,
      isPureBackendFilter: true,
      getFilterValue: (
        values: (string | undefined)[],
        options: { timezone: string }
      ) => {
        const preparedRange = values.map(dateFilterValue =>
          transformDateFilterValue(dateFilterValue, options)
        )
        // Always default the startDate to 'Today'
        if (!preparedRange[0])
          preparedRange[0] = transformDateFilterValue(
            DATE_RANGE_FILTER_VALUES.today,
            options
          )

        return preparedRange
      },
    },
    {
      ...getCommonFilterSpecs(
        getFilterKey(GALLERY_LIST_FILTER_TYPES.status, keyAliases),
        { isMulti: true }
      ),
      options: getIssueStatusOptions(),
      ...customizedSpecs[GALLERY_LIST_FILTER_TYPES.status],
      getFilterValue: (values, { isEnum = false } = {}) => {
        const result = getIssueStatusFilterValue(values)

        return isEnum ? listEnumTypeDecorator(result) : result
      },
      icon: 'HiOutlineStatusOnline',
    },
    {
      ...getCommonFilterSpecs(
        getFilterKey(GALLERY_LIST_FILTER_TYPES.severity, keyAliases),
        { isMulti: true }
      ),
      options: issueSeverityOptions,
      ...customizedSpecs[GALLERY_LIST_FILTER_TYPES.severity],
      icon: 'HiOutlineExclamationCircle',
    },
    {
      ...getCommonFilterSpecs(
        getFilterKey(GALLERY_LIST_FILTER_TYPES.assignedToUsername, keyAliases),
        { isMulti: true }
      ),
      label: 'assignee',
      options: issueAssigneesOptions,
      formatOptionLabel: getUserOptionLabel(),
      ...customizedSpecs[GALLERY_LIST_FILTER_TYPES.assignedToUsername],
      icon: 'HiOutlineUserCircle',
    },
    {
      ...getCommonFilterSpecs(ISSUE_FORM_FILTER_TYPE),
      label: 'Form',
      isPureBackendFilter: true,
      type: ISSUE_FORM_FILTER_TYPE,
      icon: 'BsFileText',
      supportsSubtaskMode: false,
    },
  ]

  // Filter specs that are not supported
  const filteredSpecs = isSubtasksMode
    ? _.reject(specs, { supportsSubtaskMode: false })
    : specs

  return _.isEmpty(allowedFilters)
    ? filteredSpecs
    : _.filter(filteredSpecs, spec => _.includes(allowedFilters, spec.key))
}

export const getDefaultIssueFilters = (): Payload => {
  return {
    [GALLERY_LIST_FILTER_TYPES.modifiedDatetime]: [
      DEFAULT_QUICK_DATETIME_PICKER_VALUE,
    ],
    [GALLERY_LIST_FILTER_TYPES.status]: [ISSUE_STATUS.open],
  }
}

export const isIssueNotAcknowledgedByCurrentUser = (
  issue: IssueGeojson,
  currentUser: User
): boolean => {
  if (_.isEmpty(issue) || _.isEmpty(currentUser)) return false

  const {
    properties: { acknowledged },
    assigneeOption,
  } = issue
  return !acknowledged && currentUser.username === assigneeOption?.username
}

export const updateIssueItem = async ({
  status,
  updateFn,
  variables,
  updateEntity,
  onSuccess,
}: {
  status: string
  updateFn: (payload: Payload) => Promise<{ error?: string } & Issue>
  variables: Payload
  updateEntity: string
  onSuccess: (issue: Issue) => void
}): Promise<string | undefined> => {
  const entity = updateEntity || ENTITIES.issue
  const onlyToastOnErrors = true
  const { error, ...updatedIssue } = await updateFn(variables)
  showCrudResponseMessage({
    entity,
    status,
    error: error && { message: error },
    onlyToastOnErrors,
    subject: updatedIssue,
  })
  if (!error) {
    onSuccess(updatedIssue)
  }
  return error
}

export const isListAllowedIssue = (issue: Issue): boolean =>
  ISSUE_LIST_ALLOWED_TYPES.includes(issue?.type)

export const getResultForMultiSelect = ({
  value,
  isMulti,
  separator,
}: {
  value: string
  isMulti: boolean
  separator: string
}): string | string[] => {
  let newValue = value
  if (isMulti) {
    newValue = _.isArray(value) ? value : _.split(value, separator)
  }
  return newValue
}

export const getDisplayValue = ({
  value,
  options,
}: {
  value: string
  options: Options
}): string =>
  _.find(options, {
    key: value,
  })?.displayName || value

const getIssueFormResultValue = (
  issueSpecificationParameter: XFormSpec,
  originalValue: IssueFormValue,
  timezone: Timezone
) => {
  const { format, options, type, isMulti, separator } =
    issueSpecificationParameter
  if (_.isEmpty(originalValue)) return NO_VALUE_PLACEHOLDER

  return switchcaseF({
    [SPEC_PARAMETERS_TYPES.string]: () => {
      if (_.includes([X_FORM_DATA_FORMAT_TYPE.dateTime], format)) {
        return displayTime({
          datetime: originalValue,
          timezone,
        })
      }
      return originalValue
    },
    [SPEC_PARAMETERS_TYPES.enum]: () => {
      const value = getResultForMultiSelect({
        value: originalValue,
        isMulti,
        separator,
      })

      return isMulti
        ? getConnectedStringFromArray(
            _.map(value, v => getDisplayValue({ value: v, options }))
          )
        : getDisplayValue({ value, options })
    },
  })(() => originalValue)(type)
}

export const getIssueXFormResultList = ({
  issueFormSpecs,
  issueFormSpecsList,
  formResult,
  timezone,
  hideInvalidValues = true,
}: {
  issueFormSpecs: SpecificationParameters
  issueFormSpecsList: XFormSpec[]
  formResult: IssueFlattenedProperties
  timezone: Timezone
  hideInvalidValues?: boolean
}): IssueResultItem[] => {
  if (!issueFormSpecs) return formResult

  return _(issueFormSpecsList)
    .map(spec => {
      const { id } = spec
      const validSpec = issueFormSpecs[id] || spec
      const { displayName, format, visible, questionType } = validSpec
      const originalValue = formResult[id]
      const isVisible =
        format === PROPERTY_VARIABLE_FORMATS.image ? true : visible
      if (!isVisible || (hideInvalidValues && isInvalidValue(originalValue)))
        return undefined

      return {
        id,
        label: displayName,
        value: getIssueFormResultValue(validSpec, originalValue, timezone),
        questionType,
      }
    })
    .compact()
    .value()
}

const getDisplayedName = (
  enumOptions: Options<string>,
  value: string
): string => _.get(_.find(enumOptions, { value }), 'label', value)

export const getFormResultItem = ({
  value,
  key,
  properties,
  uischema,
}: {
  value: string
  key: string
  properties: JSONFormSchemaProperties
  uischema: JSONFormWidgetUiSchema
}):
  | {
      id: string
      label: string
      value: string
      questionType: FormQuestionType | undefined
      questionProps: Payload
    }
  | undefined => {
  const schema = properties[key]
  const questionProps = {
    schema,
    uischema: _.get(uischema, key),
  }
  const questionType = getFormBuilderWidgetType(questionProps)

  let enumOptions = [] as Options<string>

  if (questionType === FORM_QUESTION_TYPES.DROPDOWN) {
    const { items } = schema
    enumOptions = getEnumOptions(items ? schema.items : schema)
  }

  if (questionType === FORM_QUESTION_TYPES.HEADING) {
    return undefined
  }

  return {
    id: key,
    label: schema?.title,
    value: _.isEmpty(enumOptions)
      ? value
      : _.isArray(value)
      ? _.map(value, v => getDisplayedName(enumOptions, v))
      : getDisplayedName(enumOptions, value),
    questionType,
    questionProps,
  }
}

const getPropertiesFromDependencies = (
  dependencies: JSONSchema7['dependencies']
) => {
  if (!dependencies) return {}

  const keys = Object.keys(dependencies)

  return keys.reduce((acc, key) => {
    const { oneOf = [] } = (dependencies[key] || {}) as {
      oneOf?: JSONSchema7[]
    }

    oneOf.forEach(({ properties }) => {
      acc = {
        ...acc,
        ..._.omit(properties, [key]),
      }
    })

    return acc
  }, {})
}

export const getAllJSONFormProperties = (
  jsonFormBody: JSONFormBody
): JSONFormSchemaProperties => {
  const {
    schema: { properties: rootProperties, dependencies },
  } = jsonFormBody
  const propertiesFromDeps = getPropertiesFromDependencies(dependencies)

  return {
    ...rootProperties,
    ...propertiesFromDeps,
  } as JSONFormSchemaProperties
}

export const getIssueJSONFormResultList = ({
  form,
  formResult,
}: {
  form: IssueTaskDataCollectionForm
  formResult: IssueFlattenedProperties
}): IssueResultItem[] => {
  if (!form?.jsonFormBody) return []

  const { jsonFormBody = {} } = form
  const { uischema } = jsonFormBody

  const properties = getAllJSONFormProperties(jsonFormBody as JSONFormBody)
  const widgetOrders = _.get(uischema, 'ui:order')

  return widgetOrders
    ? _(widgetOrders)
        .map(key =>
          getFormResultItem({
            value: _.get(formResult, key),
            key,
            properties,
            uischema,
          })
        )
        .compact()
        .value()
    : _(formResult)
        .map((value, key) =>
          getFormResultItem({ value, key, properties, uischema })
        )
        .compact()
        .value()
}

export const getDataCollectionFormReference = (issue: Issue): XFormReference =>
  _.get(_.first(issue?.statesParameter), 'dataCollectionFormReference')

export const getActiveIssues = (issues: Issue[]): Issue[] =>
  _.reject(issues, { deleted: true })

const getXFormGalleryViews = (
  imagesFormResults: IssueResultItem[]
): MediaKey[] => _.map(imagesFormResults, 'value')

const getJsonFormGalleryViews = (
  imagesFormResults: IssueResultItem[]
): MediaKey[] => {
  return _(imagesFormResults).flatMap('value').map('mediaKey').value()
}

export const NEED_IMAGES_RESOURCES_WIDGETS = [
  FORM_QUESTION_TYPES.IMAGE_UPLOADER,
  FORM_QUESTION_TYPES.SIGNATURE,
]

export const getImageFormResults = (items: IssueResultItem[]) =>
  _.filter(items, ({ questionType }: { questionType: FormQuestionType }) =>
    _.includes(NEED_IMAGES_RESOURCES_WIDGETS, questionType)
  )

export const getViewsFromIssueCollectionData = ({
  items = [],
  imageResources,
  taskType,
}: {
  items: IssueResultItem[]
  imageResources: DataCollectionFormMedia[]
  taskType: IssueTaskType
}): DataCollectionFormMediaView[] => {
  const imagesFormResults = _.filter(
    items,
    ({ questionType }: { questionType: FormQuestionType }) =>
      _.includes(NEED_IMAGES_RESOURCES_WIDGETS, questionType)
  )

  const fn = switchcase({
    [ISSUE_TASK_TYPES.COMPLETE_ODK_FORM]: getXFormGalleryViews,
    [ISSUE_TASK_TYPES.COMPLETE_JSON_FORM]: getJsonFormGalleryViews,
  })(_.noop)(taskType)

  const validImageResources = fn(imagesFormResults)

  if (_.isEmpty(validImageResources)) return []

  return _.reduce(
    imageResources,
    (
      result: DataCollectionFormMediaView[],
      formMedia: DataCollectionFormMedia
    ) => {
      const { mediaKey, downloadUrl } = formMedia
      return validImageResources.includes(mediaKey)
        ? [
            ...result,
            {
              ...formMedia,
              source: downloadUrl,
            },
          ]
        : result
    },
    []
  )
}

export const getVideosFromIssueCollectionData = ({
  prettifiedDataCollectionResponses = [],
  dataCollectionFormMedia,
}: {
  prettifiedDataCollectionResponses: IssueResultItem[]
  dataCollectionFormMedia: DataCollectionFormMedia[]
}): DataCollectionFormMediaView[] => {
  const collectedVideoMediaKeys = prettifiedDataCollectionResponses?.reduce(
    (acc, { questionType, value }) => {
      if (questionType === FORM_QUESTION_TYPES.VIDEO_UPLOADER)
        return [...acc, ..._.map(value, 'mediaKey')]
      return acc
    },
    []
  )

  return _.reduce<DataCollectionFormMedia[], DataCollectionFormMediaView[]>(
    dataCollectionFormMedia,
    (result, formMedia) => {
      const { mediaKey, downloadUrl } = formMedia

      return collectedVideoMediaKeys.includes(mediaKey)
        ? [
            ...result,
            {
              ...formMedia,
              source: downloadUrl,
            },
          ]
        : result
    },
    []
  )
}

export const SUB_TASK_OPTIONS = _.map(
  getIssueStatusOptions(ISSUE_TYPES.SUBTASK),
  option => ({
    ...option,
    displayName: 'Status',
  })
)

export const getIssueSubjectAssetFilters = (
  subjectRules: SubjectRules
): { assetFilters: Options } => {
  return {
    assetFilters: _(subjectRules)
      .reject({ assetFilter: null })
      .map(({ assetFilter }) => ({
        label: assetFilter?.displayName,
        value: assetFilter?.profile,
      }))
      .value(),
  }
}

/** Based on form.filterQuestions, build additional columns for the subtasks table */
export const getCustomColumnsForSubtasks = (
  xFormsSpecs: IssueTaskForms
): ColumnsWithGroupable => {
  const columnsData = _.reduce(
    xFormsSpecs,
    (acc, form) => {
      if (form?.filterQuestions) {
        form.filterQuestions.forEach(({ displayName }) => {
          const propertyKey = getFormResponsePropertyName(displayName)
          acc[propertyKey] = {
            header: displayName,
            field: `${FORM_RESPONSE_PROPERTIES_FIELD_KEY}.${propertyKey}`,
          }
        })
      }
      return acc
    },
    {} as Record<string, ColumnWithGroupable>
  )

  return _.values(columnsData)
}

/** Get isSubtasksMode true/false */
export const checkIsSubtasksMode = (
  tableGroupedBy: string | undefined
): boolean => tableGroupedBy === GROUP_BY_FORM_FIELD

/** Returns 'true' if an issue is 'fresh' and there was no any data collected yet */
export const isJsonFormInOpenStatus = ({
  jsonForm,
  currentIssueStatus,
  dataCollectionResponses,
}: {
  jsonForm?: JSONFormBody
  currentIssueStatus?: IssueStatus
  dataCollectionResponses?: SpecParams
}) =>
  jsonForm &&
  // There is no data collected or it's explicitly in the "Open" status
  ((!currentIssueStatus && !dataCollectionResponses) ||
    currentIssueStatus === ISSUE_STATUS.open)

export const prepareCollectedDataToDisplay = ({
  dataCollectionResponses,
  dataCollectionFormDetails,
  dataCollectionFormMedia,
  taskType,
}: {
  dataCollectionResponses: IssueFlattenedProperties
  dataCollectionFormDetails: IssueTaskDataCollectionForm | DataCollectionForm
  dataCollectionFormMedia: DataCollectionFormMedia[]
  taskType: IssueTaskType
}) => {
  const prettifiedDataCollectionResponses = getIssueJSONFormResultList({
    formResult: dataCollectionResponses,
    form: dataCollectionFormDetails,
  })

  const formResultImagesViews = getViewsFromIssueCollectionData({
    items: prettifiedDataCollectionResponses,
    imageResources: dataCollectionFormMedia,
    taskType,
  })
  const videos = getVideosFromIssueCollectionData({
    prettifiedDataCollectionResponses,
    dataCollectionFormMedia,
  })

  return { prettifiedDataCollectionResponses, formResultImagesViews, videos }
}
