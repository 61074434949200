import { selectorFamily } from 'recoil'
import _ from 'lodash'
import { gql } from '@apollo/client'
import { sessionUserGroupState } from 'recoilStore/userStore'

import { getApolloClient } from 'apollo/hooks/useInitApolloClient'
import { fetchAllRelayDataByApolloClient } from 'apollo/utils'
import { assetExternalIdState } from './assetTypeStore'
import { AssetEquipment } from '../types/graphql'

const GET_ASSET_EQUIPMENTS = gql`
  query AssetEquipmentsAll(
    $filter: AssetEquipmentFilter
    $properties: [ID!]
    $first: Int
    $after: String
  ) {
    assetEquipments {
      all(filter: $filter, first: $first, after: $after) {
        edges {
          node {
            assetReference
            id
            properties(properties: $properties)
            equipmentComponents {
              name
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`

export const siteEquipmentsQuery = selectorFamily({
  key: 'SiteEquipments',
  get:
    ({ parentAssetReference }: { parentAssetReference?: string }) =>
    async ({ get }) => {
      const apolloClient = getApolloClient()
      if (!apolloClient || !parentAssetReference) return []

      const assetExternalId = get(assetExternalIdState)
      const sessionGroup = get(sessionUserGroupState)
      const variables = {
        filter: {
          ...(sessionGroup ? { group: sessionGroup } : {}),
          propertyFilters: [
            {
              property: 'isAttributable',
              valueJson: true,
            },
          ],
          parentAssetReference,
        },
        properties: _.compact([
          'name',
          assetExternalId,
          'parentAssetReference',
        ]),
      }

      return fetchAllRelayDataByApolloClient<AssetEquipment>({
        first: 5000,
        client: apolloClient,
        query: GET_ASSET_EQUIPMENTS,
        variables,
        dataPath: ['assetEquipments', 'all'],
        processNode: node => ({
          ...node,
          value: node.assetReference,
          label: node.properties.name,
          components: _.map(node.equipmentComponents, component => ({
            equipmentComponentsName: component.name,
          })),
          labelExtras: {
            description: node.properties[assetExternalId],
          },
        }),
      })
    },
})
