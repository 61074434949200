/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Base64EncodedString: { input: any; output: any }
  Date: { input: any; output: any }
  DateTime: { input: any; output: any }
  GeoJSONCoordinates: { input: any; output: any }
  JSONObject: { input: any; output: any }
  Month: { input: any; output: any }
  ObservationCountsByType: { input: any; output: any }
  Time: { input: any; output: any }
  UrlEncodedString: { input: any; output: any }
  XFormForm: { input: any; output: any }
  XFormRef: { input: any; output: any }
  XFormReference: { input: any; output: any }
  XFormResult: { input: any; output: any }
  XFormXMLNode: { input: any; output: any }
  XFormXPath: { input: any; output: any }
  Year: { input: any; output: any }
}

export type AcknowledgeAssignment = {
  issueId: Scalars['ID']['input']
}

export type AcknowledgeDetectionInput = {
  detectionId: Scalars['ID']['input']
}

export type AcknowledgeEmissionObservationInput = {
  emissionObservationId: Scalars['ID']['input']
}

export type AcknowledgeEventHubNotificationError = MutationError & {
  __typename?: 'AcknowledgeEventHubNotificationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AcknowledgeEventHubNotificationInput = {
  notificationId: Scalars['ID']['input']
}

export type AcknowledgeEventHubNotificationResult = {
  __typename?: 'AcknowledgeEventHubNotificationResult'
  commandId: Scalars['ID']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AcknowledgeEventHubNotificationError>>
}

export enum ActionType {
  SendOgiInspection = 'SEND_OGI_INSPECTION',
  SendVfbReport = 'SEND_VFB_REPORT',
}

export type ActivateAssetTypeInput = {
  assetTypeId: Scalars['ID']['input']
}

export type AlarmEvent = {
  __typename?: 'AlarmEvent'
  externalId: Scalars['ID']['output']
  id: Scalars['ID']['output']
}

export type Annotation = {
  __typename?: 'Annotation'
  author?: Maybe<User>
  authorReference?: Maybe<Scalars['ID']['output']>
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deleted?: Maybe<Scalars['Boolean']['output']>
  index?: Maybe<Scalars['Int']['output']>
  modifiedAt?: Maybe<Scalars['DateTime']['output']>
  text?: Maybe<Scalars['String']['output']>
}

export type AnnotationSummary = {
  __typename?: 'AnnotationSummary'
  count?: Maybe<Scalars['Int']['output']>
}

export type ApproveDecisionsReviewRequestError = MutationError & {
  __typename?: 'ApproveDecisionsReviewRequestError'
  message: Scalars['String']['output']
  month?: Maybe<Scalars['String']['output']>
  siteId?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type ApproveDecisionsReviewRequestInput = {
  month: Scalars['Date']['input']
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ApproveDecisionsReviewRequestResult = {
  __typename?: 'ApproveDecisionsReviewRequestResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ApproveDecisionsReviewRequestError>>
}

export type Asset = {
  __typename?: 'Asset'
  /** The details of the asset's profile, should not be queried when multiple assets are returned. */
  assetProfile?: Maybe<AssetProfile>
  csv?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** The user consumable string representation for the asset */
  displayName: Scalars['String']['output']
  geoJSONFeature?: Maybe<GeoJsonFeature>
  geometry?: Maybe<GeoJsonGeometryInterface>
  /** Asset identifier (by convention this is the name property of the most recent observation dataset) */
  id: Scalars['ID']['output']
  isDeleted: Scalars['Boolean']['output']
  issueProperties?: Maybe<AssetIssueProperties>
  issueReferences?: Maybe<Array<IssueReference>>
  issueSubject: IssueSubject
  /** Last modification to the asset (any of the tracked property values) */
  modifiedAt?: Maybe<Scalars['DateTime']['output']>
  /** The merged state observation of the asset as GeoJSON */
  observation?: Maybe<Scalars['JSONObject']['output']>
  /** Reference to the profile */
  profile: Scalars['ID']['output']
  properties?: Maybe<Scalars['JSONObject']['output']>
  related?: Maybe<AssetRelationship>
  /** The user consumable secondary info for the asset (optional) */
  secondaryDisplayName?: Maybe<Scalars['String']['output']>
}

export type AssetCsvArgs = {
  properties?: InputMaybe<Array<Scalars['String']['input']>>
}

export type AssetPropertiesArgs = {
  only?: InputMaybe<Array<Scalars['String']['input']>>
}

export type AssetRelatedArgs = {
  params?: InputMaybe<Scalars['JSONObject']['input']>
  relationship?: InputMaybe<Scalars['ID']['input']>
}

export type AssetAndEventsCount = {
  __typename?: 'AssetAndEventsCount'
  assetReference?: Maybe<Scalars['String']['output']>
  camAssetReference?: Maybe<CamAssetReference>
  eventsCount?: Maybe<Scalars['Int']['output']>
}

export type AssetBasedReconciliationReport = {
  __typename?: 'AssetBasedReconciliationReport'
  camAssetReference?: Maybe<CamAssetReference>
  discrepancyPercent?: Maybe<Scalars['Float']['output']>
  emissionEventsCount?: Maybe<Scalars['Int']['output']>
  group: Scalars['String']['output']
  /** @deprecated Use span instead */
  month?: Maybe<Scalars['String']['output']>
  reviewRequest?: Maybe<ReconciliationDiscrepancyReviewRequest>
  simulationMethodology?: Maybe<EmissionSimulationMethodology>
  siteLevelEmission?: Maybe<ReconciliationReportEmission>
  siteMeasuredEmission?: Maybe<ReconciliationReportEmission>
  siteSimulatedEmission?: Maybe<ReconciliationReportEmission>
  sourceBottomUpEmission?: Maybe<ReconciliationReportEmission>
  sourceCategoryBasedReconciliationReports?: Maybe<
    Array<Maybe<SourceCategoryBasedReconciliationReport>>
  >
  sourceLevelEmission?: Maybe<ReconciliationReportEmission>
  sourceMeasuredEmission?: Maybe<ReconciliationReportEmission>
  span: Scalars['String']['output']
  unattributedEmissionEventsCount?: Maybe<Scalars['Int']['output']>
}

export type AssetBasedReconciliationReportDiscrepancyPercentArgs = {
  type: ReconciliationReportDiscrepancyType
}

export type AssetBasedReconciliationReportSiteLevelEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type AssetBasedReconciliationReportSiteMeasuredEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type AssetBasedReconciliationReportSiteSimulatedEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type AssetBasedReconciliationReportSourceBottomUpEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type AssetBasedReconciliationReportSourceCategoryBasedReconciliationReportsArgs =
  {
    sourceCategory?: InputMaybe<Scalars['String']['input']>
  }

export type AssetBasedReconciliationReportSourceLevelEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type AssetBasedReconciliationReportSourceMeasuredEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type AssetBasedReconciliationReportConnection = {
  __typename?: 'AssetBasedReconciliationReportConnection'
  edges?: Maybe<Array<AssetBasedReconciliationReportEdge>>
  pageInfo?: Maybe<PageInfo>
}

export type AssetBasedReconciliationReportEdge = {
  __typename?: 'AssetBasedReconciliationReportEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: AssetBasedReconciliationReport
}

export type AssetBasedReconciliationReportFilter = {
  group?: InputMaybe<Scalars['String']['input']>
}

export type AssetComponentEdge = {
  __typename?: 'AssetComponentEdge'
  node?: Maybe<CamAsset>
}

export type AssetComponentFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  componentId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Array<Scalars['String']['input']>>
  parentAssetReference?: InputMaybe<Array<Scalars['String']['input']>>
}

export type AssetComponentMutationError = MutationError & {
  __typename?: 'AssetComponentMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetComponentMutationResult = {
  __typename?: 'AssetComponentMutationResult'
  component?: Maybe<CamAsset>
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetComponentMutationError>>
}

export type AssetComponentSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<AssetComponentSortField>
}

export enum AssetComponentSortField {
  Name = 'NAME',
}

export type AssetComponents = {
  __typename?: 'AssetComponents'
  all?: Maybe<AssetComponentsConnection>
  byId?: Maybe<CamAsset>
}

export type AssetComponentsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetComponentFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<AssetComponentSortBy>
}

export type AssetComponentsByIdArgs = {
  componentId: Scalars['ID']['input']
}

export type AssetComponentsConnection = {
  __typename?: 'AssetComponentsConnection'
  edges?: Maybe<Array<Maybe<AssetComponentEdge>>>
  pageInfo: PageInfo
}

export type AssetConfig = {
  __typename?: 'AssetConfig'
  id: Scalars['ID']['output']
  value?: Maybe<Scalars['JSONObject']['output']>
}

export type AssetConfigMutationError = MutationError & {
  __typename?: 'AssetConfigMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetConfigMutationResult = {
  __typename?: 'AssetConfigMutationResult'
  config?: Maybe<AssetConfig>
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetConfigMutationError>>
}

export type AssetConfigs = {
  __typename?: 'AssetConfigs'
  assetStateViews?: Maybe<Scalars['JSONObject']['output']>
  config?: Maybe<AssetConfig>
  configs?: Maybe<Array<Maybe<AssetConfig>>>
}

export type AssetConfigsConfigArgs = {
  id: Scalars['ID']['input']
}

export type AssetDownload = {
  __typename?: 'AssetDownload'
  profile: Scalars['ID']['output']
  relationships?: Maybe<Array<Scalars['ID']['output']>>
}

export type AssetEdge = {
  __typename?: 'AssetEdge'
  node?: Maybe<Asset>
}

export type AssetEquipment = CamAssetIf & {
  __typename?: 'AssetEquipment'
  active: Scalars['Boolean']['output']
  assetId: Scalars['ID']['output']
  assetReference: Scalars['ID']['output']
  assetTypeId: Scalars['ID']['output']
  equipmentComponents?: Maybe<Array<AssetEquipmentComponent>>
  geometry?: Maybe<GeoJsonGeometryInterface>
  geometryJson?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  observation?: Maybe<Scalars['JSONObject']['output']>
  parent?: Maybe<CamAsset>
  properties?: Maybe<Scalars['JSONObject']['output']>
  property?: Maybe<Scalars['JSONObject']['output']>
}

export type AssetEquipmentObservationArgs = {
  properties?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type AssetEquipmentPropertiesArgs = {
  properties?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type AssetEquipmentPropertyArgs = {
  property: Scalars['ID']['input']
}

export type AssetEquipmentComponent = {
  __typename?: 'AssetEquipmentComponent'
  name?: Maybe<Scalars['String']['output']>
}

export type AssetEquipmentEdge = {
  __typename?: 'AssetEquipmentEdge'
  node?: Maybe<AssetEquipment>
}

export type AssetEquipmentFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  equipmentId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Array<Scalars['String']['input']>>
  parentAssetReference?: InputMaybe<Array<Scalars['String']['input']>>
  propertyFilters?: InputMaybe<Array<CamAssetPropertyFilter>>
  siteId?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type AssetEquipmentMutationError = MutationError & {
  __typename?: 'AssetEquipmentMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetEquipmentMutationResult = {
  __typename?: 'AssetEquipmentMutationResult'
  correlationId: Scalars['ID']['output']
  equipment?: Maybe<CamAsset>
  errors?: Maybe<Array<AssetEquipmentMutationError>>
}

export type AssetEquipmentReference = {
  __typename?: 'AssetEquipmentReference'
  equipment?: Maybe<AssetEquipment>
  equipmentId: Scalars['ID']['output']
}

export type AssetEquipmentSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<AssetEquipmentSortField>
}

export enum AssetEquipmentSortField {
  Name = 'NAME',
}

export type AssetEquipments = {
  __typename?: 'AssetEquipments'
  all?: Maybe<AssetEquipmentsConnection>
  byId?: Maybe<AssetEquipment>
}

export type AssetEquipmentsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetEquipmentFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<AssetEquipmentSortBy>
}

export type AssetEquipmentsByIdArgs = {
  equipmentId: Scalars['ID']['input']
}

export type AssetEquipmentsConnection = {
  __typename?: 'AssetEquipmentsConnection'
  edges?: Maybe<Array<Maybe<AssetEquipmentEdge>>>
  pageInfo: PageInfo
}

export type AssetFilter = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type AssetIssueProperties = {
  __typename?: 'AssetIssueProperties'
  count: Scalars['Int']['output']
  countOfStatus: Scalars['Int']['output']
  latestStatus?: Maybe<IssueStatus>
}

export type AssetIssuePropertiesCountOfStatusArgs = {
  status: IssueStatus
}

export type AssetMutationError = MutationError & {
  __typename?: 'AssetMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetMutationResult = {
  __typename?: 'AssetMutationResult'
  asset?: Maybe<CamAsset>
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetMutationError>>
}

export type AssetPipelineEdge = {
  __typename?: 'AssetPipelineEdge'
  node?: Maybe<CamAsset>
}

export type AssetPipelineFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Array<Scalars['String']['input']>>
  parentAssetReference?: InputMaybe<Array<Scalars['String']['input']>>
  pipelineId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type AssetPipelineMutationError = MutationError & {
  __typename?: 'AssetPipelineMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetPipelineMutationResult = {
  __typename?: 'AssetPipelineMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetPipelineMutationError>>
  pipeline?: Maybe<CamAsset>
}

export type AssetPipelineSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<AssetPipelineSortField>
}

export enum AssetPipelineSortField {
  Name = 'NAME',
}

export type AssetPipelines = {
  __typename?: 'AssetPipelines'
  all?: Maybe<AssetPipelinesConnection>
  byId?: Maybe<CamAsset>
}

export type AssetPipelinesAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetPipelineFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<AssetPipelineSortBy>
}

export type AssetPipelinesByIdArgs = {
  pipelineId: Scalars['ID']['input']
}

export type AssetPipelinesConnection = {
  __typename?: 'AssetPipelinesConnection'
  edges?: Maybe<Array<Maybe<AssetPipelineEdge>>>
  pageInfo: PageInfo
}

export type AssetProfile = {
  __typename?: 'AssetProfile'
  /** Lookup asset by ID (based on state fall back to filter current dataset) */
  assetById?: Maybe<Asset>
  /** List the assets */
  assets?: Maybe<AssetsConnection>
  assetsExport?: Maybe<AssetProfileAssetExport>
  /** The property which provides the value for displayName field of Asset type */
  displayNameProperty: Scalars['String']['output']
  displayNameTemplate?: Maybe<Scalars['String']['output']>
  /** The property which indicates whether the Asset is deleted */
  isDeletedProperty?: Maybe<Scalars['String']['output']>
  owner: EntityOwner
  /** Identifier of the asset profile (e.g. railcar, geofences, bus, worker) */
  profile: Scalars['ID']['output']
  profileAssets?: Maybe<Assets>
  /** Effective properties of the asset profile: they map to dataset properties */
  properties?: Maybe<Array<AssetPropertyDefinition>>
  /** List of dataset to asset property mappings */
  propertyMaps?: Maybe<Array<AssetProfilePropertyMapping>>
  /** The asset profile relationships details */
  relationships?: Maybe<Array<AssetProfileRelationship>>
  /** The property which provides the value for secondaryDisplayName field of Asset type */
  secondaryDisplayNameProperty?: Maybe<Scalars['String']['output']>
  stateUpdateDatasets?: Maybe<Array<StateUpdateDataset>>
  /** Title of the asset profile to be used in lists and such */
  title: Scalars['String']['output']
  upDataset?: Maybe<UpDataset>
  /** Direct access to the raw datasets */
  upDatasets?: Maybe<Array<UpDataset>>
  /**
   * UP Specification to retrieve observations. (compatibility)
   *
   * live data:       upSpecification(startTime: "now")
   * historical data: upSpecification(startTime: "7 days ago", endTime: "now")
   */
  upQuery?: Maybe<UpDataset>
  upQueryObservations?: Maybe<Array<Scalars['JSONObject']['output']>>
  viewConfiguration?: Maybe<AssetProfileViewConfiguration>
  /** Persistence for view configurations for an AssetProfile */
  viewConfigurations?: Maybe<Array<AssetProfileViewConfiguration>>
}

export type AssetProfileAssetByIdArgs = {
  id: Scalars['ID']['input']
}

export type AssetProfileAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetFilter>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type AssetProfileAssetsExportArgs = {
  allRelationships?: InputMaybe<Scalars['Boolean']['input']>
  geoFields?: InputMaybe<Array<Scalars['String']['input']>>
  isExcel?: InputMaybe<Scalars['Boolean']['input']>
  properties?: InputMaybe<Array<Scalars['ID']['input']>>
  relationships?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type AssetProfileUpDatasetArgs = {
  dataset: Scalars['String']['input']
}

export type AssetProfileUpQueryArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  properties?: InputMaybe<Array<Scalars['String']['input']>>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type AssetProfileUpQueryObservationsArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  properties?: InputMaybe<Array<Scalars['String']['input']>>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
}

export type AssetProfileViewConfigurationArgs = {
  id: Scalars['ID']['input']
}

export type AssetProfileAssetExport = {
  __typename?: 'AssetProfileAssetExport'
  csvFile: Scalars['String']['output']
  csvFileDownloadUrl: Scalars['String']['output']
}

export type AssetProfileAssetExportCsvFileDownloadUrlArgs = {
  exportName?: InputMaybe<Scalars['String']['input']>
}

export enum AssetProfileDatasetSource {
  Graphql = 'graphql',
  Up = 'up',
}

export type AssetProfileEdge = {
  __typename?: 'AssetProfileEdge'
  node?: Maybe<AssetProfile>
}

export type AssetProfileMutationResult = {
  __typename?: 'AssetProfileMutationResult'
  assetProfile?: Maybe<AssetProfile>
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetMutationError>>
}

export type AssetProfilePropertyMapping = {
  __typename?: 'AssetProfilePropertyMapping'
  assetPropertyName: Scalars['String']['output']
  sourceProperty?: Maybe<AssetProfileUpSpecificationPropertyReference>
}

export type AssetProfileRelationship = {
  __typename?: 'AssetProfileRelationship'
  assetProfile: AssetProfile
  lookupProperty?: Maybe<Scalars['String']['output']>
  lookupType: AssetProfileRelationshipReferenceType
  parameters?: Maybe<Array<UpSpecificationParameter>>
  profile: Scalars['ID']['output']
  referenceProperty?: Maybe<Scalars['String']['output']>
  referenceType: AssetProfileRelationshipReferenceType
  relationship: Scalars['ID']['output']
  type: AssetProfileRelationshipType
}

export enum AssetProfileRelationshipReferenceType {
  Identity = 'IDENTITY',
  Property = 'PROPERTY',
  Subject = 'SUBJECT',
}

export enum AssetProfileRelationshipType {
  Many = 'MANY',
  One = 'ONE',
}

export type AssetProfileSpecification = UpDataset & {
  __typename?: 'AssetProfileSpecification'
  assetDisplayName?: Maybe<Scalars['String']['output']>
  assetProfile: Scalars['ID']['output']
  baseSpecification?: Maybe<Scalars['JSONObject']['output']>
  dataset: Scalars['ID']['output']
  displayName?: Maybe<Scalars['String']['output']>
  identityProperty: Scalars['String']['output']
  metadataSource?: Maybe<Scalars['String']['output']>
  metadataVersion?: Maybe<Scalars['String']['output']>
  properties?: Maybe<Array<UpSpecPropertyDefinition>>
  specificationParameters?: Maybe<Array<UpSpecificationParameter>>
  timeliness?: Maybe<UpSpecificationTimeliness>
  type: AssetProfileUpSpecificationType
}

export type AssetProfileUpSpecificationPropertyReference = {
  __typename?: 'AssetProfileUpSpecificationPropertyReference'
  /** The name of the property in the up specification */
  name: Scalars['String']['output']
  upSpecification?: Maybe<UpSpecification>
}

/**
 * This is different from UpSpecificationTimeliness on purpose
 * Maps to live, feature, historical
 */
export enum AssetProfileUpSpecificationType {
  Current = 'current',
  Future = 'future',
  Past = 'past',
}

export type AssetProfileViewConfiguration = {
  __typename?: 'AssetProfileViewConfiguration'
  id: Scalars['ID']['output']
  layouts?: Maybe<Array<AssetProfileViewLayout>>
  map?: Maybe<AssetProfileViewMap>
  name?: Maybe<Scalars['String']['output']>
}

export type AssetProfileViewConfigurationLayoutsArgs = {
  mediaTypes?: InputMaybe<Array<AssetProfileViewLayoutMediaType>>
}

export type AssetProfileViewConfigurationInput = {
  id: Scalars['ID']['input']
  layouts?: InputMaybe<Array<AssetProfileViewLayoutInput>>
  map?: InputMaybe<AssetProfileViewMapInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type AssetProfileViewConfigurationMutationResult = {
  __typename?: 'AssetProfileViewConfigurationMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetMutationError>>
  viewConfiguration?: Maybe<AssetProfileViewConfiguration>
}

export type AssetProfileViewLayout = {
  __typename?: 'AssetProfileViewLayout'
  mediaType?: Maybe<AssetProfileViewLayoutMediaType>
  widgets?: Maybe<Array<AssetProfileViewWidget>>
}

export type AssetProfileViewLayoutDetails = {
  __typename?: 'AssetProfileViewLayoutDetails'
  h: Scalars['Int']['output']
  w: Scalars['Int']['output']
  x: Scalars['Int']['output']
  y: Scalars['Int']['output']
}

export type AssetProfileViewLayoutDetailsInput = {
  h: Scalars['Int']['input']
  w: Scalars['Int']['input']
  x: Scalars['Int']['input']
  y: Scalars['Int']['input']
}

export type AssetProfileViewLayoutInput = {
  mediaType: AssetProfileViewLayoutMediaType
  widgets: Array<AssetProfileViewWidgetInput>
}

export enum AssetProfileViewLayoutMediaType {
  Mobile = 'MOBILE',
  Printable = 'PRINTABLE',
  Web = 'WEB',
}

export type AssetProfileViewLayoutMutationResult = {
  __typename?: 'AssetProfileViewLayoutMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetMutationError>>
  layout?: Maybe<AssetProfileViewLayout>
}

/** This type is very WIP */
export type AssetProfileViewMap = {
  __typename?: 'AssetProfileViewMap'
  style: Scalars['JSONObject']['output']
  type: Scalars['String']['output']
}

export type AssetProfileViewMapInput = {
  style: Scalars['JSONObject']['input']
  type: Scalars['String']['input']
}

export type AssetProfileViewWidget = {
  __typename?: 'AssetProfileViewWidget'
  id: Scalars['ID']['output']
  layout: AssetProfileViewLayoutDetails
  name: Scalars['String']['output']
  settings: Scalars['JSONObject']['output']
  widget: Scalars['String']['output']
}

export type AssetProfileViewWidgetInput = {
  id: Scalars['ID']['input']
  layout: AssetProfileViewLayoutDetailsInput
  name: Scalars['String']['input']
  settings: Scalars['JSONObject']['input']
  widget: Scalars['String']['input']
}

export type AssetProfiles = {
  __typename?: 'AssetProfiles'
  all?: Maybe<AssetProfilesConnection>
  byDataset?: Maybe<AssetProfile>
  byId?: Maybe<AssetProfile>
}

export type AssetProfilesAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type AssetProfilesByDatasetArgs = {
  catalogId?: InputMaybe<Scalars['ID']['input']>
  dataset: Scalars['ID']['input']
  datasetType?: InputMaybe<AssetProfileUpSpecificationType>
}

export type AssetProfilesByIdArgs = {
  profile: Scalars['ID']['input']
}

export type AssetProfilesConnection = {
  __typename?: 'AssetProfilesConnection'
  edges?: Maybe<Array<Maybe<AssetProfileEdge>>>
  pageInfo: PageInfo
}

export enum AssetPropertyCompleteness {
  Exhaustive = 'exhaustive',
  Suggested = 'suggested',
}

export type AssetPropertyDefinition = {
  __typename?: 'AssetPropertyDefinition'
  displayName?: Maybe<Scalars['String']['output']>
  duckdbColumn?: Maybe<Scalars['JSONObject']['output']>
  extension?: Maybe<Scalars['ID']['output']>
  filterEnabled: Scalars['Boolean']['output']
  format?: Maybe<Scalars['String']['output']>
  ftsEnabled: Scalars['Boolean']['output']
  group?: Maybe<Scalars['String']['output']>
  isActive: Scalars['Boolean']['output']
  jsonSchema?: Maybe<Scalars['JSONObject']['output']>
  name: Scalars['String']['output']
  sourceProperty?: Maybe<AssetProfileUpSpecificationPropertyReference>
  terms?: Maybe<Array<AssetPropertyTerm>>
  termsCompleteness?: Maybe<AssetPropertyCompleteness>
  type?: Maybe<AssetPropertyType>
}

export type AssetPropertyDefinitionInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  duckdbColumn?: InputMaybe<Scalars['JSONObject']['input']>
  extension?: InputMaybe<Scalars['ID']['input']>
  filterEnabled?: InputMaybe<Scalars['Boolean']['input']>
  format?: InputMaybe<Scalars['String']['input']>
  ftsEnabled?: InputMaybe<Scalars['Boolean']['input']>
  group: Scalars['String']['input']
  jsonSchema?: InputMaybe<Scalars['JSONObject']['input']>
  name: Scalars['ID']['input']
  terms?: InputMaybe<Array<AssetPropertyTermInput>>
  termsCompleteness?: InputMaybe<AssetPropertyCompleteness>
  type?: InputMaybe<AssetPropertyType>
}

export enum AssetPropertyNecessity {
  Optional = 'optional',
  Required = 'required',
}

export type AssetPropertyOption = {
  __typename?: 'AssetPropertyOption'
  displayName?: Maybe<Scalars['String']['output']>
  key?: Maybe<Scalars['String']['output']>
}

export type AssetPropertyTerm = {
  __typename?: 'AssetPropertyTerm'
  displayName?: Maybe<Scalars['String']['output']>
  key: Scalars['String']['output']
}

export type AssetPropertyTermInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  key: Scalars['String']['input']
}

export enum AssetPropertyType {
  Array = 'array',
  Boolean = 'boolean',
  Number = 'number',
  Object = 'object',
  String = 'string',
}

export type AssetReference = {
  __typename?: 'AssetReference'
  asset?: Maybe<Asset>
  assetId: Scalars['ID']['output']
  /** Reference to the catalog */
  catalogId?: Maybe<Scalars['ID']['output']>
  /** Reference to the dataset */
  dataset?: Maybe<Scalars['ID']['output']>
  /** Reference to the profile */
  profile?: Maybe<Scalars['ID']['output']>
}

export type AssetRelationship = AssetRelationshipMany | AssetRelationshipOne

export type AssetRelationshipMany = {
  __typename?: 'AssetRelationshipMany'
  assets?: Maybe<Array<Asset>>
  observations?: Maybe<Array<Scalars['JSONObject']['output']>>
}

export type AssetRelationshipOne = {
  __typename?: 'AssetRelationshipOne'
  asset?: Maybe<Asset>
  observation?: Maybe<Scalars['JSONObject']['output']>
}

export type AssetSelectionFilter = {
  __typename?: 'AssetSelectionFilter'
  assetProfile: AssetProfile
  description?: Maybe<Scalars['String']['output']>
  displayName: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** The asset profile to be used for subjects */
  profile: Scalars['ID']['output']
  /** The asset profiles to be used for the subjects of the subtasks, must be related to parent */
  subProfiles?: Maybe<Array<AssetSelectionFilterRelationship>>
}

export type AssetSelectionFilterRelationship = {
  __typename?: 'AssetSelectionFilterRelationship'
  assetProfile: AssetProfile
  profile: Scalars['ID']['output']
  relationship?: Maybe<Scalars['ID']['output']>
}

export type AssetSiteEdge = {
  __typename?: 'AssetSiteEdge'
  node?: Maybe<CamAsset>
}

export type AssetSiteFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Array<Scalars['String']['input']>>
  parentAssetReference?: InputMaybe<Array<Scalars['String']['input']>>
  propertyFilters?: InputMaybe<Array<CamAssetPropertyFilter>>
  siteId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type AssetSiteMutationError = MutationError & {
  __typename?: 'AssetSiteMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetSiteMutationResult = {
  __typename?: 'AssetSiteMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetSiteMutationError>>
  site?: Maybe<CamAsset>
}

export type AssetSiteReference = {
  __typename?: 'AssetSiteReference'
  site?: Maybe<CamAsset>
  siteId: Scalars['ID']['output']
}

export type AssetSiteSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<AssetSiteSortField>
}

export enum AssetSiteSortField {
  Name = 'NAME',
}

export type AssetSites = {
  __typename?: 'AssetSites'
  all?: Maybe<AssetSitesConnection>
  byId?: Maybe<CamAsset>
}

export type AssetSitesAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetSiteFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  fullTextSearch?: InputMaybe<CamAssetFullTextSearch>
  sortBy?: InputMaybe<AssetSiteSortBy>
}

export type AssetSitesByIdArgs = {
  siteId: Scalars['ID']['input']
}

export type AssetSitesConnection = {
  __typename?: 'AssetSitesConnection'
  edges?: Maybe<Array<Maybe<AssetSiteEdge>>>
  pageInfo: PageInfo
}

export type AssetType = {
  __typename?: 'AssetType'
  /** Represents if the AssetType is ready to be consumed by the clients. */
  activeVersion: Scalars['ID']['output']
  assetTypeId: Scalars['ID']['output']
  assets?: Maybe<CamAssets>
  camEngineDataset?: Maybe<CamEngineDataset>
  draft?: Maybe<AssetTypeVersion>
  extensions?: Maybe<Array<Maybe<AssetTypeExtension>>>
  group: Scalars['String']['output']
  /** Represents if the AssetType is in the provisioning process, but the provisioning is not finished yet. If active and already completely provisioned, the value of this property will be false. */
  isActivating: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  properties?: Maybe<Array<AssetPropertyDefinition>>
}

export type AssetTypeCamEngineDatasetArgs = {
  assetGroup: Scalars['ID']['input']
  withExtensions?: InputMaybe<Array<Scalars['ID']['input']>>
  withFts?: InputMaybe<Scalars['Boolean']['input']>
}

export type AssetTypeEdge = {
  __typename?: 'AssetTypeEdge'
  node?: Maybe<AssetType>
}

export type AssetTypeExtension = {
  __typename?: 'AssetTypeExtension'
  /** Represents the minimum version of the AssetType this extension is ready to be used with. */
  activeVersion: Scalars['ID']['output']
  camEngineDataset?: Maybe<CamEngineDataset>
  connectionDetails?: Maybe<AssetTypeExtensionConnectionDetails>
  extensionId: Scalars['ID']['output']
  geometryColumn?: Maybe<Scalars['ID']['output']>
  properties?: Maybe<Array<AssetPropertyDefinition>>
  stateSyncProperties?: Maybe<Array<AssetTypeExtensionStateSyncProperty>>
}

export type AssetTypeExtensionCamEngineDatasetArgs = {
  withFts?: InputMaybe<Scalars['Boolean']['input']>
}

export type AssetTypeExtensionConnectionDetails =
  AssetTypeExtensionConnectionSimple

export type AssetTypeExtensionConnectionInput = {
  idProperty: Scalars['ID']['input']
  stateProperty: Scalars['ID']['input']
}

export type AssetTypeExtensionConnectionSimple = {
  __typename?: 'AssetTypeExtensionConnectionSimple'
  idColumn: Scalars['ID']['output']
  idProperty: Scalars['ID']['output']
  stateProperty: Scalars['ID']['output']
}

export type AssetTypeExtensionInput = {
  connectionDetails?: InputMaybe<AssetTypeExtensionConnectionInput>
  datasetId: Scalars['ID']['input']
  geometryColumn?: InputMaybe<Scalars['ID']['input']>
  stateSyncProperties?: InputMaybe<
    Array<AssetTypeExtensionStateSyncPropertyInput>
  >
}

export type AssetTypeExtensionStateSyncProperty = {
  __typename?: 'AssetTypeExtensionStateSyncProperty'
  dataColumn?: Maybe<Scalars['ID']['output']>
  dataQueryPart?: Maybe<Scalars['String']['output']>
  dataStringValue?: Maybe<Scalars['String']['output']>
  stateProperty: Scalars['ID']['output']
}

export type AssetTypeExtensionStateSyncPropertyInput = {
  dataColumn?: InputMaybe<Scalars['ID']['input']>
  dataQueryPart?: InputMaybe<Scalars['String']['input']>
  dataStringValue?: InputMaybe<Scalars['String']['input']>
  stateProperty: Scalars['ID']['input']
}

export type AssetTypeFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  assetTypeId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type AssetTypeMutationError = MutationError & {
  __typename?: 'AssetTypeMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetTypeMutationResult = {
  __typename?: 'AssetTypeMutationResult'
  assetType?: Maybe<AssetType>
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetTypeMutationError>>
}

export type AssetTypePropertyInput = {
  extension?: InputMaybe<Scalars['ID']['input']>
  name: Scalars['ID']['input']
}

export type AssetTypeSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<AssetTypeSortField>
}

export enum AssetTypeSortField {
  Name = 'NAME',
}

export enum AssetTypeStateManageMethod {
  Consolidate = 'CONSOLIDATE',
  Dump = 'DUMP',
  DumpSetup = 'DUMP_SETUP',
  PrecomputeAssetsUnion = 'PRECOMPUTE_ASSETS_UNION',
  PrecomputeAssetStateViews = 'PRECOMPUTE_ASSET_STATE_VIEWS',
  Provision = 'PROVISION',
  ReloadCamAssetsDatasets = 'RELOAD_CAM_ASSETS_DATASETS',
  ReloadCatalog = 'RELOAD_CATALOG',
  Seed = 'SEED',
  SeedExtension = 'SEED_EXTENSION',
  SeedSync = 'SEED_SYNC',
  Setup = 'SETUP',
  Sync = 'SYNC',
  SyncGeometry = 'SYNC_GEOMETRY',
  SyncProperties = 'SYNC_PROPERTIES',
}

export type AssetTypeVersion = {
  __typename?: 'AssetTypeVersion'
  assetTypeId: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  properties?: Maybe<Array<AssetPropertyDefinition>>
  version: Scalars['ID']['output']
}

export type AssetTypes = {
  __typename?: 'AssetTypes'
  all?: Maybe<AssetTypesConnection>
  byId?: Maybe<AssetType>
}

export type AssetTypesAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetTypeFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<AssetTypeSortBy>
}

export type AssetTypesByIdArgs = {
  assetTypeId: Scalars['ID']['input']
}

export type AssetTypesConnection = {
  __typename?: 'AssetTypesConnection'
  edges?: Maybe<Array<Maybe<AssetTypeEdge>>>
  pageInfo: PageInfo
}

export type AssetWellEdge = {
  __typename?: 'AssetWellEdge'
  node?: Maybe<CamAsset>
}

export type AssetWellFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  name?: InputMaybe<Array<Scalars['String']['input']>>
  parentAssetReference?: InputMaybe<Array<Scalars['String']['input']>>
  wellId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type AssetWellMutationError = MutationError & {
  __typename?: 'AssetWellMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AssetWellMutationResult = {
  __typename?: 'AssetWellMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AssetWellMutationError>>
  well?: Maybe<CamAsset>
}

export type AssetWellSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<AssetWellSortField>
}

export enum AssetWellSortField {
  Name = 'NAME',
}

export type AssetWells = {
  __typename?: 'AssetWells'
  all?: Maybe<AssetWellsConnection>
  byId?: Maybe<CamAsset>
}

export type AssetWellsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetWellFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<AssetWellSortBy>
}

export type AssetWellsByIdArgs = {
  wellId: Scalars['ID']['input']
}

export type AssetWellsConnection = {
  __typename?: 'AssetWellsConnection'
  edges?: Maybe<Array<Maybe<AssetWellEdge>>>
  pageInfo: PageInfo
}

export type Assets = {
  __typename?: 'Assets'
  all?: Maybe<AssetsConnection>
  byId?: Maybe<Asset>
}

export type AssetsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<AssetFilter>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type AssetsByIdArgs = {
  id: Scalars['ID']['input']
}

export type AssetsConnection = {
  __typename?: 'AssetsConnection'
  edges?: Maybe<Array<Maybe<AssetEdge>>>
  pageInfo: PageInfo
}

export type AssignIssueToUserInput = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  issueId: Scalars['ID']['input']
  username?: InputMaybe<Scalars['String']['input']>
}

export type AttributeEmissionObservationToEquipmentInput = {
  attributionExplanation?: InputMaybe<Scalars['String']['input']>
  emissionObservationId: Scalars['ID']['input']
  equipmentComponentName?: InputMaybe<Scalars['String']['input']>
  sourceAssetReference: Scalars['ID']['input']
}

export type AttributeEmissionObservationToEquipmentResult = {
  __typename?: 'AttributeEmissionObservationToEquipmentResult'
  correlationId: Scalars['ID']['output']
  emissionObservation?: Maybe<Detection>
  errors?: Maybe<Array<EmissionObservationMutationError>>
}

export type AttributeEmissionObservationToSiteInput = {
  attributionExplanation: Scalars['String']['input']
  emissionObservationId: Scalars['ID']['input']
  equipmentParentId?: InputMaybe<Scalars['ID']['input']>
}

export type AttributeEmissionObservationToSiteResult = {
  __typename?: 'AttributeEmissionObservationToSiteResult'
  correlationId: Scalars['ID']['output']
  emissionObservation?: Maybe<EmissionObservation>
  errors?: Maybe<Array<EmissionObservationMutationError>>
}

export type AttributeEmissionObservationsToEquipmentInput = {
  attributionExplanation?: InputMaybe<Scalars['String']['input']>
  /** The emission observations must all be on the same site. Maximum length 20. OGI type Detections are not supported. */
  emissionObservationIds: Array<Scalars['ID']['input']>
  equipmentId: Scalars['ID']['input']
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
}

export type AttributeEmissionObservationsToEquipmentResult = {
  __typename?: 'AttributeEmissionObservationsToEquipmentResult'
  correlationId: Scalars['ID']['output']
  emissionObservations?: Maybe<Array<Maybe<EmissionObservation>>>
  errors?: Maybe<Array<EmissionObservationMutationError>>
}

/** Audit is a copy of a shared implementation across schema and components */
export type Audit = {
  __typename?: 'Audit'
  createdBy?: Maybe<UserReference>
  createdTime: Scalars['DateTime']['output']
  updatedBy?: Maybe<UserReference>
  updatedTime: Scalars['DateTime']['output']
}

export type AuthAttributes = {
  __typename?: 'AuthAttributes'
  email?: Maybe<Scalars['String']['output']>
  family_name?: Maybe<Scalars['String']['output']>
  given_name?: Maybe<Scalars['String']['output']>
  phone_number?: Maybe<Scalars['String']['output']>
  sub?: Maybe<Scalars['String']['output']>
}

export type AuthAttributesInput = {
  email?: InputMaybe<Scalars['String']['input']>
  family_name?: InputMaybe<Scalars['String']['input']>
  given_name?: InputMaybe<Scalars['String']['input']>
  phone_number?: InputMaybe<Scalars['String']['input']>
  sub?: InputMaybe<Scalars['String']['input']>
}

export type AuthChallengeParam = {
  __typename?: 'AuthChallengeParam'
  requiredAttributes?: Maybe<AuthAttributes>
  userAttributes?: Maybe<AuthAttributes>
}

export type AuthChallengeParamInput = {
  requiredAttributes?: InputMaybe<AuthAttributesInput>
  userAttributes?: InputMaybe<AuthAttributesInput>
}

export enum AuthChallengeType {
  AdminNoSrpAuth = 'ADMIN_NO_SRP_AUTH',
  CustomChallenge = 'CUSTOM_CHALLENGE',
  DeviceSrpAuth = 'DEVICE_SRP_AUTH',
  MfaSetup = 'MFA_SETUP',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  PasswordVerifier = 'PASSWORD_VERIFIER',
  SelectMfaType = 'SELECT_MFA_TYPE',
  SmsMfa = 'SMS_MFA',
  SoftwareTokenMfa = 'SOFTWARE_TOKEN_MFA',
}

export enum AuthClientApplicationType {
  Api = 'API',
  Explorer = 'EXPLORER',
  SensorhubAndroid = 'SENSORHUB_ANDROID',
  SensorhubIos = 'SENSORHUB_IOS',
}

export type AuthCompleteNewPasswordInput = {
  password: Scalars['String']['input']
}

export type AuthConfig = {
  __typename?: 'AuthConfig'
  loginEnabled: Scalars['Boolean']['output']
  ssoEnabled: Scalars['Boolean']['output']
  ssoUrl: Scalars['String']['output']
}

export type AuthConfirmSignInInput = {
  code: Scalars['String']['input']
  deviceGroupKey?: InputMaybe<Scalars['String']['input']>
  deviceKey?: InputMaybe<Scalars['ID']['input']>
  deviceName?: InputMaybe<Scalars['String']['input']>
  devicePassword?: InputMaybe<Scalars['String']['input']>
  mfaType: AuthMfaType
  rememberDevice?: InputMaybe<Scalars['Boolean']['input']>
}

export type AuthConfirmSignInResult = {
  __typename?: 'AuthConfirmSignInResult'
  correlationId: Scalars['ID']['output']
  deviceGroupKey?: Maybe<Scalars['String']['output']>
  deviceKey?: Maybe<Scalars['ID']['output']>
  devicePassword?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<AuthMutationError>>
  session?: Maybe<AuthSession>
}

export type AuthCreateApiKeyInput = {
  apiKeyId?: Scalars['ID']['input']
  userGroup: Scalars['ID']['input']
}

/**
 * AuthCredentials are the opaque short lived credentials to be passed to Unipipe
 * The essential credentials call returns the same data as credentials, but without the expiration
 * Once Unipipe and the front end are in the same origin, we can use httponly cookies for this purpose
 */
export type AuthCredentials = {
  __typename?: 'AuthCredentials'
  accessKeyId: Scalars['String']['output']
  expiration: Scalars['DateTime']['output']
  secretAccessKey: Scalars['String']['output']
  sessionToken: Scalars['String']['output']
}

export type AuthDevice = {
  __typename?: 'AuthDevice'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  deviceKey: Scalars['ID']['output']
  lastIpAddress?: Maybe<Scalars['String']['output']>
  lastSignedInAt?: Maybe<Scalars['DateTime']['output']>
  modifiedAt?: Maybe<Scalars['DateTime']['output']>
  name?: Maybe<Scalars['String']['output']>
  remembered?: Maybe<Scalars['Boolean']['output']>
}

export type AuthFederatedSignInInput = {
  clientApplicationType?: InputMaybe<AuthClientApplicationType>
  cognitoAccessToken?: InputMaybe<Scalars['String']['input']>
  cognitoIdToken: Scalars['String']['input']
}

export type AuthForgetSpecificDevicesInput = {
  deviceKeys: Array<Scalars['ID']['input']>
}

export type AuthForgotPasswordInput = {
  loginUsername: Scalars['ID']['input']
}

export type AuthForgotPasswordSubmitInput = {
  confirmationCode: Scalars['String']['input']
  loginUsername: Scalars['ID']['input']
  password: Scalars['String']['input']
}

export enum AuthMfaMethod {
  Nomfa = 'NOMFA',
  Sms = 'SMS',
  Totp = 'TOTP',
}

export type AuthMfaPreferenceInput = {
  enabled: Scalars['Boolean']['input']
  preferredMFA: Scalars['Boolean']['input']
}

export enum AuthMfaType {
  Nomfa = 'NOMFA',
  SmsMfa = 'SMS_MFA',
  SoftwareTokenMfa = 'SOFTWARE_TOKEN_MFA',
}

export type AuthMutationError = MutationError & {
  __typename?: 'AuthMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type AuthMutationResult = {
  __typename?: 'AuthMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AuthMutationError>>
  session?: Maybe<AuthSession>
}

export type AuthSession = {
  __typename?: 'AuthSession'
  attributes?: Maybe<AuthAttributes>
  audit: Audit
  authenticated: Scalars['Boolean']['output']
  challengeName?: Maybe<AuthChallengeType>
  challengeParam?: Maybe<Scalars['JSONObject']['output']>
  /** @deprecated No longer supported */
  cognitoAccessToken?: Maybe<Scalars['String']['output']>
  expiresAt: Scalars['DateTime']['output']
  expiresAtHard: Scalars['DateTime']['output']
  preferredMFA?: Maybe<AuthMfaType>
  publicReference: Scalars['ID']['output']
  userGroup: Scalars['ID']['output']
  username: Scalars['ID']['output']
}

export type AuthSetPreferredMfaInput = {
  mfaMethod: AuthMfaMethod
}

export type AuthSetSessionUserGroupInput = {
  userGroup?: InputMaybe<Scalars['ID']['input']>
}

export type AuthSignInInput = {
  clientApplicationType?: InputMaybe<AuthClientApplicationType>
  deviceGroupKey?: InputMaybe<Scalars['String']['input']>
  deviceKey?: InputMaybe<Scalars['ID']['input']>
  devicePassword?: InputMaybe<Scalars['String']['input']>
  encodedDeviceFingerprint?: InputMaybe<Scalars['String']['input']>
  loginUsername: Scalars['ID']['input']
  password: Scalars['String']['input']
}

export type AuthTotpMutationResult = {
  __typename?: 'AuthTOTPMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<AuthMutationError>>
  qrCode?: Maybe<Scalars['String']['output']>
  /** Data URI containing a representation of the QR Code image. */
  qrImage?: Maybe<Scalars['String']['output']>
}

export type AuthVerifySmsCodeInput = {
  code: Scalars['String']['input']
}

export type AuthVerifyTotpTokenInput = {
  code: Scalars['String']['input']
}

export type BooleanOperand = BooleanValueOperand | PropertyOperand

export type BooleanOperandInput = {
  property?: InputMaybe<Scalars['String']['input']>
  unionType: OperandUnionType
  value?: InputMaybe<Scalars['Boolean']['input']>
}

export type BooleanValueOperand = {
  __typename?: 'BooleanValueOperand'
  value: Scalars['Boolean']['output']
}

export type BulkIssuesMutationError = MutationError & {
  __typename?: 'BulkIssuesMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type BulkIssuesMutationResult = {
  __typename?: 'BulkIssuesMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<BulkIssuesMutationError>>
  foundIssuesCount?: Maybe<Scalars['Int']['output']>
}

export type BulkUpdateIssueInput = {
  assigneeUsername?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  severity?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  watchers?: InputMaybe<Array<TriggerIssueWatcherInput>>
}

export type ByAssetReferenceFilter = {
  assetReference: Scalars['String']['input']
  customFilters?: InputMaybe<Array<EmissionCustomFilter>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  observationType?: InputMaybe<Array<DetectionType>>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Array<EmissionEventStatus>>
}

export type Co2eMonthReport = {
  __typename?: 'CO2eMonthReport'
  co2eReportDetails: Co2eReportDetails
  isMissingData: Scalars['Boolean']['output']
  month: Scalars['Date']['output']
}

export type Co2eMonthReportConnection = {
  __typename?: 'CO2eMonthReportConnection'
  edges?: Maybe<Array<Maybe<Co2eMonthReportEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type Co2eMonthReportEdge = {
  __typename?: 'CO2eMonthReportEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: Co2eMonthReport
}

export type Co2eMonthReports = {
  __typename?: 'CO2eMonthReports'
  /** CO2e report for all months for a given year */
  byYear?: Maybe<Co2eMonthReportConnection>
}

export type Co2eMonthReportsByYearArgs = {
  year: Scalars['Date']['input']
}

export type Co2eReportDetails = {
  __typename?: 'CO2eReportDetails'
  ch4Emission?: Maybe<Scalars['Float']['output']>
  ch4Percent?: Maybe<Scalars['Float']['output']>
  co2Emission?: Maybe<Scalars['Float']['output']>
  co2eEmission?: Maybe<Scalars['Float']['output']>
  co2eIntensityTonnesPerMcf?: Maybe<Scalars['Float']['output']>
  gasProductionMcf?: Maybe<Scalars['Float']['output']>
  gasRatio?: Maybe<Scalars['Float']['output']>
  heatValueDryMbtuPerMcf?: Maybe<Scalars['Float']['output']>
  heatValueWetMbtuPerBbl?: Maybe<Scalars['Float']['output']>
  n2oEmission?: Maybe<Scalars['Float']['output']>
  oilProductionBbl?: Maybe<Scalars['Float']['output']>
  scope2Emission?: Maybe<Scalars['Float']['output']>
}

export type Co2eReportDetailsCh4EmissionArgs = {
  unit?: EmissionUnit
}

export type Co2eReportDetailsCo2EmissionArgs = {
  unit?: EmissionUnit
}

export type Co2eReportDetailsCo2eEmissionArgs = {
  unit?: EmissionUnit
}

export type Co2eReportDetailsN2oEmissionArgs = {
  unit?: EmissionUnit
}

export type Co2eReportDetailsScope2EmissionArgs = {
  unit?: EmissionUnit
}

export type Co2eSiteMonthReport = {
  __typename?: 'CO2eSiteMonthReport'
  co2eReportDetails: Co2eReportDetails
  month: Scalars['Date']['output']
  siteReference?: Maybe<SiteReference>
}

export type Co2eSiteMonthReportConnection = {
  __typename?: 'CO2eSiteMonthReportConnection'
  edges?: Maybe<Array<Maybe<Co2eSiteMonthReportEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type Co2eSiteMonthReportEdge = {
  __typename?: 'CO2eSiteMonthReportEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: Co2eSiteMonthReport
}

export type Co2eSiteMonthReports = {
  __typename?: 'CO2eSiteMonthReports'
  /** CO2e report for all sites for the latest closed month */
  byLatestClosedMonth?: Maybe<Co2eSiteMonthReportConnection>
  /** CO2e report for all sites for a given month */
  byMonth?: Maybe<Co2eSiteMonthReportConnection>
}

export type Co2eSiteMonthReportsByMonthArgs = {
  month: Scalars['Date']['input']
}

export type CamAsset = CamAssetIf & {
  __typename?: 'CamAsset'
  active: Scalars['Boolean']['output']
  assetId: Scalars['ID']['output']
  assetReference: Scalars['ID']['output']
  assetTypeId: Scalars['ID']['output']
  geometry?: Maybe<GeoJsonGeometryInterface>
  geometryJson?: Maybe<Scalars['JSONObject']['output']>
  group: Scalars['String']['output']
  id: Scalars['ID']['output']
  observation?: Maybe<Scalars['JSONObject']['output']>
  parent?: Maybe<CamAsset>
  properties?: Maybe<Scalars['JSONObject']['output']>
  property?: Maybe<Scalars['JSONObject']['output']>
}

export type CamAssetObservationArgs = {
  properties?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CamAssetPropertiesArgs = {
  properties?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CamAssetPropertyArgs = {
  property: Scalars['ID']['input']
}

export type CamAssetEdge = {
  __typename?: 'CamAssetEdge'
  node?: Maybe<CamAsset>
}

export type CamAssetFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  name?: InputMaybe<Array<Scalars['String']['input']>>
  parentAssetReference?: InputMaybe<Array<Scalars['String']['input']>>
  propertyFilters?: InputMaybe<Array<CamAssetPropertyFilter>>
}

export type CamAssetFullTextSearch = {
  /** If null, it means we search in all fts indexed column */
  property?: InputMaybe<Scalars['ID']['input']>
  searchValue: Scalars['String']['input']
}

export type CamAssetIf = {
  active: Scalars['Boolean']['output']
  assetId: Scalars['ID']['output']
  assetReference: Scalars['ID']['output']
  assetTypeId: Scalars['ID']['output']
  geometry?: Maybe<GeoJsonGeometryInterface>
  geometryJson?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  observation?: Maybe<Scalars['JSONObject']['output']>
  parent?: Maybe<CamAsset>
  properties?: Maybe<Scalars['JSONObject']['output']>
  property?: Maybe<Scalars['JSONObject']['output']>
}

export type CamAssetIfObservationArgs = {
  properties?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CamAssetIfPropertiesArgs = {
  properties?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type CamAssetIfPropertyArgs = {
  property: Scalars['ID']['input']
}

export type CamAssetPropertyFilter = {
  property: Scalars['ID']['input']
  valueJson?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']['input']>>>
}

export type CamAssetPropertyValueInput = {
  property: Scalars['ID']['input']
  valueJson?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CamAssetReference = {
  __typename?: 'CamAssetReference'
  asset?: Maybe<CamAsset>
  assetId?: Maybe<Scalars['ID']['output']>
  assetReference: Scalars['ID']['output']
  assetTypeId?: Maybe<Scalars['ID']['output']>
  emissionObservationReports?: Maybe<EmissionObservationMonthSiteReportConnection>
}

export type CamAssetReferenceEmissionObservationReportsArgs = {
  filter?: InputMaybe<InboxDetectionFilter>
}

export type CamAssetReferences = {
  __typename?: 'CamAssetReferences'
  assetByReference?: Maybe<CamAsset>
  assetReference?: Maybe<CamAssetReference>
}

export type CamAssetReferencesAssetByReferenceArgs = {
  assetReference: Scalars['ID']['input']
}

export type CamAssetReferencesAssetReferenceArgs = {
  assetReference: Scalars['ID']['input']
}

export type CamAssetSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<Scalars['ID']['input']>
}

export type CamAssets = {
  __typename?: 'CamAssets'
  all?: Maybe<CamAssetsConnection>
  byId?: Maybe<CamAsset>
}

export type CamAssetsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<CamAssetFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  fullTextSearch?: InputMaybe<CamAssetFullTextSearch>
  sortBy?: InputMaybe<CamAssetSortBy>
}

export type CamAssetsByIdArgs = {
  id: Scalars['ID']['input']
}

export type CamAssetsConnection = {
  __typename?: 'CamAssetsConnection'
  edges?: Maybe<Array<Maybe<CamAssetEdge>>>
  pageInfo: PageInfo
}

export type CamCatalog = {
  __typename?: 'CamCatalog'
  group: Scalars['ID']['output']
}

export type CamDatasetCatalogBackendComponentError = MutationError & {
  __typename?: 'CamDatasetCatalogBackendComponentError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type CamDatasetCatalogBackendComponentMutationResult = {
  __typename?: 'CamDatasetCatalogBackendComponentMutationResult'
  backendComponents: Array<Maybe<Scalars['String']['output']>>
  errors?: Maybe<Array<CamDatasetCatalogBackendComponentError>>
}

export type CamDatasets = {
  __typename?: 'CamDatasets'
  byId?: Maybe<CamEngineDataset>
  list: Array<Maybe<CamEngineDataset>>
}

export type CamDatasetsByIdArgs = {
  datasetId: Scalars['ID']['input']
}

export type CamEngine = {
  __typename?: 'CamEngine'
  dataset?: Maybe<CamEngineDataset>
  datasets?: Maybe<CamDatasets>
  query?: Maybe<CamQueryResult>
  queryBuffer: CamQueryBufferResult
  queryDownload: CamQueryFileResult
}

export type CamEngineDatasetArgs = {
  datasetId: Scalars['ID']['input']
}

export type CamEngineQueryArgs = {
  datasetMetadata?: InputMaybe<Scalars['JSONObject']['input']>
  datasetQuery?: InputMaybe<CamEngineDatasetQuery>
}

export type CamEngineQueryBufferArgs = {
  datasetMetadata?: InputMaybe<Scalars['JSONObject']['input']>
  datasetQuery?: InputMaybe<CamEngineDatasetQuery>
}

export type CamEngineQueryDownloadArgs = {
  datasetMetadata?: InputMaybe<Scalars['JSONObject']['input']>
  datasetQuery?: InputMaybe<CamEngineDatasetQuery>
}

export type CamEngineDataset = {
  __typename?: 'CamEngineDataset'
  credentials?: Maybe<Scalars['JSONObject']['output']>
  datasetId: Scalars['ID']['output']
  metadata?: Maybe<CamEngineMetadata>
  metadataJson?: Maybe<Scalars['JSONObject']['output']>
  query: CamQueryResult
  queryBuffer: CamQueryBufferResult
  queryDownload: CamQueryFileResult
}

export type CamEngineDatasetMetadataJsonArgs = {
  normalized?: InputMaybe<Scalars['Boolean']['input']>
  withInternal?: InputMaybe<Scalars['Boolean']['input']>
}

export type CamEngineDatasetQueryArgs = {
  datasetQuery?: InputMaybe<CamEngineDatasetQuery>
}

export type CamEngineDatasetQueryBufferArgs = {
  datasetQuery?: InputMaybe<CamEngineDatasetQuery>
}

export type CamEngineDatasetQueryDownloadArgs = {
  datasetQuery?: InputMaybe<CamEngineDatasetQuery>
}

export type CamEngineDatasetQuery = {
  datasetParams?: InputMaybe<Scalars['JSONObject']['input']>
  enableTrace?: InputMaybe<Scalars['Boolean']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  persist?: InputMaybe<Scalars['Boolean']['input']>
  persistId?: InputMaybe<Scalars['ID']['input']>
  persistOutputType?: InputMaybe<CamEngineResultOutputType>
  presignColumns?: InputMaybe<Array<Scalars['String']['input']>>
  primaryKeyColumn?: InputMaybe<Scalars['String']['input']>
  query?: InputMaybe<Scalars['String']['input']>
  validateS3object?: InputMaybe<Scalars['Boolean']['input']>
}

export type CamEngineMetadata = {
  __typename?: 'CamEngineMetadata'
  afterDbCreatedStatements?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  baseSelectQuery?: Maybe<Scalars['String']['output']>
  columns?: Maybe<Array<CamEngineMetadataColumn>>
  createTable?: Maybe<Scalars['String']['output']>
  createdBy?: Maybe<Scalars['String']['output']>
  dataLocationQuery?: Maybe<Scalars['String']['output']>
  datasetId: Scalars['String']['output']
  datasetSource?: Maybe<Scalars['String']['output']>
  group: Scalars['ID']['output']
  name: Scalars['String']['output']
  properties?: Maybe<Array<AssetPropertyDefinition>>
  style?: Maybe<Scalars['String']['output']>
  version: Scalars['String']['output']
}

export type CamEngineMetadataColumn = {
  __typename?: 'CamEngineMetadataColumn'
  characterMaximumLength?: Maybe<Scalars['Int']['output']>
  characterOctetLength?: Maybe<Scalars['Int']['output']>
  columnDefault?: Maybe<Scalars['String']['output']>
  columnName?: Maybe<Scalars['String']['output']>
  dataType?: Maybe<Scalars['String']['output']>
  dateTimePrecision?: Maybe<Scalars['Int']['output']>
  isNullable?: Maybe<Scalars['Boolean']['output']>
  numericPrecision?: Maybe<Scalars['Int']['output']>
  numericScale?: Maybe<Scalars['Int']['output']>
  ordinalPosition?: Maybe<Scalars['Int']['output']>
}

export enum CamEngineResultOutputType {
  Csv = 'CSV',
  Geojson = 'GEOJSON',
  GeojsonSequence = 'GEOJSON_SEQUENCE',
  Gpkg = 'GPKG',
  Json = 'JSON',
  Jsonl = 'JSONL',
  JsonArray = 'JSON_ARRAY',
  Kml = 'KML',
  Parquet = 'PARQUET',
}

export type CamQueryBufferResult = {
  __typename?: 'CamQueryBufferResult'
  json?: Maybe<Scalars['JSONObject']['output']>
  jsonArray?: Maybe<Array<Scalars['JSONObject']['output']>>
  jsonLine?: Maybe<Scalars['JSONObject']['output']>
  rawString: Scalars['String']['output']
}

export type CamQueryBufferResultJsonLineArgs = {
  line?: InputMaybe<Scalars['Int']['input']>
}

export type CamQueryFileResult = {
  __typename?: 'CamQueryFileResult'
  downloadUrl: Scalars['String']['output']
  resultObjectUrl: Scalars['String']['output']
  traceDownloadUrl?: Maybe<Scalars['String']['output']>
  traceResultObjectUrl?: Maybe<Scalars['String']['output']>
}

export type CamQueryResult = CamQueryBufferResult | CamQueryFileResult

export type Catalog = {
  __typename?: 'Catalog'
  catalogId: Scalars['ID']['output']
  datasets?: Maybe<Array<UpSpecification>>
  group: Scalars['String']['output']
  isInternal: Scalars['Boolean']['output']
}

export type CatalogDatasetDataAccessApiGateway = {
  apiGatewayApiIds: Array<Scalars['String']['input']>
  urlSsmParameters: Array<Scalars['String']['input']>
}

export type CatalogDatasetDataAccessDynamoTable = {
  dynamoTableArns: Array<Scalars['String']['input']>
}

export type CatalogDatasetDataAccessInput = {
  apiGateway?: InputMaybe<CatalogDatasetDataAccessApiGateway>
  dynamoTable?: InputMaybe<CatalogDatasetDataAccessDynamoTable>
  id: Scalars['ID']['input']
  mqttTopic?: InputMaybe<CatalogDatasetDataAccessMqttTopic>
  s3Bucket?: InputMaybe<CatalogDatasetDataAccessS3Bucket>
  type?: InputMaybe<CatalogDatasetDataAccessType>
}

export type CatalogDatasetDataAccessMqttTopic = {
  mqttTopic: Scalars['String']['input']
}

export type CatalogDatasetDataAccessS3Bucket = {
  s3BucketArn: Scalars['String']['input']
  s3DataPrefix: Scalars['String']['input']
}

export enum CatalogDatasetDataAccessType {
  ApiGateway = 'API_GATEWAY',
  DynamodbTable = 'DYNAMODB_TABLE',
  MqttTopic = 'MQTT_TOPIC',
  S3Bucket = 'S3_BUCKET',
}

export type CatalogDatasetSpecificationInput = {
  baseSpecification?: InputMaybe<Scalars['JSONObject']['input']>
  dataset: Scalars['ID']['input']
  displayName?: InputMaybe<Scalars['String']['input']>
  dynamicMetadata?: InputMaybe<Scalars['JSONObject']['input']>
  isInternal?: InputMaybe<Scalars['Boolean']['input']>
  metadataSource?: InputMaybe<Scalars['String']['input']>
  metadataVersion?: InputMaybe<Scalars['String']['input']>
  properties?: InputMaybe<Array<UpSpecPropertyDefinitionInput>>
  specificationParameters?: InputMaybe<Array<UpSpecificationParameterInput>>
  timeliness?: InputMaybe<UpSpecificationTimeliness>
}

export type CatalogEdge = {
  __typename?: 'CatalogEdge'
  node?: Maybe<Catalog>
}

export type CatalogInput = {
  catalogId: Scalars['ID']['input']
  group: Scalars['ID']['input']
  isInternal?: InputMaybe<Scalars['Boolean']['input']>
}

export type CatalogReference = {
  __typename?: 'CatalogReference'
  catalog?: Maybe<Catalog>
  catalogId: Scalars['ID']['output']
}

/** Catalogs - Originally PipeCatalogs */
export type Catalogs = {
  __typename?: 'Catalogs'
  all?: Maybe<CatalogsConnection>
  byId?: Maybe<Catalog>
  dataset?: Maybe<UpSpecification>
  datasets?: Maybe<UpSpecificationConnection>
}

/** Catalogs - Originally PipeCatalogs */
export type CatalogsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

/** Catalogs - Originally PipeCatalogs */
export type CatalogsByIdArgs = {
  catalogId: Scalars['ID']['input']
}

/** Catalogs - Originally PipeCatalogs */
export type CatalogsDatasetArgs = {
  catalogId: Scalars['ID']['input']
  dataset: Scalars['String']['input']
}

/** Catalogs - Originally PipeCatalogs */
export type CatalogsDatasetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type CatalogsConnection = {
  __typename?: 'CatalogsConnection'
  edges?: Maybe<Array<Maybe<CatalogEdge>>>
  pageInfo: PageInfo
}

export type CloneIssueTaskDataCollectionFormInput = {
  /** Set true to clone form responses from the source form into the target form. */
  cloneFormResponses?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  /** Optional. If not provided, the ownership is copied from the sourceForm */
  owner?: InputMaybe<EntityOwnerInput>
  sourceForm: Scalars['ID']['input']
  subjectAssetProfile?: InputMaybe<Scalars['ID']['input']>
  /** Set true to keep the Form Definition synchronized with future changes to the sourceForm */
  synchronizeFormDefinition?: InputMaybe<Scalars['Boolean']['input']>
  targetForm: Scalars['ID']['input']
  title?: InputMaybe<Scalars['String']['input']>
}

/** Implies open -> closed */
export type CloseIssueInput = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  /** If a string literal is used, it is attempted to be parsed as JSON first. Note: Workato does not escape quotes (`"`) in string literals, so prefix with backspace (`\"`) */
  contextData?: InputMaybe<Scalars['JSONObject']['input']>
  issueId: Scalars['ID']['input']
}

export type Clue = {
  __typename?: 'Clue'
  data: Scalars['JSONObject']['output']
  id: Scalars['ID']['output']
  images?: Maybe<Array<ClueImage>>
  type: ClueType
}

export type ClueGroup = {
  __typename?: 'ClueGroup'
  clues?: Maybe<Array<ClueReference>>
  type: ClueType
}

export type ClueImage = {
  __typename?: 'ClueImage'
  label: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ClueImageInput = {
  url: Scalars['String']['input']
}

export type ClueMutationError = MutationError & {
  __typename?: 'ClueMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ClueMutationResult = {
  __typename?: 'ClueMutationResult'
  clue?: Maybe<Clue>
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ClueMutationError>>
}

export type ClueReference = {
  __typename?: 'ClueReference'
  clue?: Maybe<Clue>
  clueId: Scalars['ID']['output']
}

export enum ClueType {
  ClueTypeCms = 'CLUE_TYPE_CMS',
  ClueTypeDrillingAndWell = 'CLUE_TYPE_DRILLING_AND_WELL',
  ClueTypeKuvaAlarm = 'CLUE_TYPE_KUVA_ALARM',
  ClueTypeMaintenanceNotification = 'CLUE_TYPE_MAINTENANCE_NOTIFICATION',
  ClueTypeOgiInspection = 'CLUE_TYPE_OGI_INSPECTION',
  ClueTypeOpenWorkOrder = 'CLUE_TYPE_OPEN_WORK_ORDER',
  ClueTypeOther = 'CLUE_TYPE_OTHER',
  ClueTypeRealTimeData = 'CLUE_TYPE_REAL_TIME_DATA',
  ClueTypeVentingFlaringBlowdown = 'CLUE_TYPE_VENTING_FLARING_BLOWDOWN',
}

export type Clues = {
  __typename?: 'Clues'
  clueGroups?: Maybe<Array<ClueGroup>>
}

export type CommentIssueInput = {
  issueId: Scalars['ID']['input']
  text: Scalars['String']['input']
}

export enum ComplianceSeverityType {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
}

export type Component = {
  __typename?: 'Component'
  name: Scalars['String']['output']
}

export type Config = {
  __typename?: 'Config'
  auth: AuthConfig
}

export type ConfirmReconciliationDecisionInput = {
  day: Scalars['Date']['input']
  siteId: Scalars['ID']['input']
  virtualMeterId: Scalars['ID']['input']
}

export type ConfirmReconciliationDecisionResult = {
  __typename?: 'ConfirmReconciliationDecisionResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ConfirmReconciliationDecisionResultError>>
  reconciledEmission?: Maybe<ReconciledEmission>
}

export type ConfirmReconciliationDecisionResultError = MutationError & {
  __typename?: 'ConfirmReconciliationDecisionResultError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ContributingEmissionObservation = {
  emissionObservationId: Scalars['ID']['input']
  externalId?: InputMaybe<Scalars['String']['input']>
}

export type CreateAssetComponentInput = {
  active: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group: Scalars['String']['input']
  name: Scalars['String']['input']
  parent?: InputMaybe<ParentInput>
  siteId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateAssetEquipmentInput = {
  active: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group: Scalars['String']['input']
  name: Scalars['String']['input']
  parent?: InputMaybe<ParentInput>
  siteId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateAssetInput = {
  active: Scalars['Boolean']['input']
  assetId?: InputMaybe<Scalars['ID']['input']>
  assetTypeId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group: Scalars['String']['input']
  name: Scalars['String']['input']
  parent?: InputMaybe<ParentInput>
  parentAssetReference?: InputMaybe<Scalars['ID']['input']>
  propertyValues?: InputMaybe<Array<CamAssetPropertyValueInput>>
  propertyValuesJson?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateAssetPipelineInput = {
  active: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group: Scalars['String']['input']
  name: Scalars['String']['input']
  parent?: InputMaybe<ParentInput>
}

export type CreateAssetSiteInput = {
  active: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group: Scalars['String']['input']
  name: Scalars['String']['input']
  parent?: InputMaybe<ParentInput>
  propertyValues?: InputMaybe<Array<CamAssetPropertyValueInput>>
  propertyValuesJson?: InputMaybe<Scalars['JSONObject']['input']>
  siteId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateAssetTypeInput = {
  assetTypeId?: InputMaybe<SystemAssetTypeId>
  group: Scalars['String']['input']
  name?: InputMaybe<Scalars['String']['input']>
}

export type CreateAssetWellInput = {
  active: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group: Scalars['String']['input']
  name: Scalars['String']['input']
  parent?: InputMaybe<ParentInput>
  siteId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateDetectionInput = {
  assetReference?: InputMaybe<Scalars['ID']['input']>
  confidenceIntervalLowerBound?: InputMaybe<DetectionEmissionRateValueInput>
  confidenceIntervalUpperBound?: InputMaybe<DetectionEmissionRateValueInput>
  detectedAt: Scalars['DateTime']['input']
  detectionSource: Scalars['String']['input']
  detectionType: DetectionType
  emissionsRate?: InputMaybe<DetectionEmissionRateValueInput>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  equipmentComponentName?: InputMaybe<Scalars['String']['input']>
  equipmentExplanation?: InputMaybe<Scalars['String']['input']>
  equipmentParentId?: InputMaybe<Scalars['ID']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<DetectionDistanceValueInput>
  measurementType?: InputMaybe<MeasurementType>
  precipitation?: InputMaybe<Scalars['Float']['input']>
  /** Mandatory for OGI type detections. */
  purpose?: InputMaybe<DetectionPurpose>
  /** S3 references for downloadable media. E.g. s3://bucket-name/kairos/media/2023/08/28/p-55286-es-100005918-job-1493.jpg */
  s3MediaUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  screeningId?: InputMaybe<Scalars['String']['input']>
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
  sourceDescription: Scalars['String']['input']
  sourceLatitude?: InputMaybe<Scalars['Float']['input']>
  sourceLongitude?: InputMaybe<Scalars['Float']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  temperature?: InputMaybe<DetectionTemperatureValueInput>
  windDirection?: InputMaybe<Scalars['Float']['input']>
  windSpeed?: InputMaybe<DetectionSpeedValueInput>
}

export type CreateEmissionAlarmInput = {
  contributingEmissionObservations: Array<ContributingEmissionObservation>
  externalId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type CreateEmissionEventInput = {
  emissionObservationIds: Array<Scalars['ID']['input']>
  eventDescription?: InputMaybe<Scalars['String']['input']>
}

export type CreateEmissionObservationInput = {
  assetReference?: InputMaybe<Scalars['ID']['input']>
  blowdown?: InputMaybe<Scalars['Boolean']['input']>
  confidenceIntervalLowerBound?: InputMaybe<DetectionEmissionRateValueInput>
  confidenceIntervalUpperBound?: InputMaybe<DetectionEmissionRateValueInput>
  description?: InputMaybe<Scalars['String']['input']>
  detectedAt: Scalars['DateTime']['input']
  detectionSource: Scalars['String']['input']
  detectionType: DetectionType
  emissionsRate?: InputMaybe<DetectionEmissionRateValueInput>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  equipmentComponentName?: InputMaybe<Scalars['String']['input']>
  equipmentExplanation?: InputMaybe<Scalars['String']['input']>
  equipmentParentId?: InputMaybe<Scalars['ID']['input']>
  equipmentType?: InputMaybe<Scalars['String']['input']>
  eventCause?: InputMaybe<Scalars['String']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<DetectionDistanceValueInput>
  id?: InputMaybe<Scalars['ID']['input']>
  measurementType?: InputMaybe<MeasurementType>
  /** Media files to be uploaded */
  mediaFiles?: InputMaybe<Array<MediaFileInput>>
  ongoingStatus?: InputMaybe<Scalars['Boolean']['input']>
  parentWorkflowId?: InputMaybe<Scalars['String']['input']>
  precipitation?: InputMaybe<Scalars['Float']['input']>
  /** Mandatory for OGI type detections. */
  purpose?: InputMaybe<DetectionPurpose>
  reportedBy?: InputMaybe<Scalars['String']['input']>
  requiredFieldsMissing?: InputMaybe<Array<Scalars['String']['input']>>
  s3MediaUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  screeningId?: InputMaybe<Scalars['String']['input']>
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
  sourceDescription: Scalars['String']['input']
  sourceLatitude?: InputMaybe<Scalars['Float']['input']>
  sourceLongitude?: InputMaybe<Scalars['Float']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  temperature?: InputMaybe<DetectionTemperatureValueInput>
  /** Mandatory for VFB */
  ventingEventType?: InputMaybe<VentingEventType>
  windDirection?: InputMaybe<Scalars['Float']['input']>
  windSpeed?: InputMaybe<DetectionSpeedValueInput>
}

export type CreateEventHubSubscriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>
  enabled?: InputMaybe<Scalars['Boolean']['input']>
  filter: EventHubSubscriptionFilterInput
  id?: InputMaybe<Scalars['ID']['input']>
  messageTemplate?: InputMaybe<Scalars['String']['input']>
  targets: Array<EventHubNotificationTargetInput>
}

export type CreateFormListInput = {
  /**
   * **description** is the description of the form list to be created if provided.
   *
   * **Note**:
   *
   * 1. **description** cannot be longer than 4096 characters if provided.
   */
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * **id** is the identification of the form list to be created. For example, "game-of-thrones-characters" and "breaking-bad-characters".
   *
   * **Note**:
   *
   * 1. **id** must be a non-zero length string with only **numbers**, **letters** and **-** if provided.
   * 1. **id** can not be longer than 256.
   * 1. a duplicated **id** is not acceptable.
   */
  id?: InputMaybe<Scalars['ID']['input']>
  /** **items** is the initial items included within the form list to be created if provided. */
  items?: InputMaybe<Array<FormListItemInput>>
  /**
   * **name** is the name of the form list to be created.
   *
   * **Note**:
   *
   * 1. **id** must be a non-zero length string.
   * 1. **id** can not be longer than 4096.
   */
  name: Scalars['String']['input']
}

export type CreateInspectionRequestInput = {
  additionalComment?: InputMaybe<Scalars['String']['input']>
  attributionExplanation?: InputMaybe<Scalars['String']['input']>
  cluesToShare?: InputMaybe<Array<Scalars['ID']['input']>>
  /** A reference to a Form ID to create a Task Issue. Only one of 'dataCollectionFormReference' or 'multitaskProcedureId' should be set. */
  dataCollectionFormReference?: InputMaybe<Scalars['String']['input']>
  detectionId: Scalars['ID']['input']
  equipmentComponentName?: InputMaybe<Scalars['String']['input']>
  equipmentId?: InputMaybe<Scalars['ID']['input']>
  /** ID of the corresponding inspection request in the external source */
  externalReferenceId?: InputMaybe<Scalars['String']['input']>
  /** Name of the external source containing a corresponding inspection request */
  externalSourceName?: InputMaybe<InspectionRequestExternalSourceName>
  group?: InputMaybe<Scalars['String']['input']>
  inspectionRequestId?: InputMaybe<Scalars['ID']['input']>
  inspectionType: InspectionType
  /** A reference to a Procedure ID to create a Multitask Issue. Only one of 'dataCollectionFormReference' or 'multitaskProcedureId' should be set. */
  multitaskProcedureId?: InputMaybe<Scalars['String']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
}

export type CreateIssueTaskDataCollectionFormInput = {
  description?: InputMaybe<Scalars['String']['input']>
  formType: IssueTaskDataCollectionFormType
  id: Scalars['XFormReference']['input']
  jsonFormBody?: InputMaybe<JsonFormBodyInput>
  /** The group can be set so that super admins can define which group the Form belongs to. The username is ignored until the system supports creating entities owned by other users. */
  owner?: InputMaybe<EntityOwnerInput>
  subjectAssetProfile?: InputMaybe<Scalars['ID']['input']>
  title: Scalars['String']['input']
}

export type CreateIssueTaskResponsesViewInput = {
  issueIds?: InputMaybe<Array<Scalars['ID']['input']>>
  profile: Scalars['ID']['input']
}

export type CreateMapInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  layers: Array<Scalars['JSONObject']['input']>
  name: Scalars['String']['input']
  /** The group can be set so that super admins can define which group the Map belongs to. The username is ignored until the system supports creating entities owned by other users. */
  owner?: InputMaybe<EntityOwnerInput>
  selectedDateTimeRange?: InputMaybe<DateTimeRangeInput>
  spatialFilterEnabled?: InputMaybe<Scalars['Boolean']['input']>
  style: Scalars['String']['input']
  /** During create or update, the client pass the content of thumbnail, and for read the server return the S3 signed url for that content */
  thumbnailContent?: InputMaybe<Scalars['String']['input']>
  /** During cloning, the client can send the thumbnailPath and the resolver will do the clone in S3 */
  thumbnailPath?: InputMaybe<Scalars['String']['input']>
  timelineIntervalUnit?: InputMaybe<Scalars['String']['input']>
  viewState: Scalars['JSONObject']['input']
}

export type CreateNoDetectionInput = {
  additionalData?: InputMaybe<Scalars['JSONObject']['input']>
  assetReference?: InputMaybe<Scalars['ID']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  noDetectionId: Scalars['ID']['input']
  noDetectionTime: Scalars['DateTime']['input']
  noDetectionType: NoDetectionType
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
}

export type CreateSiteBeaconInput = {
  geometry: GeoJsonGeometryInput
  id?: InputMaybe<Scalars['ID']['input']>
  properties: CreateSiteBeaconPropertiesInput
  siteId: Scalars['ID']['input']
}

export type CreateSiteBeaconPropertiesInput = {
  UUID?: InputMaybe<Scalars['String']['input']>
  active?: InputMaybe<Scalars['Boolean']['input']>
  brand?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  deviceId?: InputMaybe<Scalars['String']['input']>
  macAddress: Scalars['String']['input']
  major?: InputMaybe<Scalars['Int']['input']>
  minor?: InputMaybe<Scalars['Int']['input']>
  name: Scalars['String']['input']
  parentId: Scalars['ID']['input']
  sensitivity?: InputMaybe<Scalars['Float']['input']>
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateSiteBuildingInput = {
  geometry: GeoJsonGeometryInput
  properties: CreateSiteBuildingPropertiesInput
  siteId: Scalars['ID']['input']
}

export type CreateSiteBuildingPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  parentId: Scalars['ID']['input']
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateSiteInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  geometry: GeoJsonGeometryInput
  group?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  properties: CreateSitePropertiesInput
  thumbnail?: InputMaybe<Scalars['String']['input']>
}

export type CreateSiteLevelInput = {
  geometry: GeoJsonGeometryInput
  properties: CreateSiteLevelPropertiesInput
  siteId: Scalars['ID']['input']
}

export type CreateSiteLevelPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  floorHeight?: InputMaybe<Scalars['Float']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  ordinal: Scalars['Int']['input']
  parentId: Scalars['ID']['input']
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateSiteLocationInput = {
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id?: InputMaybe<Scalars['ID']['input']>
  properties: CreateSiteLocationPropertiesInput
  siteId: Scalars['ID']['input']
}

export type CreateSiteLocationPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  parentId: Scalars['ID']['input']
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateSitePathwayInput = {
  geometry: GeoJsonGeometryInput
  properties: CreateSitePathwayPropertiesInput
  siteId: Scalars['ID']['input']
}

export type CreateSitePathwayPropertiesInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  parentId: Scalars['ID']['input']
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateSitePropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateSiteUnitInput = {
  geometry: GeoJsonGeometryInput
  properties: CreateSiteUnitPropertiesInput
  siteId: Scalars['ID']['input']
}

export type CreateSiteUnitPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  parentId: Scalars['ID']['input']
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type CreateUserInput = {
  email: Scalars['String']['input']
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>
  familyName: Scalars['String']['input']
  givenName: Scalars['String']['input']
  group: Scalars['String']['input']
  /** Only for internal graphql */
  overrideMfaRequired?: InputMaybe<Scalars['Boolean']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phoneVerified?: InputMaybe<Scalars['Boolean']['input']>
  preferences?: InputMaybe<Array<UpdateUserPreferenceInput>>
  role: UserRole
  timezone?: InputMaybe<Scalars['String']['input']>
  username: Scalars['ID']['input']
}

export type CreateVentingEventInput = {
  assetReference?: InputMaybe<Scalars['ID']['input']>
  blowdown: Scalars['Boolean']['input']
  description?: InputMaybe<Scalars['String']['input']>
  detectedAt: Scalars['DateTime']['input']
  emissionsRate?: InputMaybe<Scalars['Float']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  equipmentParentId?: InputMaybe<Scalars['ID']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  measurementType?: InputMaybe<MeasurementType>
  ongoingStatus: Scalars['Boolean']['input']
  requiredFieldsMissing?: InputMaybe<Array<Scalars['String']['input']>>
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
  sourceLatitude?: InputMaybe<Scalars['Float']['input']>
  sourceLongitude?: InputMaybe<Scalars['Float']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  type: VentingEventType
}

export type CreateWorkflowInput = {
  description?: InputMaybe<Scalars['String']['input']>
  diagram?: InputMaybe<Scalars['JSONObject']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  isInternal?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  widgets?: InputMaybe<Array<WorkflowWidgetInput>>
}

export type DataCollectionFormMedium = {
  __typename?: 'DataCollectionFormMedium'
  downloadUrl: Scalars['String']['output']
  mediaKey: Scalars['String']['output']
  modifiedAt: Scalars['DateTime']['output']
}

export enum DateTimeBuiltInConstant {
  BeginningOfTime = 'BEGINNING_OF_TIME',
  EndOfTime = 'END_OF_TIME',
  Now = 'NOW',
}

export enum DateTimeComparisonEditorLastUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export enum DateTimeComparisonEditorType {
  After = 'AFTER',
  Before = 'BEFORE',
  Between = 'BETWEEN',
  CurrentDay = 'CURRENT_DAY',
  CurrentWeek = 'CURRENT_WEEK',
  Last = 'LAST',
  On = 'ON',
  PreviousDay = 'PREVIOUS_DAY',
}

export type DateTimeConstantOperand = {
  __typename?: 'DateTimeConstantOperand'
  constant: DateTimeBuiltInConstant
}

export enum DateTimeDelayUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
}

export type DateTimeInput = {
  constant?: InputMaybe<DateTimeBuiltInConstant>
  property?: InputMaybe<Scalars['String']['input']>
  unionType?: InputMaybe<DateTimeOperandUnionType>
  value?: InputMaybe<Scalars['DateTime']['input']>
}

export type DateTimeManipulation = {
  __typename?: 'DateTimeManipulation'
  manipulationMethod?: Maybe<DateTimeManipulationMethod>
  manipulationUnit?: Maybe<DateTimeManipulationUnit>
  manipulationValue?: Maybe<FloatOperand>
}

export type DateTimeManipulationInput = {
  manipulationMethod?: InputMaybe<DateTimeManipulationMethod>
  manipulationUnit?: InputMaybe<DateTimeManipulationUnit>
  manipulationValue?: InputMaybe<NumericOperandInput>
}

export enum DateTimeManipulationMethod {
  EndOf = 'END_OF',
  Minus = 'MINUS',
  Plus = 'PLUS',
  StartOf = 'START_OF',
}

export enum DateTimeManipulationUnit {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK',
  Year = 'YEAR',
}

export enum DateTimeMonitorDurationUnit {
  Minute = 'MINUTE',
}

export type DateTimeOperand =
  | DateTimeConstantOperand
  | DateTimeValueOperand
  | PropertyOperand

export enum DateTimeOperandUnionType {
  Constant = 'CONSTANT',
  Property = 'PROPERTY',
  Value = 'VALUE',
}

export enum DateTimePrecision {
  Day = 'DAY',
  Minute = 'MINUTE',
}

export type DateTimeRange = {
  __typename?: 'DateTimeRange'
  end?: Maybe<Scalars['DateTime']['output']>
  start?: Maybe<Scalars['DateTime']['output']>
}

export type DateTimeRangeInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>
  start?: InputMaybe<Scalars['DateTime']['input']>
}

export type DateTimeValueOperand = {
  __typename?: 'DateTimeValueOperand'
  value: Scalars['DateTime']['output']
}

export type Decision = {
  __typename?: 'Decision'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  /** User who submitted the decision */
  createdBy?: Maybe<UserReference>
  decidedAt?: Maybe<Scalars['DateTime']['output']>
  decisionReference?: Maybe<Scalars['String']['output']>
  /** New value for measured emission output */
  emissionKilograms?: Maybe<Scalars['Float']['output']>
  /** Explanation of the method used to determine new value for measured emission output */
  explanation?: Maybe<Scalars['String']['output']>
  /** Manually submitted override for measured emission output is confirmed or not */
  isConfirmed?: Maybe<Scalars['Boolean']['output']>
  /** Methodology used to determine new value for measured emission output */
  methodology?: Maybe<DecisionMethodology>
  source?: Maybe<DecisionSource>
  target?: Maybe<DecisionTarget>
}

export enum DecisionArea {
  DecisionAreaBottomUpInventory = 'DECISION_AREA_BOTTOM_UP_INVENTORY',
  DecisionAreaVentingFlaringBlowdowns = 'DECISION_AREA_VENTING_FLARING_BLOWDOWNS',
}

export type DecisionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  decidedAt?: InputMaybe<Scalars['DateTime']['input']>
  decisionReference: Scalars['String']['input']
  source?: InputMaybe<DecisionSource>
  target?: InputMaybe<DecisionTarget>
}

export enum DecisionMethodology {
  DecisionMethodologyAverage = 'DECISION_METHODOLOGY_AVERAGE',
  DecisionMethodologyManualOverride = 'DECISION_METHODOLOGY_MANUAL_OVERRIDE',
  DecisionMethodologyMaximum = 'DECISION_METHODOLOGY_MAXIMUM',
  DecisionMethodologyMinimum = 'DECISION_METHODOLOGY_MINIMUM',
}

export enum DecisionSource {
  DecisionSourceBottomUp = 'DECISION_SOURCE_BOTTOM_UP',
  DecisionSourceManualEmission = 'DECISION_SOURCE_MANUAL_EMISSION',
  DecisionSourceSiteRemainder = 'DECISION_SOURCE_SITE_REMAINDER',
  DecisionSourceTopDown = 'DECISION_SOURCE_TOP_DOWN',
  DecisionSourceVentingFlaringBlowdown = 'DECISION_SOURCE_VENTING_FLARING_BLOWDOWN',
}

export enum DecisionTarget {
  DecisionTargetEmission = 'DECISION_TARGET_EMISSION',
}

/** An optional input parameter for issue mutations which might be sent delayed */
export type DelayedIssueMutationInput = {
  mutationAt: Scalars['DateTime']['input']
  retryCount: Scalars['Int']['input']
}

export type DeleteAssetTypeInput = {
  assetTypeId: Scalars['ID']['input']
}

export type DeleteEmissionEventsInput = {
  emissionEventIds: Array<Scalars['ID']['input']>
}

export type DeleteEmissionObservationInput = {
  id: Scalars['ID']['input']
}

export type DeleteIssueCommentInput = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  index: Scalars['Int']['input']
  issueId: Scalars['ID']['input']
}

export type DeleteIssueInput = {
  issueId: Scalars['ID']['input']
}

export type DeleteIssueTaskDataCollectionFormInput = {
  id: Scalars['XFormReference']['input']
}

export type DeleteNoDetectionInput = {
  noDetectionId: Scalars['ID']['input']
}

export enum DerivedFromType {
  Calculated = 'CALCULATED',
  EmissionFactor = 'EMISSION_FACTOR',
  Measured = 'MEASURED',
}

export type Detection = EmissionObservation & {
  __typename?: 'Detection'
  acknowledged: Scalars['Boolean']['output']
  additionalData?: Maybe<Scalars['JSONObject']['output']>
  alarmExternalId?: Maybe<Scalars['String']['output']>
  alarmId?: Maybe<Scalars['ID']['output']>
  audit: EmissionObservationAudit
  camAssetReference?: Maybe<CamAssetReference>
  camSourceAssetReference?: Maybe<CamAssetReference>
  closed: Scalars['Boolean']['output']
  clues?: Maybe<Clues>
  complianceDeadline?: Maybe<Scalars['DateTime']['output']>
  complianceSeverity?: Maybe<ComplianceSeverityType>
  component?: Maybe<EquipmentComponent>
  confidenceIntervalLowerBound?: Maybe<EmissionObservationEmissionRateValue>
  /** Use sourceAttribution */
  confidenceIntervalUpperBound?: Maybe<EmissionObservationEmissionRateValue>
  correlatedEmissionObservationCount: Scalars['Int']['output']
  correlatedEmissionObservations?: Maybe<Array<Maybe<EmissionObservation>>>
  detectedAt: Scalars['DateTime']['output']
  detectionSource: Scalars['String']['output']
  detectionType: DetectionType
  emissionEventId?: Maybe<Scalars['ID']['output']>
  emissionsRate?: Maybe<EmissionObservationEmissionRateValue>
  endTime?: Maybe<Scalars['DateTime']['output']>
  /** @deprecated No longer supported */
  equipmentExplanation?: Maybe<Scalars['String']['output']>
  equipmentParentId?: Maybe<Scalars['ID']['output']>
  /** @deprecated Use camSourceAssetReference instead */
  equipmentReference?: Maybe<EquipmentReference>
  group?: Maybe<Scalars['String']['output']>
  height?: Maybe<DetectionDistanceValue>
  id: Scalars['ID']['output']
  inputType: EmissionObservationInputType
  inspectionRequestReference?: Maybe<InspectionRequestReference>
  issues?: Maybe<Array<IssueReference>>
  lastComplianceActionAt?: Maybe<Scalars['DateTime']['output']>
  measurementType?: Maybe<MeasurementType>
  media?: Maybe<Array<DetectionMedium>>
  mediaUploadUrls: Array<Scalars['String']['output']>
  pendingResolution?: Maybe<EmissionObservationReference>
  precipitation?: Maybe<Scalars['Float']['output']>
  /** @deprecated Removing Priority Feature */
  priority?: Maybe<EmissionObservationPriority>
  procedure?: Maybe<Procedure>
  purpose?: Maybe<DetectionPurpose>
  resolution?: Maybe<DetectionResolution>
  screeningId?: Maybe<Scalars['String']['output']>
  shortId?: Maybe<Scalars['ID']['output']>
  siteMonthReference?: Maybe<SiteMonthReference>
  /** @deprecated Use camAssetReference instead */
  siteReference?: Maybe<SiteReference>
  sourceAttribution?: Maybe<SourceAttribution>
  sourceDescription?: Maybe<Scalars['String']['output']>
  sourceLatitude?: Maybe<Scalars['Float']['output']>
  sourceLongitude?: Maybe<Scalars['Float']['output']>
  startTime?: Maybe<Scalars['DateTime']['output']>
  status: DetectionStatus
  temperature?: Maybe<DetectionTemperatureValue>
  uncertainty?: Maybe<Scalars['Float']['output']>
  vfbReportRequestedExplanation?: Maybe<Scalars['String']['output']>
  vfbReportRequestedRecipients?: Maybe<Array<Scalars['String']['output']>>
  windDirection?: Maybe<Scalars['Float']['output']>
  windSpeed?: Maybe<DetectionSpeedValue>
  wip: Scalars['Boolean']['output']
}

export type DetectionMediaUploadUrlsArgs = {
  mediaKeys?: InputMaybe<Array<Scalars['String']['input']>>
}

export type DetectionCount = {
  __typename?: 'DetectionCount'
  count: Scalars['Int']['output']
  detectionType: DetectionType
}

export type DetectionDistanceValue = {
  __typename?: 'DetectionDistanceValue'
  unit: DistanceUnitType
  value: Scalars['Float']['output']
}

export type DetectionDistanceValueInput = {
  unit: DistanceUnitType
  value: Scalars['Float']['input']
}

export type DetectionEdge = {
  __typename?: 'DetectionEdge'
  node?: Maybe<Detection>
}

export type DetectionEmissionRateValueInput = {
  unit: EmissionRateUnitType
  value: Scalars['Float']['input']
}

export type DetectionFilter = {
  detectionType?: InputMaybe<Array<DetectionType>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  inputType?: InputMaybe<Array<EmissionObservationInputType>>
}

export type DetectionInput = {
  detectionId: Scalars['ID']['input']
  emissionEndDate?: InputMaybe<Scalars['DateTime']['input']>
  emissionStartDate?: InputMaybe<Scalars['DateTime']['input']>
  totalVolume?: InputMaybe<Scalars['Float']['input']>
}

export type DetectionMedium = {
  __typename?: 'DetectionMedium'
  downloadUrl: Scalars['String']['output']
  mediaKey: Scalars['String']['output']
}

export type DetectionMutationError = MutationError & {
  __typename?: 'DetectionMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type DetectionMutationResult = {
  __typename?: 'DetectionMutationResult'
  correlationId: Scalars['ID']['output']
  detection?: Maybe<Detection>
  errors?: Maybe<Array<DetectionMutationError>>
}

export enum DetectionPurpose {
  DetectionPurposeFollowup = 'DETECTION_PURPOSE_FOLLOWUP',
  DetectionPurposeRepair = 'DETECTION_PURPOSE_REPAIR',
  DetectionPurposeScheduled = 'DETECTION_PURPOSE_SCHEDULED',
  DetectionPurposeVerification = 'DETECTION_PURPOSE_VERIFICATION',
}

export type DetectionReference = {
  __typename?: 'DetectionReference'
  detection?: Maybe<Detection>
  detectionId: Scalars['ID']['output']
}

export type DetectionResolution = {
  __typename?: 'DetectionResolution'
  correlatedClue?: Maybe<ClueReference>
  /** @deprecated Use correlatedEmissionObservation instead */
  correlatedDetection?: Maybe<DetectionReference>
  correlatedEmissionObservation?: Maybe<EmissionObservationReference>
  explanation?: Maybe<Scalars['String']['output']>
  resolutionMode: ResolutionMode
}

export type DetectionSpeedValue = {
  __typename?: 'DetectionSpeedValue'
  unit: SpeedUnitType
  value: Scalars['Float']['output']
}

export type DetectionSpeedValueInput = {
  unit: SpeedUnitType
  value: Scalars['Float']['input']
}

export enum DetectionStatus {
  DetectionStatusDroneCompleted = 'DETECTION_STATUS_DRONE_COMPLETED',
  DetectionStatusDroneRequested = 'DETECTION_STATUS_DRONE_REQUESTED',
  DetectionStatusDroneScheduled = 'DETECTION_STATUS_DRONE_SCHEDULED',
  DetectionStatusLeakRepaired = 'DETECTION_STATUS_LEAK_REPAIRED',
  DetectionStatusLeakRepairScheduled = 'DETECTION_STATUS_LEAK_REPAIR_SCHEDULED',
  DetectionStatusNew = 'DETECTION_STATUS_NEW',
  DetectionStatusOgiCompleted = 'DETECTION_STATUS_OGI_COMPLETED',
  DetectionStatusOgiRequested = 'DETECTION_STATUS_OGI_REQUESTED',
  DetectionStatusOgiScheduled = 'DETECTION_STATUS_OGI_SCHEDULED',
  DetectionStatusPending = 'DETECTION_STATUS_PENDING',
  DetectionStatusPendingResolution = 'DETECTION_STATUS_PENDING_RESOLUTION',
  DetectionStatusResolved = 'DETECTION_STATUS_RESOLVED',
  DetectionStatusVerificationScheduled = 'DETECTION_STATUS_VERIFICATION_SCHEDULED',
  DetectionStatusVfbReportRequested = 'DETECTION_STATUS_VFB_REPORT_REQUESTED',
  DetectionStatusVisualInspectionCompleted = 'DETECTION_STATUS_VISUAL_INSPECTION_COMPLETED',
  DetectionStatusVisualInspectionScheduled = 'DETECTION_STATUS_VISUAL_INSPECTION_SCHEDULED',
}

export type DetectionTemperatureValue = {
  __typename?: 'DetectionTemperatureValue'
  unit: TemperatureUnitType
  value: Scalars['Float']['output']
}

export type DetectionTemperatureValueInput = {
  unit: TemperatureUnitType
  value: Scalars['Float']['input']
}

export enum DetectionType {
  DetectionTypeCms = 'DETECTION_TYPE_CMS',
  DetectionTypeDrone = 'DETECTION_TYPE_DRONE',
  DetectionTypeFlyover = 'DETECTION_TYPE_FLYOVER',
  DetectionTypeManual = 'DETECTION_TYPE_MANUAL',
  DetectionTypeOgi = 'DETECTION_TYPE_OGI',
  /** On-site Walk Down */
  DetectionTypeOwd = 'DETECTION_TYPE_OWD',
  DetectionTypeSatellite = 'DETECTION_TYPE_SATELLITE',
  DetectionTypeUnknown = 'DETECTION_TYPE_UNKNOWN',
  DetectionTypeVentingFlaringBlowdown = 'DETECTION_TYPE_VENTING_FLARING_BLOWDOWN',
}

export type DetectionsConnection = {
  __typename?: 'DetectionsConnection'
  edges?: Maybe<Array<Maybe<DetectionEdge>>>
  pageInfo: PageInfo
}

export type DisableEventHubSubscriptionInput = {
  subscriptionId?: InputMaybe<Scalars['ID']['input']>
}

export enum DistanceUnitType {
  Feet = 'FEET',
}

export type DumpAssetTypesStateInput = {
  assetTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>
  seedLimit?: InputMaybe<Scalars['Int']['input']>
}

export enum DurationMethodType {
  Estimated = 'ESTIMATED',
  Observed = 'OBSERVED',
}

export type EmissionAlarmMutationError = MutationError & {
  __typename?: 'EmissionAlarmMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type EmissionAlarmMutationResult = {
  __typename?: 'EmissionAlarmMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<EmissionAlarmMutationError>>
}

export type EmissionAssetFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  assetReference?: InputMaybe<Array<Scalars['ID']['input']>>
  assetTypeId?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Array<Scalars['String']['input']>>
  parentAssetReference?: InputMaybe<Array<Scalars['String']['input']>>
  propertyFilters?: InputMaybe<Array<EmissionAssetPropertyFilter>>
}

export type EmissionAssetFilterInput = {
  properties?: InputMaybe<Array<EmissionAssetFilterPropertyInput>>
}

export type EmissionAssetFilterProperty = {
  __typename?: 'EmissionAssetFilterProperty'
  assetTypeIds?: Maybe<Array<Scalars['ID']['output']>>
  icon?: Maybe<Scalars['String']['output']>
  isMulti: Scalars['Boolean']['output']
  key: Scalars['ID']['output']
  label: Scalars['String']['output']
  options?: Maybe<Array<EmissionAssetFilterPropertyOption>>
  property: Scalars['ID']['output']
}

export type EmissionAssetFilterPropertyOptionsArgs = {
  search?: InputMaybe<Scalars['String']['input']>
}

export type EmissionAssetFilterPropertyInput = {
  assetTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>
  icon?: InputMaybe<Scalars['String']['input']>
  isMulti?: Scalars['Boolean']['input']
  key?: InputMaybe<Scalars['ID']['input']>
  label?: InputMaybe<Scalars['String']['input']>
  property: Scalars['ID']['input']
}

export type EmissionAssetFilterPropertyOption = {
  __typename?: 'EmissionAssetFilterPropertyOption'
  label?: Maybe<Scalars['String']['output']>
  value: Scalars['JSONObject']['output']
  valueCount?: Maybe<Scalars['Int']['output']>
}

export type EmissionAssetFilterSpecifications = {
  __typename?: 'EmissionAssetFilterSpecifications'
  assetFilterProperties?: Maybe<Array<EmissionAssetFilterProperty>>
  assetFilterProperty?: Maybe<EmissionAssetFilterProperty>
}

export type EmissionAssetFilterSpecificationsAssetFilterPropertyArgs = {
  property: Scalars['ID']['input']
}

export type EmissionAssetPropertyFilter = {
  property: Scalars['ID']['input']
  valueJson?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']['input']>>>
}

export type EmissionConfig = {
  __typename?: 'EmissionConfig'
  assetFilterSpecifications?: Maybe<EmissionAssetFilterSpecifications>
}

export type EmissionConfigAssetFilterSpecificationsArgs = {
  group: Scalars['ID']['input']
}

export type EmissionConfigMutationError = MutationError & {
  __typename?: 'EmissionConfigMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type EmissionConfigMutationResult = {
  __typename?: 'EmissionConfigMutationResult'
  config?: Maybe<EmissionSimpleConfig>
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<EmissionConfigMutationError>>
}

export type EmissionConfigs = {
  __typename?: 'EmissionConfigs'
  assetFilterSpecifications?: Maybe<EmissionAssetFilterSpecifications>
  config?: Maybe<EmissionSimpleConfig>
  emissionEventForDetailPanelProperties: Array<Scalars['ID']['output']>
}

export type EmissionConfigsConfigArgs = {
  id: Scalars['ID']['input']
}

export type EmissionConfigsMutationResult = {
  __typename?: 'EmissionConfigsMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<EmissionConfigMutationError>>
}

export type EmissionCustomFilter = {
  key: Scalars['ID']['input']
  valueJson?: InputMaybe<Array<InputMaybe<Scalars['JSONObject']['input']>>>
}

export type EmissionCustomFilterSpecification = {
  __typename?: 'EmissionCustomFilterSpecification'
  expression: Scalars['JSONObject']['output']
  icon?: Maybe<Scalars['String']['output']>
  isMulti: Scalars['Boolean']['output']
  key: Scalars['ID']['output']
  label: Scalars['String']['output']
  options?: Maybe<Array<EmissionCustomFilterSpecificationOption>>
}

/**
 * From su-explorer/src/app/MissionControlMethaneSolution/constants/detection.ts
 * {
 * key: DETECTION_LIST_FILTER_TYPES.specialSite,
 * label: 'Special Sites',
 * icon: 'MethaneInboxIcon',
 * isMulti: false,
 * options: [
 * { value: true, label: 'Yes' },
 * { value: false, label: 'No' },
 * ],
 * expression: "assetReference IN ('[list]')"
 * }
 */
export type EmissionCustomFilterSpecificationInput = {
  expression: Scalars['JSONObject']['input']
  icon?: InputMaybe<Scalars['String']['input']>
  isMulti?: InputMaybe<Scalars['Boolean']['input']>
  key: Scalars['ID']['input']
  label?: InputMaybe<Scalars['String']['input']>
  options?: InputMaybe<Array<EmissionCustomFilterSpecificationOptionInput>>
}

export type EmissionCustomFilterSpecificationOption = {
  __typename?: 'EmissionCustomFilterSpecificationOption'
  label?: Maybe<Scalars['String']['output']>
  value: Scalars['JSONObject']['output']
}

export type EmissionCustomFilterSpecificationOptionInput = {
  label?: InputMaybe<Scalars['String']['input']>
  value: Scalars['JSONObject']['input']
}

export type EmissionEvent = {
  __typename?: 'EmissionEvent'
  action?: Maybe<ActionType>
  camAssetReference?: Maybe<CamAssetReference>
  /** @deprecated use createdTime instead */
  createdAt: Scalars['DateTime']['output']
  createdBy?: Maybe<Scalars['String']['output']>
  createdTime: Scalars['DateTime']['output']
  detectedAt?: Maybe<Scalars['DateTime']['output']>
  durationMethod?: Maybe<DurationMethodType>
  emissionQuantities?: Maybe<Array<Maybe<EmissionQuantity>>>
  emissionsRate?: Maybe<EmissionObservationEmissionRateValue>
  emissionsVolume?: Maybe<EmissionsVolume>
  endTime?: Maybe<Scalars['DateTime']['output']>
  endTimeSource?: Maybe<EmissionEventTimeSourceReference>
  eventCategory: EmissionEventCategory
  eventDescription?: Maybe<Scalars['String']['output']>
  eventType: EmissionEventType
  falseAlarm?: Maybe<FalseAlarm>
  group: Scalars['String']['output']
  id: Scalars['ID']['output']
  isManualStatus?: Maybe<Scalars['Boolean']['output']>
  noDetections?: Maybe<Array<Maybe<NoDetectionReference>>>
  observationCountsByType: Scalars['ObservationCountsByType']['output']
  observations: Array<EmissionObservationReference>
  shortId: Scalars['String']['output']
  siteId: Scalars['String']['output']
  siteLevelEmissionsRate?: Maybe<EmissionsRate>
  siteReference?: Maybe<SiteReference>
  source: SourceType
  sourceLevelEmissionsRate?: Maybe<EmissionsRate>
  startTime: Scalars['DateTime']['output']
  startTimeSource?: Maybe<EmissionEventTimeSourceReference>
  status?: Maybe<EmissionEventStatus>
  statusExplanation?: Maybe<Scalars['String']['output']>
  statusReasons?: Maybe<Array<StatusReason>>
  updatedBy?: Maybe<Scalars['String']['output']>
  updatedTime?: Maybe<Scalars['DateTime']['output']>
}

export type EmissionEventEmissionsRateArgs = {
  rule?: InputMaybe<ObservationSelectionRule>
  unit?: InputMaybe<EmissionRateUnitType>
}

export type EmissionEventEmissionsVolumeArgs = {
  rule?: InputMaybe<ObservationSelectionRule>
  unit?: InputMaybe<EmissionUnitQuantityType>
}

export type EmissionEventSiteLevelEmissionsRateArgs = {
  rule?: InputMaybe<ObservationSelectionRule>
}

export type EmissionEventSourceLevelEmissionsRateArgs = {
  rule?: InputMaybe<ObservationSelectionRule>
}

export enum EmissionEventAction {
  Close = 'CLOSE',
  FalseAlarm = 'FALSE_ALARM',
  Reopen = 'REOPEN',
  UndoFalseAlarm = 'UNDO_FALSE_ALARM',
}

export type EmissionEventAttributionInput = {
  emissionEventId: Scalars['ID']['input']
  eventAssetReference?: InputMaybe<Scalars['String']['input']>
  explanation?: InputMaybe<Scalars['String']['input']>
  sourceAssetReference: Scalars['String']['input']
  sourceCategory?: InputMaybe<Scalars['String']['input']>
}

export enum EmissionEventCategory {
  Combustion = 'COMBUSTION',
  Flaring = 'FLARING',
  Fugitive = 'FUGITIVE',
  Venting = 'VENTING',
}

export type EmissionEventDeletionResult = {
  __typename?: 'EmissionEventDeletionResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<EmissionEventMutationError>>
}

export enum EmissionEventDurationUnit {
  Hour = 'HOUR',
  Minute = 'MINUTE',
}

export type EmissionEventEdge = {
  __typename?: 'EmissionEventEdge'
  cursor?: Maybe<Scalars['String']['output']>
  node?: Maybe<EmissionEvent>
}

export type EmissionEventMutationError = MutationError & {
  __typename?: 'EmissionEventMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type EmissionEventMutationResult = {
  __typename?: 'EmissionEventMutationResult'
  correlationId: Scalars['ID']['output']
  emissionEvent?: Maybe<EmissionEvent>
  errors?: Maybe<Array<EmissionEventMutationError>>
}

export enum EmissionEventStatus {
  Closed = 'CLOSED',
  Clue = 'CLUE',
  FalseAlarm = 'FALSE_ALARM',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export type EmissionEventTimeSourceReference =
  | EmissionObservationTimeSourceReference
  | NoDetectionTimeSourceReference

export enum EmissionEventType {
  Known = 'KNOWN',
  Unknown = 'UNKNOWN',
}

export type EmissionEvents = {
  __typename?: 'EmissionEvents'
  aggregation?: Maybe<EmissionEventsSummary>
  all?: Maybe<EmissionEventsConnection>
  /** @deprecated use all */
  byAssetReference?: Maybe<Array<Maybe<EmissionEvent>>>
  byId?: Maybe<EmissionEvent>
  relevantToTarget?: Maybe<Array<Maybe<EmissionEvent>>>
}

export type EmissionEventsAggregationArgs = {
  filter?: InputMaybe<EmissionEventsAggregationFilter>
  topAssetsNumber?: InputMaybe<Scalars['Int']['input']>
}

export type EmissionEventsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter: EmissionEventsFilter
  first?: InputMaybe<Scalars['Int']['input']>
}

export type EmissionEventsByAssetReferenceArgs = {
  filter: ByAssetReferenceFilter
}

export type EmissionEventsByIdArgs = {
  id: Scalars['ID']['input']
}

export type EmissionEventsRelevantToTargetArgs = {
  filter: RelevantToTargetEmissionEventsFilter
}

export type EmissionEventsAggregationFilter = {
  assetFilters?: InputMaybe<EmissionAssetFilter>
  assetReference?: InputMaybe<Array<Scalars['String']['input']>>
  customFilters?: InputMaybe<Array<EmissionCustomFilter>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  observationType?: InputMaybe<Array<DetectionType>>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Array<EmissionEventStatus>>
}

export type EmissionEventsConnection = {
  __typename?: 'EmissionEventsConnection'
  edges?: Maybe<Array<Maybe<EmissionEventEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type EmissionEventsCorrelationInput = {
  emissionEventIds?: InputMaybe<Array<Scalars['ID']['input']>>
  targetEmissionEventId?: InputMaybe<Scalars['ID']['input']>
}

export type EmissionEventsCorrelationResult = {
  __typename?: 'EmissionEventsCorrelationResult'
  correlationId: Scalars['ID']['output']
  emissionEvent?: Maybe<EmissionEvent>
  emissionEventId?: Maybe<Scalars['ID']['output']>
  errors?: Maybe<Array<EmissionEventMutationError>>
}

export type EmissionEventsFilter = {
  assetReference: Scalars['ID']['input']
  customFilters?: InputMaybe<Array<EmissionCustomFilter>>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  observationType?: InputMaybe<Array<DetectionType>>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Array<EmissionEventStatus>>
}

export type EmissionEventsSummary = {
  __typename?: 'EmissionEventsSummary'
  eventSummaryByAsset?: Maybe<Array<Maybe<EventAssetSummary>>>
  observationCountsByType?: Maybe<Scalars['ObservationCountsByType']['output']>
  ongoingEventsCount?: Maybe<Scalars['Int']['output']>
  topAssetsByEventsCount?: Maybe<Array<Maybe<AssetAndEventsCount>>>
  totalEventsCount?: Maybe<Scalars['Int']['output']>
  totalObservationsCount?: Maybe<Scalars['Int']['output']>
}

export type EmissionEventsUncorrelationInput = {
  emissionEventId: Scalars['ID']['input']
  observationIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type EmissionEventsUncorrelationResult = {
  __typename?: 'EmissionEventsUncorrelationResult'
  correlationId: Scalars['ID']['output']
  emissionEventIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>
  emissionEvents?: Maybe<Array<Maybe<EmissionEvent>>>
  errors?: Maybe<Array<EmissionEventMutationError>>
}

export type EmissionInput = {
  emission: Scalars['Float']['input']
  lowerBound: Scalars['Float']['input']
  upperBound: Scalars['Float']['input']
}

/** Implemented by Detection or VentingEvent. Emission observations are used for detections or venting */
export type EmissionObservation = {
  acknowledged: Scalars['Boolean']['output']
  audit: EmissionObservationAudit
  camAssetReference?: Maybe<CamAssetReference>
  camSourceAssetReference?: Maybe<CamAssetReference>
  closed: Scalars['Boolean']['output']
  complianceDeadline?: Maybe<Scalars['DateTime']['output']>
  complianceSeverity?: Maybe<ComplianceSeverityType>
  correlatedEmissionObservationCount: Scalars['Int']['output']
  correlatedEmissionObservations?: Maybe<Array<Maybe<EmissionObservation>>>
  detectedAt: Scalars['DateTime']['output']
  emissionEventId?: Maybe<Scalars['ID']['output']>
  emissionsRate?: Maybe<EmissionObservationEmissionRateValue>
  endTime?: Maybe<Scalars['DateTime']['output']>
  equipmentExplanation?: Maybe<Scalars['String']['output']>
  equipmentParentId?: Maybe<Scalars['ID']['output']>
  /** @deprecated Use camSourceAssetReference instead */
  equipmentReference?: Maybe<EquipmentReference>
  group?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inputType: EmissionObservationInputType
  issues?: Maybe<Array<IssueReference>>
  lastComplianceActionAt?: Maybe<Scalars['DateTime']['output']>
  measurementType?: Maybe<MeasurementType>
  /** @deprecated Removing Priority Feature */
  priority?: Maybe<EmissionObservationPriority>
  procedure?: Maybe<Procedure>
  shortId?: Maybe<Scalars['ID']['output']>
  siteMonthReference?: Maybe<SiteMonthReference>
  /** @deprecated Use camAssetReference instead */
  siteReference?: Maybe<SiteReference>
  sourceAttribution?: Maybe<SourceAttribution>
  sourceLatitude?: Maybe<Scalars['Float']['output']>
  sourceLongitude?: Maybe<Scalars['Float']['output']>
  startTime?: Maybe<Scalars['DateTime']['output']>
  uncertainty?: Maybe<Scalars['Float']['output']>
  wip: Scalars['Boolean']['output']
}

/**
 * @TODO: Have EmissionObservationAudit type extend base Audit type and include only
 * acknowledged related properties - acknowledgedBy and acknowledgedTime
 */
export type EmissionObservationAudit = {
  __typename?: 'EmissionObservationAudit'
  acknowledgedBy?: Maybe<UserReference>
  acknowledgedTime?: Maybe<Scalars['DateTime']['output']>
  createdBy?: Maybe<UserReference>
  createdTime: Scalars['DateTime']['output']
  updatedBy?: Maybe<UserReference>
  updatedTime: Scalars['DateTime']['output']
}

export type EmissionObservationCountFilter = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>
  assetFilters?: InputMaybe<EmissionAssetFilter>
  assetReference?: InputMaybe<Array<Scalars['ID']['input']>>
  customFilters?: InputMaybe<Array<EmissionCustomFilter>>
  detectedAfter?: InputMaybe<Scalars['DateTime']['input']>
  detectedBefore?: InputMaybe<Scalars['DateTime']['input']>
  detectionType?: InputMaybe<Array<DetectionType>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  inputType?: InputMaybe<Array<EmissionObservationInputType>>
  orFilter?: InputMaybe<EmissionObservationCountFilter>
  screeningId?: InputMaybe<Array<Scalars['String']['input']>>
  shortId?: InputMaybe<Array<Scalars['ID']['input']>>
  siteId?: InputMaybe<Array<Scalars['ID']['input']>>
  siteName?: InputMaybe<Array<Scalars['String']['input']>>
  sourceAssetReference?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type EmissionObservationCounts = {
  __typename?: 'EmissionObservationCounts'
  detectionCounts: Array<Maybe<DetectionCount>>
  ventingEventCount: Scalars['Int']['output']
}

export type EmissionObservationEdge = {
  __typename?: 'EmissionObservationEdge'
  node?: Maybe<EmissionObservation>
}

export type EmissionObservationEmissionRateValue = {
  __typename?: 'EmissionObservationEmissionRateValue'
  unit: EmissionRateUnitType
  value?: Maybe<Scalars['Float']['output']>
}

export enum EmissionObservationInputType {
  DetectionInputTypeIngress = 'DETECTION_INPUT_TYPE_INGRESS',
  DetectionInputTypeManual = 'DETECTION_INPUT_TYPE_MANUAL',
}

export type EmissionObservationMonthReport = {
  __typename?: 'EmissionObservationMonthReport'
  emissionObservationCounts: EmissionObservationCounts
  inboxClosedCount: Scalars['Int']['output']
  inboxOpenCount: Scalars['Int']['output']
  /** Month site reports in descending order by total events */
  topSiteCounts?: Maybe<Array<Maybe<EmissionObservationMonthSiteReport>>>
  /** All emission observations */
  totalEvents: Scalars['Int']['output']
}

export type EmissionObservationMonthReportTopSiteCountsArgs = {
  max?: Scalars['Int']['input']
}

export type EmissionObservationMonthSiteReport = {
  __typename?: 'EmissionObservationMonthSiteReport'
  camAssetReference?: Maybe<CamAssetReference>
  emissionObservationCounts: EmissionObservationCounts
  /** @deprecated No longer supported */
  month: Scalars['String']['output']
  /** @deprecated Use camAssetReference instead */
  siteReference?: Maybe<SiteReference>
}

export type EmissionObservationMonthSiteReportConnection = {
  __typename?: 'EmissionObservationMonthSiteReportConnection'
  edges?: Maybe<Array<Maybe<EmissionObservationMonthSiteReportEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type EmissionObservationMonthSiteReportEdge = {
  __typename?: 'EmissionObservationMonthSiteReportEdge'
  cursor: Scalars['ID']['output']
  node: EmissionObservationMonthSiteReport
}

export type EmissionObservationMutationError = MutationError & {
  __typename?: 'EmissionObservationMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type EmissionObservationMutationResult = {
  __typename?: 'EmissionObservationMutationResult'
  correlationId: Scalars['ID']['output']
  emissionObservation?: Maybe<EmissionObservation>
  errors?: Maybe<Array<EmissionObservationMutationError>>
}

export enum EmissionObservationPriority {
  /** Integer value 100 */
  DetectionPriorityHigh = 'DETECTION_PRIORITY_HIGH',
  /** Integer value 1 */
  DetectionPriorityLow = 'DETECTION_PRIORITY_LOW',
  /** Integer value 50 */
  DetectionPriorityMedium = 'DETECTION_PRIORITY_MEDIUM',
}

export type EmissionObservationReference = {
  __typename?: 'EmissionObservationReference'
  emissionObservation?: Maybe<EmissionObservation>
  emissionObservationId: Scalars['ID']['output']
  explanation?: Maybe<Scalars['String']['output']>
  procedure?: Maybe<EstimationProcedure>
}

export type EmissionObservationReports = {
  __typename?: 'EmissionObservationReports'
  /** Inbox emission observations grouped by assetReference in the given date range */
  byAssetReference?: Maybe<EmissionObservationMonthSiteReportConnection>
  /**
   * Inbox emission observations grouped by Site in the given date range
   * @deprecated No longer supported
   */
  bySite?: Maybe<EmissionObservationMonthSiteReportConnection>
  /** All Inbox emission observations in the given date range */
  summary?: Maybe<EmissionObservationMonthReport>
  /** Total counts of emission observations as per the given filter */
  totalCounts?: Maybe<EmissionObservationTotalCounts>
}

export type EmissionObservationReportsByAssetReferenceArgs = {
  filter?: InputMaybe<InboxDetectionFilter>
}

export type EmissionObservationReportsBySiteArgs = {
  filter?: InputMaybe<InboxDetectionFilter>
}

export type EmissionObservationReportsSummaryArgs = {
  filter?: InputMaybe<InboxDetectionFilter>
}

export type EmissionObservationReportsTotalCountsArgs = {
  filter?: InputMaybe<EmissionObservationCountFilter>
}

export type EmissionObservationSortBy = {
  ascending: Scalars['Boolean']['input']
  field?: InputMaybe<EmissionObservationSortField>
}

export enum EmissionObservationSortField {
  DetectedAt = 'DETECTED_AT',
  EmissionsRate = 'EMISSIONS_RATE',
  LastUpdated = 'LAST_UPDATED',
  ShortId = 'SHORT_ID',
  SiteName = 'SITE_NAME',
}

export enum EmissionObservationStatusFilter {
  Closed = 'CLOSED',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
}

export type EmissionObservationTimeSourceReference = {
  __typename?: 'EmissionObservationTimeSourceReference'
  emissionObservation?: Maybe<EmissionObservation>
  emissionObservationId: Scalars['ID']['output']
}

export type EmissionObservationTotalCounts = {
  __typename?: 'EmissionObservationTotalCounts'
  /** Total count of emission observations in the closed:true */
  wipClosedCount: Scalars['Int']['output']
  /** Total count of emission observations in the wip:true */
  wipOpenCount: Scalars['Int']['output']
}

export type EmissionObservations = {
  __typename?: 'EmissionObservations'
  byAssetReference?: Maybe<EmissionObservationsConnection>
  byCorrelatedToEmissionObservationId?: Maybe<EmissionObservationsConnection>
  byId?: Maybe<EmissionObservation>
  byIds?: Maybe<Array<Maybe<EmissionObservation>>>
  inbox?: Maybe<EmissionObservationsConnection>
  /** In the same assetReference within days before or after the passed observation detectedAt */
  relevantToTarget?: Maybe<EmissionObservationsConnection>
  workInProgress?: Maybe<EmissionObservationsConnection>
}

export type EmissionObservationsByAssetReferenceArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter: ObservationsByAssetReferenceFilter
  first?: InputMaybe<Scalars['Int']['input']>
}

export type EmissionObservationsByCorrelatedToEmissionObservationIdArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  correlatedToEmissionObservationId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  partitionMonth?: InputMaybe<Scalars['String']['input']>
}

export type EmissionObservationsByIdArgs = {
  id: Scalars['ID']['input']
}

export type EmissionObservationsByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type EmissionObservationsInboxArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<InboxDetectionFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<EmissionObservationSortBy>
}

export type EmissionObservationsRelevantToTargetArgs = {
  filter?: InputMaybe<RelevantToTargetFilter>
}

export type EmissionObservationsWorkInProgressArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<WipDetectionFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  sortBy?: InputMaybe<EmissionObservationSortBy>
}

export type EmissionObservationsAggregatedContextualization = {
  __typename?: 'EmissionObservationsAggregatedContextualization'
  /** The de-duplicated set of Clues for the selected Emission Observations */
  clues?: Maybe<Array<Clue>>
  /** The available [start, end] dates of the selected emission observations */
  detectedAtRange?: Maybe<Array<Scalars['DateTime']['output']>>
  emissionObservations?: Maybe<Array<EmissionObservation>>
  /** The [min, max] emission rates of the selected emission observations */
  emissionsRateRange?: Maybe<Array<EmissionObservationEmissionRateValue>>
}

export type EmissionObservationsConnection = {
  __typename?: 'EmissionObservationsConnection'
  edges?: Maybe<Array<Maybe<EmissionObservationEdge>>>
  pageInfo: PageInfo
}

export type EmissionQuantity = {
  __typename?: 'EmissionQuantity'
  derivedFrom: DerivedFromType
  type: DetectionType
  unit: EmissionUnitQuantityType
  value?: Maybe<Scalars['Float']['output']>
}

export enum EmissionRateUnitType {
  KilogramsPerHour = 'KILOGRAMS_PER_HOUR',
  StandardCubicFeetPerHour = 'STANDARD_CUBIC_FEET_PER_HOUR',
}

export type EmissionSimpleConfig = {
  __typename?: 'EmissionSimpleConfig'
  id: Scalars['ID']['output']
  value?: Maybe<Scalars['JSONObject']['output']>
}

export enum EmissionSimulationMethodology {
  Average = 'AVERAGE',
  EmissionBelowMdl = 'EMISSION_BELOW_MDL',
  EmissionExtrapolation = 'EMISSION_EXTRAPOLATION',
}

export enum EmissionUnit {
  Kilogram = 'KILOGRAM',
  Tonne = 'TONNE',
}

export enum EmissionUnitQuantityType {
  Kilograms = 'KILOGRAMS',
  StandardCubicFeet = 'STANDARD_CUBIC_FEET',
}

export type EmissionsRate = {
  __typename?: 'EmissionsRate'
  emissionsRate?: Maybe<Scalars['Float']['output']>
  lowerBoundRate?: Maybe<Scalars['Float']['output']>
  selectionRule?: Maybe<Scalars['String']['output']>
  upperBoundRate?: Maybe<Scalars['Float']['output']>
}

export type EmissionsVolume = {
  __typename?: 'EmissionsVolume'
  unit: EmissionUnitQuantityType
  value?: Maybe<Scalars['Float']['output']>
}

export type EnableEventHubSubscriptionInput = {
  subscriptionId?: InputMaybe<Scalars['ID']['input']>
}

/** Describes the Owner of an Entity. Can be a Group or a User. */
export type EntityOwner = {
  __typename?: 'EntityOwner'
  group: Scalars['String']['output']
  user?: Maybe<User>
  username: Scalars['String']['output']
}

export type EntityOwnerInput = {
  group?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type Equipment = {
  __typename?: 'Equipment'
  components?: Maybe<Array<EquipmentComponent>>
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  siteReference: SiteReference
}

export type EquipmentComponent = {
  __typename?: 'EquipmentComponent'
  equipmentComponentName?: Maybe<Scalars['String']['output']>
}

export type EquipmentConnection = {
  __typename?: 'EquipmentConnection'
  edges?: Maybe<Array<Maybe<EquipmentEdge>>>
  pageInfo: PageInfo
}

export type EquipmentEdge = {
  __typename?: 'EquipmentEdge'
  node?: Maybe<Equipment>
}

export type EquipmentReference = {
  __typename?: 'EquipmentReference'
  equipment?: Maybe<Equipment>
  equipmentId: Scalars['ID']['output']
}

export type EquipmentSearch = {
  __typename?: 'EquipmentSearch'
  bySite?: Maybe<EquipmentConnection>
}

export type EquipmentSearchBySiteArgs = {
  siteId: Scalars['ID']['input']
}

export enum ErrorLogType {
  ErrorLogTypeRequiredFieldsMissing = 'ERROR_LOG_TYPE_REQUIRED_FIELDS_MISSING',
}

export enum EstimationProcedure {
  TimeEstimator = 'TIME_ESTIMATOR',
}

export type EventAssetSummary = {
  __typename?: 'EventAssetSummary'
  assetReference?: Maybe<Scalars['String']['output']>
  camAssetReference?: Maybe<CamAssetReference>
  eventsCount?: Maybe<Scalars['Int']['output']>
  observationCountsByType?: Maybe<Scalars['ObservationCountsByType']['output']>
  observationsCount?: Maybe<Scalars['Int']['output']>
}

/**
 * A Notification is a Published Event that passes the filter for a given Subscription. It is created for a single Target
 * from the Subscription.
 */
export type EventHubNotification = {
  __typename?: 'EventHubNotification'
  acknowledgement?: Maybe<EventHubNotificationAcknowledgement>
  causationId?: Maybe<Scalars['String']['output']>
  correlationId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  message?: Maybe<Scalars['String']['output']>
  publishedEvent: EventHubPublishedEvent
  publishedEventId: Scalars['ID']['output']
  raisedAt: Scalars['DateTime']['output']
  subscriptionId: Scalars['ID']['output']
  target: EventHubNotificationTarget
}

export type EventHubNotificationAcknowledgement = {
  __typename?: 'EventHubNotificationAcknowledgement'
  time?: Maybe<Scalars['DateTime']['output']>
}

/** See GraphQL Cursor Connections Specification - https://relay.dev/graphql/connections.htm */
export type EventHubNotificationEdge = {
  __typename?: 'EventHubNotificationEdge'
  node?: Maybe<EventHubNotification>
}

/** The target for a Notification */
export type EventHubNotificationTarget = {
  __typename?: 'EventHubNotificationTarget'
  username: Scalars['String']['output']
}

export type EventHubNotificationTargetInput = {
  username: Scalars['String']['input']
}

export type EventHubNotifications = {
  __typename?: 'EventHubNotifications'
  /** Find all notifications for the API caller that were raised after a certain time. This includes Acknowledged Notifications */
  targetedToMe?: Maybe<EventHubNotificationsConnection>
}

export type EventHubNotificationsTargetedToMeArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  raisedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

/** See GraphQL Cursor Connections Specification - https://relay.dev/graphql/connections.htm */
export type EventHubNotificationsConnection = {
  __typename?: 'EventHubNotificationsConnection'
  edges?: Maybe<Array<Maybe<EventHubNotificationEdge>>>
  pageInfo: PageInfo
}

/**
 * A Published Event is a record of an Event that originated in a source Domain or Bounded Context and was published for
 * ingestion by the Eventhub Bounded Context. A Published Event includes a list of Subjects which are all the entities
 * that are associated to or interested about the original Event.
 */
export type EventHubPublishedEvent = {
  __typename?: 'EventHubPublishedEvent'
  /** The identifier to the parent event, message or command whose handling triggered the current message. */
  causationId?: Maybe<Scalars['ID']['output']>
  /** Tracks a set of related events, messages or commands originating from the same original event, message or command */
  correlationId?: Maybe<Scalars['ID']['output']>
  /** The problem domain or bounded context where the Published Event originated. */
  domainName: Scalars['String']['output']
  event: Scalars['String']['output']
  /** The time of the Event described by the EventHubPublishedEvent. */
  happenedAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The time the Published Event was ingested by the Eventhub context. */
  ingestedAt: Scalars['DateTime']['output']
  subject?: Maybe<EventHubSubject>
  subjects: Array<EventHubSubject>
}

/**
 * A Published Event is a record of an Event that originated in a source Domain or Bounded Context and was published for
 * ingestion by the Eventhub Bounded Context. A Published Event includes a list of Subjects which are all the entities
 * that are associated to or interested about the original Event.
 */
export type EventHubPublishedEventSubjectArgs = {
  relationship: Scalars['String']['input']
  type?: InputMaybe<Scalars['String']['input']>
}

/**
 * A Published Event is a record of an Event that originated in a source Domain or Bounded Context and was published for
 * ingestion by the Eventhub Bounded Context. A Published Event includes a list of Subjects which are all the entities
 * that are associated to or interested about the original Event.
 */
export type EventHubPublishedEventSubjectsArgs = {
  excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  includeTypes?: InputMaybe<Array<Scalars['String']['input']>>
  relationship?: InputMaybe<Array<Scalars['String']['input']>>
}

/** See GraphQL Cursor Connections Specification - https://relay.dev/graphql/connections.htm */
export type EventHubPublishedEventEdge = {
  __typename?: 'EventHubPublishedEventEdge'
  node?: Maybe<EventHubPublishedEvent>
}

export type EventHubPublishedEvents = {
  __typename?: 'EventHubPublishedEvents'
  /**
   * Query for all Published Events that have been Ingested by the Eventhub context since a certain time for a given
   * source Domain / Bounded Context.
   */
  byDomain?: Maybe<EventHubPublishedEventsConnection>
  /**
   * Query for all Published Events that have been Ingested by the Eventhub context since a certain time and associated
   * to a given Subject. This query can be used to find all EventHubPublishedEvents since time T where user U is related to the
   * EventHubPublishedEvent by the "IssueAssignedTo" relationship.
   */
  bySubject?: Maybe<EventHubPublishedEventsConnection>
}

export type EventHubPublishedEventsByDomainArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  domainName: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  ingestedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type EventHubPublishedEventsBySubjectArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  ingestedAfter?: InputMaybe<Scalars['DateTime']['input']>
  reference: Scalars['ID']['input']
  relationship: Scalars['String']['input']
  type: Scalars['String']['input']
}

/** See GraphQL Cursor Connections Specification - https://relay.dev/graphql/connections.htm */
export type EventHubPublishedEventsConnection = {
  __typename?: 'EventHubPublishedEventsConnection'
  edges?: Maybe<Array<Maybe<EventHubPublishedEventEdge>>>
  pageInfo: PageInfo
}

/**
 * An Event Hub Subject describes the relationship between the Published Event and an Entity that is associated to, or
 * interested about the original event. The Entity is identified thorugh a combination of the 'type' and 'reference'
 * fields.
 */
export type EventHubSubject = {
  __typename?: 'EventHubSubject'
  asset?: Maybe<Asset>
  issue?: Maybe<Issue>
  reference: Scalars['ID']['output']
  /** A keyword or label that describes the nature of the relationship between the Published Event and the Subject. */
  relationship: Scalars['String']['output']
  type: Scalars['String']['output']
  user?: Maybe<User>
}

/**
 * An Event Hub Subject describes the relationship between the Published Event and an Entity that is associated to, or
 * interested about the original event. The Entity is identified thorugh a combination of the 'type' and 'reference'
 * fields.
 */
export type EventHubSubjectIssueArgs = {
  filter?: InputMaybe<IssueFilter>
}

export type EventHubSubjectInput = {
  reference: Scalars['ID']['input']
  relationship: Scalars['String']['input']
  type: Scalars['String']['input']
}

/**
 * A Subscription is used to define a search criteria for matching PublishedEvents and a list of targets. It is used to
 * determine when a Published Event should be turned into one or more notifications for the targets.
 */
export type EventHubSubscription = {
  __typename?: 'EventHubSubscription'
  description?: Maybe<Scalars['String']['output']>
  enabled: Scalars['Boolean']['output']
  filter: EventHubSubscriptionFilter
  id: Scalars['ID']['output']
  messageTemplate?: Maybe<Scalars['String']['output']>
  owner: EntityOwner
  targets: Array<EventHubNotificationTarget>
}

/** See GraphQL Cursor Connections Specification - https://relay.dev/graphql/connections.htm */
export type EventHubSubscriptionEdge = {
  __typename?: 'EventHubSubscriptionEdge'
  node?: Maybe<EventHubSubscription>
}

export type EventHubSubscriptionFilter = {
  __typename?: 'EventHubSubscriptionFilter'
  /** Match when a Published Event has a Subject that matches the one described */
  anySubjectMatching: EventHubSubject
}

export type EventHubSubscriptionFilterInput = {
  anySubjectMatching: EventHubSubjectInput
}

export type EventHubSubscriptionMutationError = MutationError & {
  __typename?: 'EventHubSubscriptionMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type EventHubSubscriptionMutationResult = {
  __typename?: 'EventHubSubscriptionMutationResult'
  commandId: Scalars['ID']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<EventHubSubscriptionMutationError>>
  subscription?: Maybe<EventHubSubscription>
}

export type EventHubSubscriptions = {
  __typename?: 'EventHubSubscriptions'
  filteredBy?: Maybe<EventHubSubscriptionsConnection>
}

export type EventHubSubscriptionsFilteredByArgs = {
  filter?: InputMaybe<EventHubSubscriptionsFilteredByInput>
}

/** See GraphQL Cursor Connections Specification - https://relay.dev/graphql/connections.htm */
export type EventHubSubscriptionsConnection = {
  __typename?: 'EventHubSubscriptionsConnection'
  edges?: Maybe<Array<Maybe<EventHubSubscriptionEdge>>>
  pageInfo: PageInfo
}

export type EventHubSubscriptionsFilteredByInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type ExportEmissionEventsAndObservationsInput = {
  /** List of asset references to export data for */
  assetReferences: Array<Scalars['ID']['input']>
  destinationPrefix: Scalars['String']['input']
  endDate: Scalars['DateTime']['input']
  group: Scalars['String']['input']
  startDate: Scalars['DateTime']['input']
}

export type ExportEmissionEventsAndObservationsMutationError = MutationError & {
  __typename?: 'ExportEmissionEventsAndObservationsMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ExportEmissionEventsAndObservationsResult = {
  __typename?: 'ExportEmissionEventsAndObservationsResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ExportEmissionEventsAndObservationsMutationError>>
}

export type ExportEmissionObservationsInput = {
  destinationPrefix: Scalars['String']['input']
  /** Maximum length 6 */
  partitionMonths: Array<Scalars['String']['input']>
}

export type ExportEmissionObservationsMutationError = MutationError & {
  __typename?: 'ExportEmissionObservationsMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ExportEmissionObservationsResult = {
  __typename?: 'ExportEmissionObservationsResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ExportEmissionObservationsMutationError>>
}

export type ExportReconciledEmissionsInput = {
  destinationPrefix: Scalars['String']['input']
  /** Maximum length 6. Month format: yyyy-MM. Example: ["2023-09", "2023-10"] */
  partitionMonths: Array<Scalars['String']['input']>
}

export type ExportReconciledEmissionsMutationError = MutationError & {
  __typename?: 'ExportReconciledEmissionsMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ExportReconciledEmissionsResult = {
  __typename?: 'ExportReconciledEmissionsResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ExportReconciledEmissionsMutationError>>
}

export type FalseAlarm = {
  __typename?: 'FalseAlarm'
  explanation?: Maybe<Scalars['String']['output']>
  value: Scalars['Boolean']['output']
}

export type FloatOperand = FloatValueOperand | PropertyOperand

export type FloatValueOperand = {
  __typename?: 'FloatValueOperand'
  value: Scalars['Float']['output']
}

export type FormList = {
  __typename?: 'FormList'
  audit: Audit
  description?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  items: Array<FormListItem>
  name: Scalars['String']['output']
  owner: EntityOwner
}

export type FormListEdge = {
  __typename?: 'FormListEdge'
  node?: Maybe<FormList>
}

export type FormListItem = {
  __typename?: 'FormListItem'
  label?: Maybe<Scalars['String']['output']>
  value: Scalars['String']['output']
}

export type FormListItemInput = {
  /**
   * **label** is the item label.
   *
   * **Note**:
   *
   * 1. **value** can not longer than 4096 if provided.
   */
  label?: InputMaybe<Scalars['String']['input']>
  /**
   * **value** is the item value.
   *
   * **Note**:
   *
   * 1. **value** must be a non-empty string.
   * 1. **value** can not longer than 4096.
   */
  value: Scalars['String']['input']
}

export type FormListMutationError = MutationError & {
  __typename?: 'FormListMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type FormListMutationResult = {
  __typename?: 'FormListMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<FormListMutationError>>
  formList?: Maybe<FormList>
}

export type FormLists = {
  __typename?: 'FormLists'
  /**
   * Query a list of form lists
   *
   * **Note**:
   *
   * 1. Return an empty list if user is not authorized.
   */
  all?: Maybe<FormListsConnection>
  /**
   * Query a single form list by the given **formListId**.
   *
   * **Note**:
   *
   * 1. Return **null** if user is not authorized.
   * 1. Return **null** if the given formListId is not found.
   */
  byId?: Maybe<FormList>
}

export type FormListsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type FormListsByIdArgs = {
  formListId: Scalars['ID']['input']
}

export type FormListsConnection = {
  __typename?: 'FormListsConnection'
  edges?: Maybe<Array<Maybe<FormListEdge>>>
  pageInfo: PageInfo
}

export type FormResponseCamDatasetGenerateResult = {
  __typename?: 'FormResponseCamDatasetGenerateResult'
  datasetId: Scalars['ID']['output']
  entitiesCount: Scalars['Int']['output']
}

export type FormResponseCamDatasetGenerateResults = {
  __typename?: 'FormResponseCamDatasetGenerateResults'
  results?: Maybe<Array<FormResponseCamDatasetGenerateResult>>
}

export type GeoJsonFeature = GeoJsonInterface & {
  __typename?: 'GeoJSONFeature'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  geometry?: Maybe<GeoJsonGeometryInterface>
  id?: Maybe<Scalars['String']['output']>
  properties?: Maybe<Scalars['JSONObject']['output']>
  type: GeoJsonType
}

export type GeoJsonFeatureCollection = GeoJsonInterface & {
  __typename?: 'GeoJSONFeatureCollection'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  features: Array<GeoJsonFeature>
  type: GeoJsonType
}

export type GeoJsonFeatureInput = {
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id?: InputMaybe<Scalars['String']['input']>
  properties?: InputMaybe<Scalars['JSONObject']['input']>
  type: GeoJsonType
}

export type GeoJsonGeometryCollection = GeoJsonInterface & {
  __typename?: 'GeoJSONGeometryCollection'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  geometries: Array<GeoJsonGeometryInterface>
  type: GeoJsonType
}

export type GeoJsonGeometryInput = {
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  coordinates?: InputMaybe<Scalars['GeoJSONCoordinates']['input']>
  type: GeoJsonType
}

export type GeoJsonGeometryInterface = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>
  type: GeoJsonType
}

export type GeoJsonInput = {
  bbox?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>
  features?: InputMaybe<Array<GeoJsonFeatureInput>>
  geometries?: InputMaybe<Array<GeoJsonGeometryInput>>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  type: GeoJsonType
}

export type GeoJsonInterface = {
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  type: GeoJsonType
}

export type GeoJsonLineString = GeoJsonGeometryInterface & {
  __typename?: 'GeoJSONLineString'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>
  type: GeoJsonType
}

export type GeoJsonMultiLineString = GeoJsonGeometryInterface & {
  __typename?: 'GeoJSONMultiLineString'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>
  type: GeoJsonType
}

export type GeoJsonMultiPoint = GeoJsonGeometryInterface & {
  __typename?: 'GeoJSONMultiPoint'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>
  type: GeoJsonType
}

export type GeoJsonMultiPolygon = GeoJsonGeometryInterface & {
  __typename?: 'GeoJSONMultiPolygon'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>
  type: GeoJsonType
}

export type GeoJsonPoint = GeoJsonGeometryInterface & {
  __typename?: 'GeoJSONPoint'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>
  type: GeoJsonType
}

export type GeoJsonPolygon = GeoJsonGeometryInterface & {
  __typename?: 'GeoJSONPolygon'
  bbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  coordinates?: Maybe<Scalars['GeoJSONCoordinates']['output']>
  type: GeoJsonType
}

export enum GeoJsonType {
  Feature = 'Feature',
  FeatureCollection = 'FeatureCollection',
  GeometryCollection = 'GeometryCollection',
  LineString = 'LineString',
  MultiLineString = 'MultiLineString',
  MultiPoint = 'MultiPoint',
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon',
}

export type GeofenceOperand = GeofenceValueOperand | PropertyOperand

export type GeofenceOperandInput = {
  property?: InputMaybe<Scalars['String']['input']>
  unionType: OperandUnionType
  value?: InputMaybe<Scalars['Boolean']['input']>
}

export type GeofenceReference = {
  __typename?: 'GeofenceReference'
  entityId: Scalars['ID']['output']
  entityType: Scalars['String']['output']
  site?: Maybe<Site>
}

export type GeofenceReferenceInput = {
  entityId: Scalars['ID']['input']
  entityType: Scalars['String']['input']
}

export type GeofenceValueOperand = {
  __typename?: 'GeofenceValueOperand'
  value: GeoJsonGeometryInterface
}

export type GroupMutationResult = {
  __typename?: 'GroupMutationResult'
  group?: Maybe<UserGroup>
}

export enum HeightUnit {
  Feet = 'feet',
  Metre = 'metre',
}

export type InboxDetectionFilter = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>
  assetFilters?: InputMaybe<EmissionAssetFilter>
  assetReference?: InputMaybe<Array<Scalars['ID']['input']>>
  closed?: InputMaybe<Scalars['Boolean']['input']>
  customFilters?: InputMaybe<Array<EmissionCustomFilter>>
  detectedAfter?: InputMaybe<Scalars['DateTime']['input']>
  detectedBefore?: InputMaybe<Scalars['DateTime']['input']>
  detectionType?: InputMaybe<Array<DetectionType>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  inputType?: InputMaybe<Array<EmissionObservationInputType>>
  orFilter?: InputMaybe<InboxDetectionFilter>
  screeningId?: InputMaybe<Array<Scalars['String']['input']>>
  shortId?: InputMaybe<Array<Scalars['ID']['input']>>
  siteId?: InputMaybe<Array<Scalars['ID']['input']>>
  siteName?: InputMaybe<Array<Scalars['String']['input']>>
  sourceAssetReference?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type InspectionRequest = {
  __typename?: 'InspectionRequest'
  additionalComment?: Maybe<Scalars['String']['output']>
  audit: InspectionRequestAudit
  clues?: Maybe<Array<ClueReference>>
  dataCollectionFormReference?: Maybe<Scalars['String']['output']>
  detectionReference: DetectionReference
  externalReferenceId?: Maybe<Scalars['String']['output']>
  externalSourceName?: Maybe<InspectionRequestExternalSourceName>
  group?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inspectionType: InspectionType
  multitaskProcedureId?: Maybe<Scalars['String']['output']>
  requestedBy?: Maybe<InspectionRequestRequester>
  siteId: Scalars['ID']['output']
  status?: Maybe<InspectionStatus>
}

export type InspectionRequestAudit = {
  __typename?: 'InspectionRequestAudit'
  createdBy?: Maybe<UserReference>
  createdTime: Scalars['DateTime']['output']
  updatedBy?: Maybe<UserReference>
  updatedTime: Scalars['DateTime']['output']
}

export type InspectionRequestConnection = {
  __typename?: 'InspectionRequestConnection'
  edges?: Maybe<Array<Maybe<InspectionRequestEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type InspectionRequestEdge = {
  __typename?: 'InspectionRequestEdge'
  node: InspectionRequest
}

export enum InspectionRequestExternalSourceName {
  TrueContextProntoforms = 'TRUE_CONTEXT_PRONTOFORMS',
}

export type InspectionRequestMutationError = MutationError & {
  __typename?: 'InspectionRequestMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type InspectionRequestMutationResult = {
  __typename?: 'InspectionRequestMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<InspectionRequestMutationError>>
  inspectionRequest?: Maybe<InspectionRequest>
}

export type InspectionRequestReference = {
  __typename?: 'InspectionRequestReference'
  inspectionRequest?: Maybe<InspectionRequest>
  inspectionRequestId: Scalars['ID']['output']
}

export type InspectionRequestRequester = {
  __typename?: 'InspectionRequestRequester'
  email: Scalars['String']['output']
  familyName: Scalars['String']['output']
  givenName: Scalars['String']['output']
}

export type InspectionRequests = {
  __typename?: 'InspectionRequests'
  byId?: Maybe<InspectionRequest>
  /** Find Inspection Requests still in progress for a Site. Results are ordered by the creation time (descending) */
  incompleteBySite?: Maybe<InspectionRequestConnection>
  new?: Maybe<InspectionRequestConnection>
}

export type InspectionRequestsByIdArgs = {
  id: Scalars['ID']['input']
}

export type InspectionRequestsIncompleteBySiteArgs = {
  createdAfter: Scalars['DateTime']['input']
  inspectionType?: InputMaybe<Array<InspectionType>>
  siteId: Scalars['ID']['input']
}

export type InspectionRequestsNewArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  inspectionType?: InputMaybe<Array<InspectionType>>
}

export enum InspectionStatus {
  InspectionStatusCompleted = 'INSPECTION_STATUS_COMPLETED',
  InspectionStatusLeakRepaired = 'INSPECTION_STATUS_LEAK_REPAIRED',
  InspectionStatusLeakRepairScheduled = 'INSPECTION_STATUS_LEAK_REPAIR_SCHEDULED',
  InspectionStatusRequested = 'INSPECTION_STATUS_REQUESTED',
  InspectionStatusScheduled = 'INSPECTION_STATUS_SCHEDULED',
  InspectionStatusVisualInspectionCompleted = 'INSPECTION_STATUS_VISUAL_INSPECTION_COMPLETED',
}

export enum InspectionType {
  InspectionDrone = 'INSPECTION_DRONE',
  InspectionOgi = 'INSPECTION_OGI',
}

export type Issue = Node & {
  __typename?: 'Issue'
  annotationSummary?: Maybe<AnnotationSummary>
  annotations?: Maybe<Array<Maybe<Annotation>>>
  assignee?: Maybe<IssueAssignee>
  /** @deprecated Use closedAt */
  closed?: Maybe<Scalars['DateTime']['output']>
  closedAt?: Maybe<Scalars['DateTime']['output']>
  /** The current state of the issue */
  currentState: IssueState
  currentStateData?: Maybe<IssueStateData>
  currentStateParameter?: Maybe<IssueStateParameter>
  deleted: Scalars['Boolean']['output']
  description?: Maybe<Scalars['String']['output']>
  dueAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  lastModifiedAt: Scalars['DateTime']['output']
  /** @deprecated Use subject.assetReference.asset */
  observation?: Maybe<GeoJsonFeature>
  /** @deprecated Use openedAt */
  opened: Scalars['DateTime']['output']
  openedAt: Scalars['DateTime']['output']
  owner?: Maybe<EntityOwner>
  pastDue?: Maybe<Scalars['Boolean']['output']>
  resolution?: Maybe<IssueResolution>
  severity: Scalars['Int']['output']
  statesData?: Maybe<Array<Maybe<IssueStateData>>>
  /** Parameter and Data for each state of the issue */
  statesParameter?: Maybe<Array<Maybe<IssueStateParameter>>>
  status: IssueStatus
  statusAt: Scalars['DateTime']['output']
  subject: IssueSubject
  taskType?: Maybe<IssueTaskType>
  title: Scalars['String']['output']
  trigger: IssueTrigger
  type: IssueType
  watchers?: Maybe<Array<IssueWatcher>>
}

export type IssueAssignee = {
  acknowledged?: Maybe<Scalars['Boolean']['output']>
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<IssueAssigneeType>
}

export enum IssueAssigneeType {
  User = 'user',
}

export type IssueDataCollectionForm = {
  __typename?: 'IssueDataCollectionForm'
  allowedIssueTypes?: Maybe<Array<IssueType>>
  dataCollectionForm: Scalars['XFormForm']['output']
  /** @deprecated Use IssueTaskDataCollectionForm instead */
  id?: Maybe<Scalars['XFormReference']['output']>
  isSubtaskForm?: Maybe<Scalars['Boolean']['output']>
  isTaskForm?: Maybe<Scalars['Boolean']['output']>
  modifiedAt: Scalars['DateTime']['output']
  subjectRules?: Maybe<Array<IssueSubjectRule>>
  title?: Maybe<Scalars['String']['output']>
}

/**
 * Extra Issue Filter to support a TCE use case to search tasks, multitasks, subtasks for form responses.
 * Will help determine a list of issue ids
 */
export type IssueDataCollectionFormFilter = {
  formReference: Scalars['XFormReference']['input']
  formResponses?: InputMaybe<
    Array<InputMaybe<IssueDataCollectionFormFilterResponse>>
  >
  taskStatus?: InputMaybe<Array<IssueStatus>>
  /** Defaults to [task,sub_task] */
  taskType?: InputMaybe<Array<IssueType>>
}

export type IssueDataCollectionFormFilterResponse = {
  response?: InputMaybe<Scalars['String']['input']>
  xFormRef?: InputMaybe<Scalars['XFormRef']['input']>
}

export type IssueEdge = {
  __typename?: 'IssueEdge'
  node?: Maybe<Issue>
}

export type IssueFilter = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>
  assignedToUsername?: InputMaybe<Scalars['String']['input']>
  conditions?: InputMaybe<Array<IssueFilterConditionInput>>
  dueAfter?: InputMaybe<Scalars['DateTime']['input']>
  dueBefore?: InputMaybe<Scalars['DateTime']['input']>
  issueIds?: InputMaybe<Array<Scalars['ID']['input']>>
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
  modifiedBefore?: InputMaybe<Scalars['DateTime']['input']>
  severity?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<IssueStatus>
  subjectReference?: InputMaybe<Scalars['ID']['input']>
  triggerReference?: InputMaybe<Scalars['ID']['input']>
  type?: InputMaybe<IssueType>
}

export type IssueFilterCondition = {
  __typename?: 'IssueFilterCondition'
  assignedToUsername?: Maybe<Array<Scalars['String']['output']>>
  field: IssueFilterConditionField
  modifiedAfter?: Maybe<Scalars['DateTime']['output']>
  modifiedBefore?: Maybe<Scalars['DateTime']['output']>
  severity?: Maybe<Array<Scalars['Int']['output']>>
  status?: Maybe<Array<IssueStatus>>
  /** Filtering for SUBTASK is not supported as it is not a root type. */
  type?: Maybe<Array<IssueType>>
}

export enum IssueFilterConditionField {
  AssignedToUsername = 'ASSIGNED_TO_USERNAME',
  Deleted = 'DELETED',
  ModifiedAfter = 'MODIFIED_AFTER',
  Severity = 'SEVERITY',
  Status = 'STATUS',
  Type = 'TYPE',
}

/** This represents a pill in the filter (each must only contain one field) */
export type IssueFilterConditionInput = {
  assignedToUsername?: InputMaybe<Array<Scalars['String']['input']>>
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  field: IssueFilterConditionField
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
  modifiedBefore?: InputMaybe<Scalars['DateTime']['input']>
  severity?: InputMaybe<Array<Scalars['Int']['input']>>
  status?: InputMaybe<Array<IssueStatus>>
  type?: InputMaybe<Array<IssueType>>
}

export type IssueFilterPreset = {
  __typename?: 'IssueFilterPreset'
  conditions?: Maybe<Array<IssueFilterCondition>>
  name: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type IssueMultitask = {
  __typename?: 'IssueMultitask'
  procedures: Array<IssueMultitaskProcedure>
}

/** Rules for MULTITASK type issues SubTasks status data (forms for subtasks) */
export type IssueMultitaskProcedure = {
  __typename?: 'IssueMultitaskProcedure'
  id?: Maybe<Scalars['ID']['output']>
  /** A list of forms which can be added after the multitask is created */
  subTasksDynamicFormReferences?: Maybe<
    Array<Scalars['XFormReference']['output']>
  >
  subTasksDynamicForms?: Maybe<Array<Maybe<IssueTaskDataCollectionForm>>>
  /** A list of forms to be used to create sub tasks automatically for the multitask with the same subject */
  subTasksStaticFormReferences?: Maybe<
    Array<Scalars['XFormReference']['output']>
  >
  subTasksStaticForms?: Maybe<Array<Maybe<IssueTaskDataCollectionForm>>>
  subjectRules?: Maybe<Array<IssueSubjectRule>>
  title?: Maybe<Scalars['String']['output']>
}

/** Rules for MULTITASK type issues SubTasks status data (forms for subtasks) */
export type IssueMultitaskProcedureSubTasksDynamicFormsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

/** Rules for MULTITASK type issues SubTasks status data (forms for subtasks) */
export type IssueMultitaskProcedureSubTasksStaticFormsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueMultitaskProcedureReference = {
  __typename?: 'IssueMultitaskProcedureReference'
  id: Scalars['ID']['output']
  issueMultitaskProcedure?: Maybe<IssueMultitaskProcedure>
}

export type IssueMutationError = MutationError & {
  __typename?: 'IssueMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type IssueMutationResult = {
  __typename?: 'IssueMutationResult'
  commandId: Scalars['ID']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<IssueMutationError>>
  issue?: Maybe<Issue>
}

export type IssueReference = {
  __typename?: 'IssueReference'
  issue?: Maybe<Issue>
  issueId: Scalars['ID']['output']
}

export type IssueResolution = {
  __typename?: 'IssueResolution'
  /** @deprecated Use resolutionContext instead. Will be removed in v51 */
  context?: Maybe<ResolutionContext>
  resolutionContext?: Maybe<IssueResolutionContext>
  time: Scalars['DateTime']['output']
}

export type IssueResolutionContext = {
  __typename?: 'IssueResolutionContext'
  data?: Maybe<Scalars['JSONObject']['output']>
  reference: Scalars['ID']['output']
  /** @deprecated use workflow */
  rule?: Maybe<Workflow>
  type: Scalars['String']['output']
  user?: Maybe<User>
  workflow?: Maybe<Workflow>
}

export type IssueSeverityDisplay = {
  __typename?: 'IssueSeverityDisplay'
  label: Scalars['String']['output']
  severity: Scalars['Int']['output']
  webColour: Scalars['String']['output']
}

export type IssueState = {
  enteredAt: Scalars['DateTime']['output']
  status: IssueStatus
}

export type IssueStateClosed = IssueState & {
  __typename?: 'IssueStateClosed'
  enteredAt: Scalars['DateTime']['output']
  status: IssueStatus
}

export type IssueStateClosedData = IssueStateData & {
  __typename?: 'IssueStateClosedData'
  status: IssueStatus
}

export type IssueStateData = {
  status: IssueStatus
}

export type IssueStateDataCollection = IssueState & {
  __typename?: 'IssueStateDataCollection'
  /** @deprecated This was never a good idea */
  dataCollectionForm: Scalars['XFormForm']['output']
  dataCollectionFormComplete?: Maybe<Scalars['Boolean']['output']>
  dataCollectionFormDetails?: Maybe<IssueTaskDataCollectionForm>
  dataCollectionFormMedia?: Maybe<Array<DataCollectionFormMedium>>
  dataCollectionFormMediaLastModifiedAt?: Maybe<Scalars['DateTime']['output']>
  /** Use mediaKeys. In this context mediaFilePaths and mediaKeys are the same, we kept mediaFilePaths for backward compatibility */
  dataCollectionFormMediaUploadUrls: Array<Scalars['String']['output']>
  dataCollectionFormMediaUrls?: Maybe<Array<Scalars['String']['output']>>
  dataCollectionFormReference: Scalars['XFormReference']['output']
  dataCollectionFormResult?: Maybe<Scalars['XFormResult']['output']>
  dataCollectionFormStarted?: Maybe<Scalars['Boolean']['output']>
  dataCollectionResponses?: Maybe<Scalars['JSONObject']['output']>
  dataCollectionResponsesViews?: Maybe<Array<DataCollectionFormMedium>>
  enteredAt: Scalars['DateTime']['output']
  status: IssueStatus
}

export type IssueStateDataCollectionDataCollectionFormDetailsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataCollectionDataCollectionFormMediaArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataCollectionDataCollectionFormMediaUploadUrlsArgs = {
  mediaFilePaths?: InputMaybe<Array<Scalars['String']['input']>>
  mediaKeys?: InputMaybe<Array<Scalars['String']['input']>>
}

export type IssueStateDataCollectionDataCollectionFormMediaUrlsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataCollectionDataCollectionFormResultArgs = {
  allowNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type IssueStateDataCollectionDataCollectionResponsesViewsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataCollectionData = IssueStateData & {
  __typename?: 'IssueStateDataCollectionData'
  dataCollectionFormComplete?: Maybe<Scalars['Boolean']['output']>
  dataCollectionFormMedia?: Maybe<Array<DataCollectionFormMedium>>
  dataCollectionFormMediaLastModifiedAt?: Maybe<Scalars['DateTime']['output']>
  dataCollectionFormMediaUploadUrls: Array<Scalars['String']['output']>
  dataCollectionFormMediaUrls?: Maybe<Array<Scalars['String']['output']>>
  /**
   * The result/answers to the questions of the form
   *
   * allowNull defaults to false to stay backwards compatible
   */
  dataCollectionFormResult?: Maybe<Scalars['XFormResult']['output']>
  dataCollectionFormStarted?: Maybe<Scalars['Boolean']['output']>
  dataCollectionResponses?: Maybe<Scalars['JSONObject']['output']>
  dataCollectionResponsesViews?: Maybe<Array<DataCollectionFormMedium>>
  /** Always data_collection */
  status: IssueStatus
}

export type IssueStateDataCollectionDataDataCollectionFormMediaArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataCollectionDataDataCollectionFormMediaUploadUrlsArgs =
  {
    mediaFilePaths: Array<Scalars['String']['input']>
  }

export type IssueStateDataCollectionDataDataCollectionFormMediaUrlsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataCollectionDataDataCollectionFormResultArgs = {
  allowNull?: InputMaybe<Scalars['Boolean']['input']>
}

export type IssueStateDataCollectionDataDataCollectionResponsesViewsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataCollectionParameter = IssueStateParameter & {
  __typename?: 'IssueStateDataCollectionParameter'
  /** @deprecated This was never a good idea */
  dataCollectionForm: Scalars['XFormForm']['output']
  dataCollectionFormDetails?: Maybe<IssueTaskDataCollectionForm>
  /** The definition of the form to be completed */
  dataCollectionFormReference: Scalars['XFormReference']['output']
  /** Always data_collection */
  status: IssueStatus
}

export type IssueStateDataCollectionParameterDataCollectionFormDetailsArgs = {
  modifiedAfter?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateDataInput = {
  dataCollectionFormComplete?: InputMaybe<Scalars['Boolean']['input']>
  dataCollectionFormResult?: InputMaybe<Scalars['XFormResult']['input']>
  status: IssueStatus
  subTasksComplete?: InputMaybe<Scalars['Boolean']['input']>
  subTasksIds?: InputMaybe<Array<Scalars['ID']['input']>>
  subTasksLastModifiedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type IssueStateOpen = IssueState & {
  __typename?: 'IssueStateOpen'
  enteredAt: Scalars['DateTime']['output']
  status: IssueStatus
}

export type IssueStateOpenData = IssueStateData & {
  __typename?: 'IssueStateOpenData'
  status: IssueStatus
}

export type IssueStateParameter = {
  status: IssueStatus
}

export type IssueStateParameterInput = {
  dataCollectionFormReference?: InputMaybe<Scalars['XFormReference']['input']>
  multitaskProcedureId?: InputMaybe<Scalars['ID']['input']>
  status: IssueStatus
}

export type IssueStateSubTasks = IssueState & {
  __typename?: 'IssueStateSubTasks'
  enteredAt: Scalars['DateTime']['output']
  multitaskProcedure?: Maybe<IssueMultitaskProcedure>
  multitaskProcedureId?: Maybe<Scalars['ID']['output']>
  status: IssueStatus
  subTasksComplete?: Maybe<Scalars['Boolean']['output']>
  subTasksIds?: Maybe<Array<Scalars['ID']['output']>>
  subTasksLastModifiedAt?: Maybe<Scalars['DateTime']['output']>
  subTasksReferences?: Maybe<Array<IssueReference>>
}

export type IssueStateSubTasksData = IssueStateData & {
  __typename?: 'IssueStateSubTasksData'
  /** Always sub_tasks */
  status: IssueStatus
  subTasksComplete?: Maybe<Scalars['Boolean']['output']>
  subTasksIds?: Maybe<Array<Scalars['ID']['output']>>
  subTasksLastModifiedAt?: Maybe<Scalars['DateTime']['output']>
  subTasksReferences?: Maybe<Array<IssueReference>>
}

export type IssueStateSubTasksParameter = IssueStateParameter & {
  __typename?: 'IssueStateSubTasksParameter'
  multitaskProcedure?: Maybe<IssueMultitaskProcedure>
  multitaskProcedureId: Scalars['ID']['output']
  /** Always sub_tasks */
  status: IssueStatus
}

export enum IssueStatus {
  /** @deprecated Demonstration only */
  Blocked = 'blocked',
  Closed = 'closed',
  DataCollection = 'data_collection',
  Open = 'open',
  SubTasks = 'sub_tasks',
}

/** Reference to a subject to be resolved by the component providing the domain entity */
export type IssueSubject = {
  __typename?: 'IssueSubject'
  /** Asset subject of the issue */
  assetReference?: Maybe<AssetReference>
  reference: Scalars['ID']['output']
  type: Scalars['String']['output']
}

export type IssueSubjectInput = {
  reference: Scalars['ID']['input']
  type: IssueSubjectType
}

/** Reference to a subject filter to be resolved by the component providing the domain entity */
export type IssueSubjectRule = {
  __typename?: 'IssueSubjectRule'
  assetFilter?: Maybe<AssetSelectionFilter>
  reference: Scalars['ID']['output']
  type: Scalars['String']['output']
}

export enum IssueSubjectType {
  Asset = 'asset',
  /** @deprecated Use observation instead */
  DatasetAsset = 'dataset_asset',
  Observation = 'observation',
}

export type IssueTask = {
  __typename?: 'IssueTask'
  dataCollectionForm?: Maybe<IssueTaskDataCollectionForm>
  dataCollectionFormGraph?: Maybe<IssueTaskDataCollectionFormGraph>
  dataCollectionForms: Array<IssueTaskDataCollectionForm>
}

export type IssueTaskDataCollectionFormArgs = {
  id: Scalars['XFormReference']['input']
}

export type IssueTaskDataCollectionFormsArgs = {
  filter?: InputMaybe<DataCollectionFormFilter>
  formTypes?: Array<IssueTaskDataCollectionFormType>
}

export type IssueTaskDataCollectionForm = {
  __typename?: 'IssueTaskDataCollectionForm'
  allowedIssueTypes?: Maybe<Array<IssueType>>
  camDataset?: Maybe<CamEngineDataset>
  cloneFormResponsesOfFormId?: Maybe<Scalars['ID']['output']>
  dataCollectionForm?: Maybe<Scalars['XFormForm']['output']>
  description?: Maybe<Scalars['String']['output']>
  filterQuestions?: Maybe<Array<IssueTaskDataCollectionFormFilterQuestion>>
  formType: IssueTaskDataCollectionFormType
  id?: Maybe<Scalars['XFormReference']['output']>
  /** Represents if the DataCollectionForm is in the provisioning process, but the provisioning is not finished yet. If for for is active and already completely provisioned, the value of this property will be false. */
  isActivating: Scalars['Boolean']['output']
  /** Represents if the DataCollectionForm is provisioned and ready to be consumed by the clients. */
  isActive: Scalars['Boolean']['output']
  isSubtaskForm?: Maybe<Scalars['Boolean']['output']>
  isTaskForm?: Maybe<Scalars['Boolean']['output']>
  jsonFormBody?: Maybe<JsonFormBody>
  modifiedAt: Scalars['DateTime']['output']
  owner?: Maybe<EntityOwner>
  responseDatasetProperties?: Maybe<Array<UpSpecPropertyDefinition>>
  subjectAssetProfile?: Maybe<Scalars['ID']['output']>
  subjectRules?: Maybe<Array<IssueSubjectRule>>
  synchronizeFormDefinitionWithFormId?: Maybe<Scalars['ID']['output']>
  taskTitleTemplate?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  xForm?: Maybe<XFormDetails>
}

export type IssueTaskDataCollectionFormFilterQuestion = {
  __typename?: 'IssueTaskDataCollectionFormFilterQuestion'
  displayName: Scalars['String']['output']
  items?: Maybe<Array<XFormItem>>
  xFormRef: Scalars['XFormRef']['output']
}

export type IssueTaskDataCollectionFormGraph = {
  __typename?: 'IssueTaskDataCollectionFormGraph'
  downloadUrl: Scalars['String']['output']
}

export type IssueTaskDataCollectionFormMutationError = MutationError & {
  __typename?: 'IssueTaskDataCollectionFormMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type IssueTaskDataCollectionFormMutationResult = {
  __typename?: 'IssueTaskDataCollectionFormMutationResult'
  correlationId: Scalars['ID']['output']
  dataCollectionForm?: Maybe<IssueTaskDataCollectionForm>
  errors?: Maybe<Array<IssueTaskDataCollectionFormMutationError>>
}

export type IssueTaskDataCollectionFormReference = {
  __typename?: 'IssueTaskDataCollectionFormReference'
  id: Scalars['ID']['output']
  issueTaskDataCollectionForm?: Maybe<IssueTaskDataCollectionForm>
}

export enum IssueTaskDataCollectionFormType {
  JsonForm = 'JSON_FORM',
  OdkForm = 'ODK_FORM',
}

export enum IssueTaskType {
  CompleteJsonForm = 'COMPLETE_JSON_FORM',
  CompleteOdkForm = 'COMPLETE_ODK_FORM',
}

export type IssueTrigger = {
  __typename?: 'IssueTrigger'
  /** @deprecated Use triggerContext instead. Will be removed in v51 */
  context?: Maybe<TriggerContext>
  location?: Maybe<GeoJsonPoint>
  time: Scalars['DateTime']['output']
  triggerContext?: Maybe<IssueTriggerContext>
}

export type IssueTriggerContext = {
  __typename?: 'IssueTriggerContext'
  data?: Maybe<Scalars['JSONObject']['output']>
  issueReference?: Maybe<IssueReference>
  reference: Scalars['ID']['output']
  /** @deprecated use workflow */
  rule?: Maybe<Workflow>
  type: Scalars['String']['output']
  user?: Maybe<User>
  workflow?: Maybe<Workflow>
}

export enum IssueTriggerContextType {
  UserAction = 'userAction',
  WorkflowExecuteDag = 'workflowExecuteDag',
}

/**
 * The type of the issue determines it's state machine for its state transitions.
 *
 * Each transition and state comes with a label/title
 */
export enum IssueType {
  /**
   * States: opened, closed
   * Transitions: open, close, reopen
   */
  Issue = 'ISSUE',
  /**
   * States: opened, subtasks, closed
   * Transitions: trigger, start, skip, reopen, restart, complete, stop
   */
  Multitask = 'MULTITASK',
  /**
   * States: opened, data-collection, closed
   * Transitions: trigger, start, skip, reopen, restart, complete, stop
   */
  Subtask = 'SUBTASK',
  /**
   * States: opened, data-collection, closed
   * Transitions: trigger, start, skip, reopen, restart, complete, stop
   */
  Task = 'TASK',
}

export type IssueUserAssignee = IssueAssignee & {
  __typename?: 'IssueUserAssignee'
  acknowledged?: Maybe<Scalars['Boolean']['output']>
  acknowledgedAt?: Maybe<Scalars['DateTime']['output']>
  type?: Maybe<IssueAssigneeType>
  userReference?: Maybe<UserReference>
}

export type IssueWatcher = {
  __typename?: 'IssueWatcher'
  email: Scalars['String']['output']
}

export type Issues = {
  __typename?: 'Issues'
  byId?: Maybe<Issue>
  filteredBy?: Maybe<IssuesConnection>
}

export type IssuesByIdArgs = {
  issueId: Scalars['ID']['input']
  suDataCollectionApplicationId?: InputMaybe<Scalars['ID']['input']>
}

export type IssuesFilteredByArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<IssueFilter>
  first?: InputMaybe<Scalars['Int']['input']>
  formFilter?: InputMaybe<IssueDataCollectionFormFilter>
}

export type IssuesConnection = {
  __typename?: 'IssuesConnection'
  edges?: Maybe<Array<Maybe<IssueEdge>>>
  pageInfo: PageInfo
}

export type JsonFormBody = {
  __typename?: 'JSONFormBody'
  /** An array of list sources that is used to resolve values for list type properties in the data collection form */
  propertyValueListSources?: Maybe<Array<Maybe<PropertyValueListSource>>>
  schema?: Maybe<Scalars['JSONObject']['output']>
  uischema?: Maybe<Scalars['JSONObject']['output']>
}

export type JsonFormBodyInput = {
  /** An array of list sources that is used to resolve values for list type properties in the data collection form */
  propertyValueListSources?: InputMaybe<
    Array<InputMaybe<PropertyValueListSourceInput>>
  >
  schema?: InputMaybe<Scalars['JSONObject']['input']>
  uischema?: InputMaybe<Scalars['JSONObject']['input']>
}

export enum KnownEventType {
  KnownEventTypeBlowdown = 'KNOWN_EVENT_TYPE_BLOWDOWN',
  KnownEventTypeFlaring = 'KNOWN_EVENT_TYPE_FLARING',
  KnownEventTypeFlaringAndBlowdown = 'KNOWN_EVENT_TYPE_FLARING_AND_BLOWDOWN',
  KnownEventTypeVenting = 'KNOWN_EVENT_TYPE_VENTING',
  KnownEventTypeVentingAndBlowdown = 'KNOWN_EVENT_TYPE_VENTING_AND_BLOWDOWN',
}

export type LogReconciliationDecisionInput = {
  day: Scalars['Date']['input']
  /** New value for measured emission output */
  emissionKilograms: Scalars['Float']['input']
  /** Explanation of the method used to determine new value for measured emission output */
  explanation: Scalars['String']['input']
  /** Methodology used to determine new value for measured emission output */
  methodology: DecisionMethodology
  siteId: Scalars['ID']['input']
  virtualMeterId: Scalars['ID']['input']
}

export type LogReconciliationDecisionResult = {
  __typename?: 'LogReconciliationDecisionResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<LogReconciliationDecisionResultError>>
  reconciledEmission?: Maybe<ReconciledEmission>
}

export type LogReconciliationDecisionResultError = MutationError & {
  __typename?: 'LogReconciliationDecisionResultError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type LogReconciliationErrorInput = {
  area: DecisionArea
  errorTime: Scalars['DateTime']['input']
  errorType: ErrorLogType
  inventoryData?: InputMaybe<Scalars['JSONObject']['input']>
  siteId: Scalars['ID']['input']
}

export type LogReconciliationErrorResult = {
  __typename?: 'LogReconciliationErrorResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<LogReconciliationErrorResultError>>
}

export type LogReconciliationErrorResultError = MutationError & {
  __typename?: 'LogReconciliationErrorResultError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type ManageCamAssetsMutation = {
  __typename?: 'ManageCamAssetsMutation'
  dumpAssetTypesState?: Maybe<Array<AssetTypeMutationResult>>
  help?: Maybe<Scalars['JSONObject']['output']>
  precomputeAssetsUnion?: Maybe<Array<AssetTypeMutationResult>>
  provisionAssetType?: Maybe<Array<AssetTypeMutationResult>>
  reloadCamDatasetsCatalog?: Maybe<CamDatasets>
  setupAssetTypesExtensions?: Maybe<Array<AssetTypeMutationResult>>
  setupCamStaticDatasetsCatalog?: Maybe<CamDatasets>
  setupSystemAssetTypes?: Maybe<Array<AssetTypeMutationResult>>
  triggerAssetTypesStateMethods?: Maybe<Array<AssetTypeMutationResult>>
  updateCamDatasetCatalogBackendComponents?: Maybe<
    Array<Scalars['ID']['output']>
  >
}

export type ManageCamAssetsMutationDumpAssetTypesStateArgs = {
  input: DumpAssetTypesStateInput
}

export type ManageCamAssetsMutationProvisionAssetTypeArgs = {
  input: ProvisionAssetTypeInput
}

export type ManageCamAssetsMutationSetupAssetTypesExtensionsArgs = {
  input: SetupAssetTypesExtensionsInput
}

export type ManageCamAssetsMutationSetupSystemAssetTypesArgs = {
  input: SetupSystemAssetTypesInput
}

export type ManageCamAssetsMutationTriggerAssetTypesStateMethodsArgs = {
  input: TriggerAssetTypesStateInput
}

export type ManageCamAssetsMutationUpdateCamDatasetCatalogBackendComponentsArgs =
  {
    input?: InputMaybe<UpdateBackendComponentsInput>
  }

export type ManageEmissionsMutation = {
  __typename?: 'ManageEmissionsMutation'
  configureAssetPropertyFilters?: Maybe<Scalars['JSONObject']['output']>
  configureEmissionEventForDetailPanelProperties?: Maybe<EmissionSimpleConfig>
  help?: Maybe<Scalars['JSONObject']['output']>
  setConfig?: Maybe<Scalars['JSONObject']['output']>
}

export type ManageEmissionsMutationConfigureAssetPropertyFiltersArgs = {
  assetGroup: Scalars['ID']['input']
  filters: Array<EmissionAssetFilterInput>
}

export type ManageEmissionsMutationConfigureEmissionEventForDetailPanelPropertiesArgs =
  {
    value: Array<Scalars['ID']['input']>
  }

export type ManageEmissionsMutationSetConfigArgs = {
  group: Scalars['ID']['input']
  id: Scalars['ID']['input']
  value: Scalars['JSONObject']['input']
}

export type ManualCamDatasetMetadata = {
  group?: InputMaybe<Scalars['ID']['input']>
  metadataJson?: InputMaybe<Scalars['JSONObject']['input']>
  name: Scalars['String']['input']
  version: Scalars['String']['input']
}

export type Map = {
  __typename?: 'Map'
  audit: Audit
  id: Scalars['ID']['output']
  isPrivate: Scalars['Boolean']['output']
  layers: Array<Scalars['JSONObject']['output']>
  name: Scalars['String']['output']
  owner: EntityOwner
  selectedDateTimeRange?: Maybe<DateTimeRange>
  spatialFilterEnabled?: Maybe<Scalars['Boolean']['output']>
  style: Scalars['String']['output']
  /** S3 URI for the thumbnail */
  thumbnailPath?: Maybe<Scalars['String']['output']>
  /** During create or update, the client pass the content of thumbnail, and for read the server return the S3 signed url for that content */
  thumbnailUrl?: Maybe<Scalars['String']['output']>
  timelineIntervalUnit?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  viewCount: Scalars['Int']['output']
  viewState?: Maybe<Scalars['JSONObject']['output']>
}

export type MapEdge = {
  __typename?: 'MapEdge'
  node?: Maybe<Map>
}

export type MapFilter = {
  group?: InputMaybe<Array<Scalars['String']['input']>>
  isPrivate?: InputMaybe<Array<Scalars['Boolean']['input']>>
  username?: InputMaybe<Array<Scalars['String']['input']>>
}

export type MapMutationError = MutationError & {
  __typename?: 'MapMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type MapMutationResult = {
  __typename?: 'MapMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<MapMutationError>>
  map?: Maybe<Map>
}

export type Maps = {
  __typename?: 'Maps'
  all?: Maybe<MapsConnection>
  byId?: Maybe<Map>
}

export type MapsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<MapFilter>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type MapsByIdArgs = {
  id: Scalars['ID']['input']
}

export type MapsConfig = {
  __typename?: 'MapsConfig'
  wmtsTileTemplateUrl?: Maybe<Scalars['String']['output']>
}

export type MapsConfigWmtsTileTemplateUrlArgs = {
  tsId: Scalars['String']['input']
}

export type MapsConnection = {
  __typename?: 'MapsConnection'
  edges?: Maybe<Array<Maybe<MapEdge>>>
  pageInfo: PageInfo
}

export type MarkDetectionAsVfbReportRequestedInput = {
  detectionId: Scalars['ID']['input']
  explanation?: InputMaybe<Scalars['String']['input']>
  recipients?: InputMaybe<Array<Scalars['String']['input']>>
}

export enum MeasurementDesignation {
  MeasurementDesignationBestIfCalculated = 'MEASUREMENT_DESIGNATION_BEST_IF_CALCULATED',
  MeasurementDesignationBestIfMeasured = 'MEASUREMENT_DESIGNATION_BEST_IF_MEASURED',
}

export enum MeasurementType {
  Ch4 = 'CH4',
  Co = 'CO',
  Co2 = 'CO2',
  H2S = 'H2S',
  No2 = 'NO2',
  Pm2 = 'PM2',
  So2 = 'SO2',
  Voc = 'VOC',
}

export type MediaFileInput = {
  /** A base64 encoded string representing a media file */
  body: Scalars['String']['input']
  /** The extension name of the media file, for example, jpg or tiff */
  extension: Scalars['String']['input']
  /** The source of the media file, for example, Kairos or Carbonmapper */
  source?: MediaSourceType
}

export enum MediaSourceType {
  Carbonmapper = 'CARBONMAPPER',
  Kairos = 'KAIROS',
}

export type ModifyIssueCommentInput = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  index: Scalars['Int']['input']
  issueId: Scalars['ID']['input']
  text: Scalars['String']['input']
}

export enum MonthlyScheduleType {
  RepeatMonthlyOnDay = 'REPEAT_MONTHLY_ON_DAY',
  RepeatMonthlyOnLastDay = 'REPEAT_MONTHLY_ON_LAST_DAY',
  RepeatMonthlyOnWeek = 'REPEAT_MONTHLY_ON_WEEK',
}

export type Mutation = {
  __typename?: 'Mutation'
  acknowledgeAssignment: IssueMutationResult
  acknowledgeEmissionObservation: EmissionObservationMutationResult
  acknowledgeEventHubNotification: AcknowledgeEventHubNotificationResult
  activateAssetType: AssetTypeMutationResult
  activateIssueTaskDataCollectionForm: IssueTaskDataCollectionFormMutationResult
  /** Activate a workflow - set isActive = true */
  activateWorkflow: WorkflowMutationResult
  addManualCamDatasetIntoCatalog: CamEngineMetadata
  /** Used by the approver to approve the decisions review request (for a site, for a month), and to mark the site as closed. Can be performed in bulk if multiple siteIds are provided. */
  approveDecisionsReviewRequest: ApproveDecisionsReviewRequestResult
  /** Assignment */
  assignIssueToUser: IssueMutationResult
  attributeEmissionEvent: EmissionEventMutationResult
  attributeEmissionObservationToEquipment: AttributeEmissionObservationToEquipmentResult
  attributeEmissionObservationToSite: AttributeEmissionObservationToSiteResult
  attributeEmissionObservationsToEquipment: AttributeEmissionObservationsToEquipmentResult
  /** Bulk Update Issues */
  bulkUpdateIssues: BulkIssuesMutationResult
  cloneIssueTaskDataCollectionForm: IssueTaskDataCollectionFormMutationResult
  cloneMap: MapMutationResult
  cloneSite?: Maybe<SiteMutationResponse>
  cloneWorkflow: WorkflowMutationResult
  /**
   * Builtin state transition ( -> closed )
   * @deprecated Use transitionIssueState instead
   */
  closeIssue: IssueMutationResult
  /** Comment */
  commentIssue: IssueMutationResult
  completeNewPassword: AuthMutationResult
  /** Used by the reviewer to confirm a manual reconciliation decision for an emission */
  confirmReconciliationDecision: ConfirmReconciliationDecisionResult
  confirmSignIn: AuthConfirmSignInResult
  correlateEmissionEvents: EmissionEventsCorrelationResult
  createAPIKey?: Maybe<AuthMutationResult>
  createAlarm: EmissionAlarmMutationResult
  createAsset: AssetMutationResult
  createAssetComponent: AssetComponentMutationResult
  createAssetEquipment: AssetEquipmentMutationResult
  createAssetPipeline: AssetPipelineMutationResult
  createAssetSite: AssetSiteMutationResult
  createAssetType: AssetTypeMutationResult
  createAssetWell: AssetWellMutationResult
  createDetection: DetectionMutationResult
  createEmissionEvent: EmissionEventMutationResult
  /** Create an emission observation with media data */
  createEmissionObservation: EmissionObservationMutationResult
  createEventHubSubscription: EventHubSubscriptionMutationResult
  /**
   * Create a new form list basing on the input
   *
   * **Note**:
   *
   * 1. Return the created form list if successful.
   */
  createFormList?: Maybe<FormListMutationResult>
  /** This changes the Detection status to *_REQUESTED and side effect is to create an inspection request */
  createInspectionRequest: InspectionRequestMutationResult
  createIssueTaskDataCollectionForm: IssueTaskDataCollectionFormMutationResult
  createIssueTaskResponsesView: CreateIssueTaskResponsesViewMutationResult
  createMap: MapMutationResult
  /** This mutation should only be used for purpose of Ingressing NO DETECTIONs only */
  createNoDetection: NoDetectionMutationResult
  createSite?: Maybe<SiteMutationResponse>
  createSiteBeacon?: Maybe<SiteBeaconMutationResponse>
  createSiteBuilding?: Maybe<SiteBuildingMutationResponse>
  createSiteLevel?: Maybe<SiteLevelMutationResponse>
  createSiteLocation?: Maybe<SiteLocationMutationResponse>
  createSitePathway?: Maybe<SitePathwayMutationResponse>
  createSiteUnit?: Maybe<SiteUnitMutationResponse>
  createUser: UserMutationResult
  /** Create a Venting Event */
  createVentingEvent: VentingEventMutationResult
  createWorkflow: WorkflowMutationResult
  deactivateIssueTaskDataCollectionForm: IssueTaskDataCollectionFormMutationResult
  /** Deactivate a workflow - set isActive = false */
  deactivateWorkflow: WorkflowMutationResult
  deleteAssetConfig?: Maybe<AssetConfigMutationResult>
  deleteAssetType: AssetTypeMutationResult
  deleteEmissionEvents: EmissionEventMutationResult
  /** Performs a soft-deletion of an emission observation */
  deleteEmissionObservation: EmissionObservationMutationResult
  /** Delete issue (mark deleted true) */
  deleteIssue: IssueMutationResult
  deleteIssueComment: IssueMutationResult
  deleteIssueTaskDataCollectionForm: IssueTaskDataCollectionFormMutationResult
  deleteMap: MapMutationResult
  /** This mutation should only be used for purpose of deleting NO DETECTIONs through import only */
  deleteNoDetection: NoDetectionMutationResult
  deleteSite?: Maybe<SiteMutationResponse>
  deleteSiteBeacon?: Maybe<SiteBeaconMutationResponse>
  deleteSiteBuilding?: Maybe<SiteBuildingMutationResponse>
  deleteSiteLevel?: Maybe<SiteLevelMutationResponse>
  deleteSiteLocation?: Maybe<SiteLocationMutationResponse>
  deleteSitePathway?: Maybe<SitePathwayMutationResponse>
  deleteSiteUnit?: Maybe<SiteUnitMutationResponse>
  deleteUser: UserMutationResult
  deleteWorkflow: WorkflowMutationResult
  disableEventHubSubscription: EventHubSubscriptionMutationResult
  enableEventHubSubscription: EventHubSubscriptionMutationResult
  /** Export emission events and their observations for the given asset references */
  exportEmissionEventsAndObservations: ExportEmissionEventsAndObservationsResult
  /** This mutation should only be used for purpose of reconciliation & contextualization */
  exportEmissionObservations: ExportEmissionObservationsResult
  /**
   * Used by the Data team to get an export of reconciled emissions. For internal use only.
   * @deprecated No longer supported
   */
  exportReconciledEmissions: ExportReconciledEmissionsResult
  federatedSignIn?: Maybe<AuthMutationResult>
  flushCamStreamDatasetDelta?: Maybe<Scalars['ID']['output']>
  forgetDevices?: Maybe<AuthMutationResult>
  forgotPassword?: Maybe<AuthMutationResult>
  forgotPasswordSubmit?: Maybe<AuthMutationResult>
  generateFormResponseDataset: FormResponseCamDatasetGenerateResults
  generateFormResponseDatasets: FormResponseCamDatasetGenerateResults
  generateNoDetectionsDatasets: NoDetectionsDatasetsResult
  generateSMSCode?: Maybe<AuthMutationResult>
  globalSignOut?: Maybe<AuthMutationResult>
  /** Used by the reviewer to add a manual reconciliation decision for an emission */
  logReconciliationDecision: LogReconciliationDecisionResult
  /**
   * Used by the reconciliation job to insert reconciliation errors directly into the data model. For internal use only.
   * @deprecated No longer supported
   */
  logReconciliationError: LogReconciliationErrorResult
  loginMe: UserMutationResult
  manageCamAssets?: Maybe<ManageCamAssetsMutation>
  manageEmissions?: Maybe<ManageEmissionsMutation>
  /** This mutation accepts non OGI detection ID, and move it to work in progress with status waiting for VFB event */
  markDetectionAsVfbReportRequested: DetectionMutationResult
  modifyIssueComment: IssueMutationResult
  /** OXY-1927: This is designed to modify the status of a detection ONLY. (New → Pending) */
  pendDetection: DetectionMutationResult
  /** The client must provide a mutationId to acknowledge that it is prepared to handle the provisioned eventhub messages */
  provisionCatalogDataset?: Maybe<UpSpecificationMutationResult>
  putAssetProfileExtension?: Maybe<AssetProfileMutationResult>
  /** Used by an admin or super admin to reconcile emission events for a month and list of asset references */
  reconcileEmissionEvents: ReconcileEmissionEventsResult
  recordMapViewed: MapMutationResult
  reloadCamAssetsDatasets: Array<CamEngineDataset>
  reloadFormDatasets: Array<CamEngineDataset>
  reloadMethaneLdarBackendDatasets: Array<CamEngineDataset>
  removeAssetProfileExtension?: Maybe<AssetProfileMutationResult>
  /**
   * Builtin state transition ( -> open )
   * @deprecated Use transitionIssueState instead
   */
  reopenIssue: IssueMutationResult
  /** Used to reopen a site for a given month, which will reopen its associated decisions review request. Can be performed in bulk if multiple siteIds are provided. */
  reopenReconciliationSiteMonth: ReopenReconciliationSiteMonthResult
  /** Used by the reviewer to request for decisions to be approved (for a site, for a month). Can be performed in bulk if multiple siteIds are provided. */
  requestDecisionsReviewForApproval: RequestDecisionsReviewForApprovalResult
  /** Mark a detection as correlated to another or as a false alarm */
  resolveDetection: DetectionMutationResult
  setAssetConfig?: Maybe<AssetConfigMutationResult>
  setMethaneLdarConfig?: Maybe<EmissionConfigMutationResult>
  setSessionUserGroup?: Maybe<AuthMutationResult>
  setupTOTP: AuthTotpMutationResult
  signIn: AuthMutationResult
  signOut?: Maybe<AuthMutationResult>
  /** Transfer ownership of a workflow */
  transferWorkflow: WorkflowMutationResult
  /**  Note: this command can be sent delayed (after coming back online) from the mobile device */
  transitionIssueState: IssueMutationResult
  /**  Note: this command can be sent delayed (after coming back online) from the mobile device */
  triggerIssue: IssueMutationResult
  triggerIssueMultitask: IssueMutationResult
  triggerIssueSubTask: IssueMutationResult
  triggerIssueTask: IssueMutationResult
  triggerReconciliation: TriggerReconciliationResult
  triggerSitesBulkExport?: Maybe<SiteBulkExportResponse>
  unCorrelateEmissionEvents: EmissionEventsUncorrelationResult
  /** Undo delete issue (mark deleted false) */
  undeleteIssue: IssueMutationResult
  updateAlarm: EmissionAlarmMutationResult
  updateAsset: AssetMutationResult
  updateAssetComponent: AssetComponentMutationResult
  updateAssetEquipment: AssetEquipmentMutationResult
  updateAssetGeometry: AssetMutationResult
  updateAssetPipeline: AssetPipelineMutationResult
  updateAssetProfile?: Maybe<AssetProfileMutationResult>
  /** Replace a ViewConfiguration (based on profile, id) */
  updateAssetProfileViewConfiguration: AssetProfileViewConfigurationMutationResult
  /** Replace a ViewLayout (based on profile, id, mediaType) */
  updateAssetProfileViewLayout: AssetProfileViewLayoutMutationResult
  updateAssetProperty: AssetMutationResult
  updateAssetSite: AssetSiteMutationResult
  updateAssetType: AssetTypeMutationResult
  updateAssetWell: AssetWellMutationResult
  /** This mutation should only be used for purpose of Ingressing detections from data team only */
  updateClue: ClueMutationResult
  /** This mutation should only be used for purpose of Ingressing detections from data team only */
  updateDetection: DetectionMutationResult
  /** @deprecated use updateEmissionEventStatus instead */
  updateEmissionEventFalseAlarmStatus: EmissionEventMutationResult
  updateEmissionEventStatus: EmissionEventMutationResult
  /**
   * Update an existing form list basing on the input
   *
   * **Note**:
   *
   * 1. Return the updated form list if successful.
   */
  updateFormList?: Maybe<FormListMutationResult>
  updateGroup: GroupMutationResult
  /** This mutation should only be used for purpose of Ingressing historical detections from data team only */
  updateHistoricalDetection: DetectionMutationResult
  updateInspectionRequest: InspectionRequestMutationResult
  /** Update inspection request status */
  updateInspectionRequestStatus: InspectionRequestMutationResult
  /** Update basic properties of an issue */
  updateIssue: IssueMutationResult
  /** Note: this command can be sent delayed (after coming back online) from the mobile device */
  updateIssueStateData: IssueMutationResult
  updateIssueTaskDataCollectionForm: IssueTaskDataCollectionFormMutationResult
  updateMap: MapMutationResult
  /** This mutation should only be used for purpose of Ingressing NO DETECTIONs only */
  updateNoDetection: NoDetectionMutationResult
  /**
   * Used by the reconciliation job to insert reconciled emissions directly into the data model. For internal use only.
   * @deprecated No longer supported
   */
  updateReconciledEmission: UpdateReconciledEmissionResult
  /** Used to create or update the Scope 2 emission value for a site and month */
  updateScope2Emission: UpdateScope2EmissionResult
  updateSite?: Maybe<SiteMutationResponse>
  updateSiteBeacon?: Maybe<SiteBeaconMutationResponse>
  updateSiteBuilding?: Maybe<SiteBuildingMutationResponse>
  updateSiteLevel?: Maybe<SiteLevelMutationResponse>
  updateSiteLocation?: Maybe<SiteLocationMutationResponse>
  updateSitePathway?: Maybe<SitePathwayMutationResponse>
  updateSiteUnit?: Maybe<SiteUnitMutationResponse>
  updateUser: UserMutationResult
  /** Add current User consent for an application and one or more subjects (ex. A User can consents to privacy and T&M documents for explorer) */
  updateUserMeConsent: UserMutationResult
  updateUserPreference: UserMutationResult
  updateUserRegisterNotificationToken: UserMutationResult
  updateUserUnregisterNotificationToken: UserMutationResult
  /** This mutation should only be used for purpose of Ingressing Venting from data team only */
  updateVentingEvent: UpdateVentingEventResult
  updateWorkflow: WorkflowMutationResult
  /** Used by the data pipeline to insert/update yearly simulation results directly into the data model. For internal use only. */
  upsertRolledUpReconciledEmissions: UpsertRolledUpReconciledEmissionsResult
  verifySMSCode?: Maybe<AuthMutationResult>
  verifyTOTPToken?: Maybe<AuthMutationResult>
}

export type MutationAcknowledgeAssignmentArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: AcknowledgeAssignment
}

export type MutationAcknowledgeEmissionObservationArgs = {
  input: AcknowledgeEmissionObservationInput
}

export type MutationAcknowledgeEventHubNotificationArgs = {
  input: AcknowledgeEventHubNotificationInput
}

export type MutationActivateAssetTypeArgs = {
  input: ActivateAssetTypeInput
}

export type MutationActivateIssueTaskDataCollectionFormArgs = {
  id: Scalars['ID']['input']
  subjectAssetProfile?: InputMaybe<Scalars['ID']['input']>
}

export type MutationActivateWorkflowArgs = {
  id: Scalars['ID']['input']
}

export type MutationAddManualCamDatasetIntoCatalogArgs = {
  dataset?: InputMaybe<ManualCamDatasetMetadata>
}

export type MutationApproveDecisionsReviewRequestArgs = {
  input: ApproveDecisionsReviewRequestInput
}

export type MutationAssignIssueToUserArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: AssignIssueToUserInput
}

export type MutationAttributeEmissionEventArgs = {
  input: EmissionEventAttributionInput
}

export type MutationAttributeEmissionObservationToEquipmentArgs = {
  input: AttributeEmissionObservationToEquipmentInput
}

export type MutationAttributeEmissionObservationToSiteArgs = {
  input: AttributeEmissionObservationToSiteInput
}

export type MutationAttributeEmissionObservationsToEquipmentArgs = {
  input: AttributeEmissionObservationsToEquipmentInput
}

export type MutationBulkUpdateIssuesArgs = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  input: BulkUpdateIssueInput
  queryFilter: IssueFilter
}

export type MutationCloneIssueTaskDataCollectionFormArgs = {
  input: CloneIssueTaskDataCollectionFormInput
}

export type MutationCloneMapArgs = {
  id: Scalars['ID']['input']
}

export type MutationCloneSiteArgs = {
  id: Scalars['ID']['input']
}

export type MutationCloneWorkflowArgs = {
  id: Scalars['ID']['input']
}

export type MutationCloseIssueArgs = {
  input: CloseIssueInput
}

export type MutationCommentIssueArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: CommentIssueInput
}

export type MutationCompleteNewPasswordArgs = {
  input?: InputMaybe<AuthCompleteNewPasswordInput>
}

export type MutationConfirmReconciliationDecisionArgs = {
  input: ConfirmReconciliationDecisionInput
}

export type MutationConfirmSignInArgs = {
  input?: InputMaybe<AuthConfirmSignInInput>
}

export type MutationCorrelateEmissionEventsArgs = {
  input: EmissionEventsCorrelationInput
}

export type MutationCreateApiKeyArgs = {
  input: AuthCreateApiKeyInput
}

export type MutationCreateAlarmArgs = {
  input: CreateEmissionAlarmInput
}

export type MutationCreateAssetArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: CreateAssetInput
}

export type MutationCreateAssetComponentArgs = {
  input: CreateAssetComponentInput
}

export type MutationCreateAssetEquipmentArgs = {
  input: CreateAssetEquipmentInput
}

export type MutationCreateAssetPipelineArgs = {
  input: CreateAssetPipelineInput
}

export type MutationCreateAssetSiteArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: CreateAssetSiteInput
}

export type MutationCreateAssetTypeArgs = {
  input: CreateAssetTypeInput
}

export type MutationCreateAssetWellArgs = {
  input: CreateAssetWellInput
}

export type MutationCreateDetectionArgs = {
  input: CreateDetectionInput
}

export type MutationCreateEmissionEventArgs = {
  input: CreateEmissionEventInput
}

export type MutationCreateEmissionObservationArgs = {
  input: CreateEmissionObservationInput
}

export type MutationCreateEventHubSubscriptionArgs = {
  input: CreateEventHubSubscriptionInput
}

export type MutationCreateFormListArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: CreateFormListInput
  mutationId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationCreateInspectionRequestArgs = {
  input: CreateInspectionRequestInput
}

export type MutationCreateIssueTaskDataCollectionFormArgs = {
  input: CreateIssueTaskDataCollectionFormInput
}

export type MutationCreateIssueTaskResponsesViewArgs = {
  input: CreateIssueTaskResponsesViewInput
}

export type MutationCreateMapArgs = {
  input: CreateMapInput
}

export type MutationCreateNoDetectionArgs = {
  input: CreateNoDetectionInput
}

export type MutationCreateSiteArgs = {
  input: CreateSiteInput
}

export type MutationCreateSiteBeaconArgs = {
  input: CreateSiteBeaconInput
}

export type MutationCreateSiteBuildingArgs = {
  input: CreateSiteBuildingInput
}

export type MutationCreateSiteLevelArgs = {
  input: CreateSiteLevelInput
}

export type MutationCreateSiteLocationArgs = {
  input: CreateSiteLocationInput
}

export type MutationCreateSitePathwayArgs = {
  input: CreateSitePathwayInput
}

export type MutationCreateSiteUnitArgs = {
  input: CreateSiteUnitInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationCreateVentingEventArgs = {
  input: CreateVentingEventInput
}

export type MutationCreateWorkflowArgs = {
  input: CreateWorkflowInput
}

export type MutationDeactivateIssueTaskDataCollectionFormArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeactivateWorkflowArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteAssetConfigArgs = {
  group: Scalars['ID']['input']
  id: Scalars['ID']['input']
}

export type MutationDeleteAssetTypeArgs = {
  input: DeleteAssetTypeInput
}

export type MutationDeleteEmissionEventsArgs = {
  input: DeleteEmissionEventsInput
}

export type MutationDeleteEmissionObservationArgs = {
  input: DeleteEmissionObservationInput
}

export type MutationDeleteIssueArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: DeleteIssueInput
}

export type MutationDeleteIssueCommentArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: DeleteIssueCommentInput
}

export type MutationDeleteIssueTaskDataCollectionFormArgs = {
  input: DeleteIssueTaskDataCollectionFormInput
}

export type MutationDeleteMapArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteNoDetectionArgs = {
  input: DeleteNoDetectionInput
}

export type MutationDeleteSiteArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSiteBeaconArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSiteBuildingArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSiteLevelArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSiteLocationArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSitePathwayArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSiteUnitArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteUserArgs = {
  transferToUser?: InputMaybe<Scalars['ID']['input']>
  username: Scalars['ID']['input']
}

export type MutationDeleteWorkflowArgs = {
  id: Scalars['ID']['input']
}

export type MutationDisableEventHubSubscriptionArgs = {
  input: DisableEventHubSubscriptionInput
}

export type MutationEnableEventHubSubscriptionArgs = {
  input: EnableEventHubSubscriptionInput
}

export type MutationExportEmissionEventsAndObservationsArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: ExportEmissionEventsAndObservationsInput
}

export type MutationExportEmissionObservationsArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: ExportEmissionObservationsInput
}

export type MutationExportReconciledEmissionsArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: ExportReconciledEmissionsInput
}

export type MutationFederatedSignInArgs = {
  input: AuthFederatedSignInInput
}

export type MutationFlushCamStreamDatasetDeltaArgs = {
  streamDatasetId: Scalars['ID']['input']
}

export type MutationForgetDevicesArgs = {
  input?: InputMaybe<AuthForgetSpecificDevicesInput>
}

export type MutationForgotPasswordArgs = {
  input?: InputMaybe<AuthForgotPasswordInput>
}

export type MutationForgotPasswordSubmitArgs = {
  input?: InputMaybe<AuthForgotPasswordSubmitInput>
}

export type MutationGenerateFormResponseDatasetArgs = {
  async?: Scalars['Boolean']['input']
  formReference: Scalars['String']['input']
}

export type MutationGenerateFormResponseDatasetsArgs = {
  async?: Scalars['Boolean']['input']
}

export type MutationGenerateNoDetectionsDatasetsArgs = {
  input: NoDetectionsDatasetsInput
}

export type MutationLogReconciliationDecisionArgs = {
  input: LogReconciliationDecisionInput
}

export type MutationLogReconciliationErrorArgs = {
  input: LogReconciliationErrorInput
}

export type MutationLoginMeArgs = {
  identityId: Scalars['String']['input']
}

export type MutationManageCamAssetsArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationManageEmissionsArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationMarkDetectionAsVfbReportRequestedArgs = {
  input: MarkDetectionAsVfbReportRequestedInput
}

export type MutationModifyIssueCommentArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: ModifyIssueCommentInput
}

export type MutationPendDetectionArgs = {
  input: PendDetectionInput
}

export type MutationProvisionCatalogDatasetArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: ProvisionCatalogDatasetInput
  mutationId: Scalars['ID']['input']
}

export type MutationPutAssetProfileExtensionArgs = {
  input: PutAssetProfileExtensionInput
}

export type MutationReconcileEmissionEventsArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<ReconcileEmissionEventsInput>
}

export type MutationRecordMapViewedArgs = {
  id: Scalars['ID']['input']
}

export type MutationReloadCamAssetsDatasetsArgs = {
  group: Scalars['ID']['input']
}

export type MutationReloadFormDatasetsArgs = {
  group?: InputMaybe<Scalars['ID']['input']>
}

export type MutationReloadMethaneLdarBackendDatasetsArgs = {
  group: Scalars['ID']['input']
}

export type MutationRemoveAssetProfileExtensionArgs = {
  input: RemoveAssetProfileExtensionInput
}

export type MutationReopenIssueArgs = {
  input: ReopenIssueInput
}

export type MutationReopenReconciliationSiteMonthArgs = {
  input: ReopenReconciliationSiteMonthInput
}

export type MutationRequestDecisionsReviewForApprovalArgs = {
  input: RequestDecisionsReviewForApprovalInput
}

export type MutationResolveDetectionArgs = {
  input: ResolveDetectionInput
}

export type MutationSetAssetConfigArgs = {
  group: Scalars['ID']['input']
  id: Scalars['ID']['input']
  value: Scalars['JSONObject']['input']
}

export type MutationSetMethaneLdarConfigArgs = {
  group: Scalars['ID']['input']
  id: Scalars['ID']['input']
  value: Scalars['JSONObject']['input']
}

export type MutationSetSessionUserGroupArgs = {
  input?: InputMaybe<AuthSetSessionUserGroupInput>
}

export type MutationSignInArgs = {
  input: AuthSignInInput
}

export type MutationTransferWorkflowArgs = {
  group: Scalars['String']['input']
  id: Scalars['ID']['input']
  username: Scalars['String']['input']
}

export type MutationTransitionIssueStateArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: TransitionIssueStateInput
}

export type MutationTriggerIssueArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: TriggerIssueInput
}

export type MutationTriggerIssueMultitaskArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: TriggerIssueMultitaskInput
}

export type MutationTriggerIssueSubTaskArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: TriggerIssueSubTaskInput
}

export type MutationTriggerIssueTaskArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: TriggerIssueTaskInput
}

export type MutationTriggerReconciliationArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<TriggerReconciliationInput>
}

export type MutationTriggerSitesBulkExportArgs = {
  input?: InputMaybe<SiteBulkExportInput>
}

export type MutationUnCorrelateEmissionEventsArgs = {
  input: EmissionEventsUncorrelationInput
}

export type MutationUndeleteIssueArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: UndeleteIssueInput
}

export type MutationUpdateAlarmArgs = {
  input: UpdateEmissionAlarmInput
}

export type MutationUpdateAssetArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: UpdateAssetInput
}

export type MutationUpdateAssetComponentArgs = {
  input: UpdateAssetComponentInput
}

export type MutationUpdateAssetEquipmentArgs = {
  input: UpdateAssetEquipmentInput
}

export type MutationUpdateAssetGeometryArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: UpdateAssetGeometryInput
}

export type MutationUpdateAssetPipelineArgs = {
  input: UpdateAssetPipelineInput
}

export type MutationUpdateAssetProfileArgs = {
  input?: InputMaybe<UpdateAssetProfileInput>
}

export type MutationUpdateAssetProfileViewConfigurationArgs = {
  input: UpdateAssetProfileViewConfigurationInput
}

export type MutationUpdateAssetProfileViewLayoutArgs = {
  input?: InputMaybe<UpdateAssetProfileViewLayoutInput>
}

export type MutationUpdateAssetPropertyArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: UpdateAssetPropertyInput
}

export type MutationUpdateAssetSiteArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: UpdateAssetSiteInput
}

export type MutationUpdateAssetTypeArgs = {
  input: UpdateAssetTypeInput
}

export type MutationUpdateAssetWellArgs = {
  input: UpdateAssetWellInput
}

export type MutationUpdateClueArgs = {
  input: UpdateClueInput
}

export type MutationUpdateDetectionArgs = {
  input: UpdateDetectionInput
}

export type MutationUpdateEmissionEventFalseAlarmStatusArgs = {
  input: UpdateEmissionEventFalseAlarmStatusInput
}

export type MutationUpdateEmissionEventStatusArgs = {
  input: UpdateEmissionEventStatusInput
}

export type MutationUpdateFormListArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input: UpdateFormListInput
  mutationId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput
}

export type MutationUpdateHistoricalDetectionArgs = {
  input: UpdateHistoricalDetectionInput
}

export type MutationUpdateInspectionRequestArgs = {
  input: UpdateInspectionRequestInput
}

export type MutationUpdateInspectionRequestStatusArgs = {
  input: UpdateInspectionRequestStatusInput
}

export type MutationUpdateIssueArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input: UpdateIssueInput
}

export type MutationUpdateIssueStateDataArgs = {
  delayed?: InputMaybe<DelayedIssueMutationInput>
  input?: InputMaybe<UpdateIssueStateDataInput>
}

export type MutationUpdateIssueTaskDataCollectionFormArgs = {
  input: UpdateIssueTaskDataCollectionFormInput
}

export type MutationUpdateMapArgs = {
  input: UpdateMapInput
}

export type MutationUpdateNoDetectionArgs = {
  input: UpdateNoDetectionInput
}

export type MutationUpdateReconciledEmissionArgs = {
  input: UpdateReconciledEmissionInput
}

export type MutationUpdateScope2EmissionArgs = {
  correlationId?: InputMaybe<Scalars['ID']['input']>
  input?: InputMaybe<UpdateScope2EmissionInput>
}

export type MutationUpdateSiteArgs = {
  input: UpdateSiteInput
}

export type MutationUpdateSiteBeaconArgs = {
  input: UpdateSiteBeaconInput
}

export type MutationUpdateSiteBuildingArgs = {
  input: UpdateSiteBuildingInput
}

export type MutationUpdateSiteLevelArgs = {
  input?: InputMaybe<UpdateSiteLevelInput>
}

export type MutationUpdateSiteLocationArgs = {
  input: UpdateSiteLocationInput
}

export type MutationUpdateSitePathwayArgs = {
  input: UpdateSitePathwayInput
}

export type MutationUpdateSiteUnitArgs = {
  input: UpdateSiteUnitInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserMeConsentArgs = {
  input: UpdateUserConsentInput
}

export type MutationUpdateUserPreferenceArgs = {
  input: UpdateUserPreferenceInput
}

export type MutationUpdateUserRegisterNotificationTokenArgs = {
  input: UpdateUserRegisterNotificationTokenInput
}

export type MutationUpdateVentingEventArgs = {
  input: UpdateVentingEventInput
}

export type MutationUpdateWorkflowArgs = {
  input: UpdateWorkflowInput
}

export type MutationUpsertRolledUpReconciledEmissionsArgs = {
  input: UpsertRolledUpReconciledEmissionsInput
}

export type MutationVerifySmsCodeArgs = {
  input?: InputMaybe<AuthVerifySmsCodeInput>
}

export type MutationVerifyTotpTokenArgs = {
  input?: InputMaybe<AuthVerifyTotpTokenInput>
}

export type MutationError = {
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type MutationResponse = {
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<MutationError>>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
}

export type NoDetection = {
  __typename?: 'NoDetection'
  additionalData?: Maybe<Scalars['JSONObject']['output']>
  camAssetReference?: Maybe<CamAssetReference>
  camSourceAssetReference?: Maybe<CamAssetReference>
  companyName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use camSourceAssetReference instead */
  equipmentReference?: Maybe<EquipmentReference>
  group?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  noDetectionTime: Scalars['DateTime']['output']
  noDetectionType: NoDetectionType
  shortId?: Maybe<Scalars['String']['output']>
  /** @deprecated Use camAssetReference instead */
  siteReference?: Maybe<SiteReference>
}

export type NoDetectionMutationError = MutationError & {
  __typename?: 'NoDetectionMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type NoDetectionMutationResult = {
  __typename?: 'NoDetectionMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<NoDetectionMutationError>>
  noDetection?: Maybe<NoDetection>
}

export type NoDetectionReference = {
  __typename?: 'NoDetectionReference'
  noDetection?: Maybe<NoDetection>
  noDetectionId: Scalars['ID']['output']
}

export type NoDetectionTimeSourceReference = {
  __typename?: 'NoDetectionTimeSourceReference'
  noDetection?: Maybe<NoDetection>
  noDetectionId: Scalars['ID']['output']
}

export enum NoDetectionType {
  NoDetectionTypeCms = 'NO_DETECTION_TYPE_CMS',
  NoDetectionTypeDrone = 'NO_DETECTION_TYPE_DRONE',
  NoDetectionTypeFlyoverCoverage = 'NO_DETECTION_TYPE_FLYOVER_COVERAGE',
  NoDetectionTypeOgi = 'NO_DETECTION_TYPE_OGI',
  NoDetectionTypeVentingFlaringBlowdown = 'NO_DETECTION_TYPE_VENTING_FLARING_BLOWDOWN',
}

export type NoDetections = {
  __typename?: 'NoDetections'
  byId?: Maybe<NoDetection>
}

export type NoDetectionsByIdArgs = {
  id: Scalars['ID']['input']
}

export type NoDetectionsDatasetsInput = {
  consolidateHistory?: InputMaybe<Scalars['Boolean']['input']>
  consolidateRealTimeDelta?: InputMaybe<Scalars['Boolean']['input']>
  group?: InputMaybe<Scalars['ID']['input']>
  partitionMonths?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type NoDetectionsDatasetsResult = {
  __typename?: 'NoDetectionsDatasetsResult'
  success?: Maybe<Scalars['Boolean']['output']>
}

export type Node = {
  id: Scalars['ID']['output']
}

export type NumericOperandInput = {
  property?: InputMaybe<Scalars['String']['input']>
  unionType: OperandUnionType
  value?: InputMaybe<Scalars['Float']['input']>
}

export enum ObservationSelectionRule {
  UserDefined = 'USER_DEFINED',
}

export type ObservationsByAssetReferenceFilter = {
  assetReference: Scalars['String']['input']
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<EmissionObservationStatusFilter>
}

export enum OnComparisonType {
  Date = 'Date',
  DateTime = 'DateTime',
}

export enum OperandUnionType {
  Property = 'PROPERTY',
  Value = 'VALUE',
}

/**
 * Proper pagination according to Connections spec. In the meanwhile, API indicates if there are
 *        more results but doesn't support going to the next batch.
 *        https://relay.dev/graphql/connections.htm
 */
export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']['output']>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  startCursor?: Maybe<Scalars['String']['output']>
}

export type ParentInput = {
  assetId: Scalars['ID']['input']
  assetTypeId: Scalars['ID']['input']
}

export type PendDetectionInput = {
  detectionId: Scalars['ID']['input']
}

export type Platform = {
  __typename?: 'Platform'
  configuration?: Maybe<PlatformConfiguration>
  versions?: Maybe<PlatformVersions>
}

export enum PlatformClientApplicationType {
  Explorer = 'EXPLORER',
  Sensorhub = 'SENSORHUB',
}

export type PlatformComponentConfiguration = {
  __typename?: 'PlatformComponentConfiguration'
  branding: Scalars['JSONObject']['output']
  configuration: Scalars['JSONObject']['output']
  features?: Maybe<Array<PlatformFeatureFlag>>
}

export type PlatformConfiguration = {
  __typename?: 'PlatformConfiguration'
  clientConfig: PlatformComponentConfiguration
  environment: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type PlatformConfigurationClientConfigArgs = {
  application: PlatformClientApplicationType
}

export type PlatformFeatureFlag = {
  __typename?: 'PlatformFeatureFlag'
  enabled: Scalars['Boolean']['output']
  version?: Maybe<Scalars['String']['output']>
}

export type PlatformRelease = {
  __typename?: 'PlatformRelease'
  exceptions?: Maybe<Array<PlatformVersion>>
  name: Scalars['String']['output']
  version: Scalars['String']['output']
}

export type PlatformVersion = {
  __typename?: 'PlatformVersion'
  commit?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  version: Scalars['String']['output']
}

export type PlatformVersions = {
  __typename?: 'PlatformVersions'
  components: Array<PlatformVersion>
  release: PlatformRelease
  stage: Scalars['String']['output']
}

export enum Procedure {
  CarbonmapperFlyAvirisL2B = 'CARBONMAPPER_FLY_AVIRIS_L2B',
  CarbonmapperSatEmitL2B = 'CARBONMAPPER_SAT_EMIT_L2B',
  KairosCoverage = 'KAIROS_COVERAGE',
  KairosQuantificationAlgorithm = 'KAIROS_QUANTIFICATION_ALGORITHM',
  Kuva = 'KUVA',
  Longpath = 'LONGPATH',
  OgiInspection = 'OGI_INSPECTION',
  OperationalLogFromInterlex = 'OPERATIONAL_LOG_FROM_INTERLEX',
  OsiPi = 'OSI_PI',
  Qube = 'QUBE',
  TropomiL2B = 'TROPOMI_L2B',
  Unknown = 'UNKNOWN',
}

export type PropertyOperand = {
  __typename?: 'PropertyOperand'
  /** Full JSONPath to grab this data from the GeoJson object */
  property: Scalars['String']['output']
}

/** Enum defining type of various source(s) that is used to resolve values for list type property in the data collection form */
export enum PropertyValueListSourceType {
  /** Static list source provides list of static values */
  StaticList = 'STATIC_LIST',
}

export type ProvisionAssetTypeInput = {
  assetTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ProvisionCatalogDatasetInput = {
  catalog?: InputMaybe<CatalogInput>
  catalogId: Scalars['ID']['input']
  dataAccess?: InputMaybe<CatalogDatasetDataAccessInput>
  dataAccessId: Scalars['ID']['input']
  dataset: Scalars['ID']['input']
  rawUpSpecification?: InputMaybe<Scalars['JSONObject']['input']>
  upSpecification?: InputMaybe<CatalogDatasetSpecificationInput>
}

export type Query = {
  __typename?: 'Query'
  allIssues?: Maybe<IssuesConnection>
  assetComponents?: Maybe<AssetComponents>
  assetConfigs?: Maybe<AssetConfigs>
  assetEquipments?: Maybe<AssetEquipments>
  assetFilterDownloads?: Maybe<Array<AssetDownload>>
  assetFilters?: Maybe<Array<AssetSelectionFilter>>
  assetPipelines?: Maybe<AssetPipelines>
  assetProfiles?: Maybe<AssetProfiles>
  assetSites?: Maybe<AssetSites>
  assetTypes?: Maybe<AssetTypes>
  assetWells?: Maybe<AssetWells>
  assets?: Maybe<Assets>
  camAssetReferences?: Maybe<CamAssetReferences>
  camAssets?: Maybe<CamAssets>
  camAssetsDatasets: Array<Maybe<CamEngineDataset>>
  camCatalog?: Maybe<CamCatalog>
  camEngine?: Maybe<CamEngine>
  catalogDatasets?: Maybe<UpSpecifications>
  catalogs?: Maybe<Catalogs>
  /** List of CO2e reports for a month */
  co2eMonthReports?: Maybe<Co2eMonthReports>
  /** List of CO2e reports for a site and month */
  co2eSiteMonthReports?: Maybe<Co2eSiteMonthReports>
  config?: Maybe<Config>
  credentials?: Maybe<AuthCredentials>
  emissionEvents?: Maybe<EmissionEvents>
  emissionObservationReports?: Maybe<EmissionObservationReports>
  /** Support mixing detections and venting events query */
  emissionObservations?: Maybe<EmissionObservations>
  eventHubNotifications?: Maybe<EventHubNotifications>
  eventHubPublishedEvents?: Maybe<EventHubPublishedEvents>
  eventHubSubscriptions?: Maybe<EventHubSubscriptions>
  formDatasets: Array<CamEngineDataset>
  /** Query either a list of form lists or a single form list by ID */
  formLists?: Maybe<FormLists>
  inspectionRequests?: Maybe<InspectionRequests>
  /** @deprecated Use issueTask { dataCollectionFormReferences } */
  issueDataCollectionFormReferences?: Maybe<Array<IssueDataCollectionForm>>
  issueMultitask?: Maybe<IssueMultitask>
  issueSeverityTypes?: Maybe<Array<IssueSeverityDisplay>>
  issueTask?: Maybe<IssueTask>
  /** @deprecated users.all is now provided */
  issueUserAssignees?: Maybe<UsersConnection>
  issues?: Maybe<Issues>
  issuesDatasets: Array<Maybe<CamEngineDataset>>
  maps?: Maybe<Maps>
  mapsConfig?: Maybe<MapsConfig>
  me?: Maybe<User>
  methaneLdarBackendConfig?: Maybe<EmissionConfig>
  methaneLdarBackendConfigs?: Maybe<EmissionConfigs>
  methaneLdarBackendDatasets: Array<CamEngineDataset>
  noDetections?: Maybe<NoDetections>
  openIssueBySubjectAndReference?: Maybe<Issue>
  /** @deprecated Use catalogs instead */
  pipeCatalogs?: Maybe<Catalogs>
  platform: Platform
  /** List of reconciliation decisions that are manually reconciled and approved */
  reconciledDecisions?: Maybe<ReconciledDecisions>
  /** List of all reconciled emissions */
  reconciledEmissions?: Maybe<ReconciledEmissions>
  /** Reconciled emission report for different granularity, for example, report for all sites for a given month, report for a given site for a given month etc. */
  reconciledReports?: Maybe<ReconciledReports>
  /** List of reconciliation errors */
  reconciliationErrors?: Maybe<ReconciliationErrors>
  reconciliationMonths?: Maybe<ReconciliationMonths>
  /** Reconciliation reports - V2 */
  reconciliationReports?: Maybe<ReconciliationReports>
  reconciliationSiteMonths?: Maybe<ReconciliationSiteMonths>
  /** Scope 2 emissions */
  scope2Emissions?: Maybe<Scope2Emissions>
  session?: Maybe<AuthSession>
  signWorkatoJwtToken?: Maybe<Scalars['String']['output']>
  site?: Maybe<Site>
  siteBeaconUserPropertiesKeys?: Maybe<Array<SiteFeatureUserPropertiesKey>>
  siteBuildingCategories?: Maybe<Array<SiteFeatureCategory>>
  siteBuildingUserPropertiesKeys?: Maybe<Array<SiteFeatureUserPropertiesKey>>
  siteCategories?: Maybe<Array<SiteFeatureCategory>>
  siteLevelCategories?: Maybe<Array<SiteFeatureCategory>>
  siteLevelUserPropertiesKeys?: Maybe<Array<SiteFeatureUserPropertiesKey>>
  siteLocationCategories?: Maybe<Array<SiteFeatureCategory>>
  siteLocationUserPropertiesKeys?: Maybe<Array<SiteFeatureUserPropertiesKey>>
  sitePathwayUserPropertiesKeys?: Maybe<Array<SiteFeatureUserPropertiesKey>>
  siteUnitCategories?: Maybe<Array<SiteFeatureCategory>>
  siteUnitUserPropertiesKeys?: Maybe<Array<SiteFeatureUserPropertiesKey>>
  siteUserPropertiesKeys?: Maybe<Array<SiteFeatureUserPropertiesKey>>
  sites?: Maybe<SiteConnection>
  sitesDeletedObservations?: Maybe<Array<Scalars['JSONObject']['output']>>
  sitesObservations?: Maybe<Array<Scalars['JSONObject']['output']>>
  /** Get all the documents for a specific subject and/or application */
  userConsentDocuments?: Maybe<Array<UserConsentDocument>>
  userGroups?: Maybe<UserGroups>
  users?: Maybe<Users>
  workflows?: Maybe<Workflows>
}

export type QueryAllIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  severity?: InputMaybe<Scalars['Int']['input']>
  status?: InputMaybe<IssueStatus>
  subjectReference?: InputMaybe<Scalars['ID']['input']>
  triggerReference?: InputMaybe<Scalars['ID']['input']>
}

export type QueryAssetConfigsArgs = {
  group: Scalars['ID']['input']
}

export type QueryAssetsArgs = {
  profile: Scalars['ID']['input']
}

export type QueryCamAssetsArgs = {
  assetTypeId: Scalars['ID']['input']
}

export type QueryCamAssetsDatasetsArgs = {
  group: Scalars['ID']['input']
}

export type QueryCamCatalogArgs = {
  group: Scalars['ID']['input']
}

export type QueryCatalogDatasetsArgs = {
  withInternal?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCatalogsArgs = {
  withInternal?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryCredentialsArgs = {
  group?: InputMaybe<Scalars['String']['input']>
}

export type QueryFormDatasetsArgs = {
  group: Scalars['ID']['input']
}

export type QueryIssueUserAssigneesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type QueryIssuesDatasetsArgs = {
  group: Scalars['ID']['input']
}

export type QueryMethaneLdarBackendConfigsArgs = {
  group: Scalars['ID']['input']
}

export type QueryMethaneLdarBackendDatasetsArgs = {
  group: Scalars['ID']['input']
}

export type QueryOpenIssueBySubjectAndReferenceArgs = {
  subjectReference: Scalars['ID']['input']
  triggerReference: Scalars['ID']['input']
}

export type QuerySignWorkatoJwtTokenArgs = {
  customerVendorOrigin?: InputMaybe<Scalars['String']['input']>
}

export type QuerySiteArgs = {
  id: Scalars['ID']['input']
}

export type QuerySitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<SiteFilter>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySitesDeletedObservationsArgs = {
  filter?: InputMaybe<SiteFilter>
  observationsFilter?: InputMaybe<SiteObservationsFilter>
  select?: InputMaybe<SiteObservationSelector>
}

export type QuerySitesObservationsArgs = {
  filter?: InputMaybe<SiteFilter>
  observationsFilter?: InputMaybe<SiteObservationsFilter>
  select?: InputMaybe<SiteObservationSelector>
}

export type QueryUserConsentDocumentsArgs = {
  application?: InputMaybe<Scalars['String']['input']>
  subject?: InputMaybe<Scalars['ID']['input']>
}

export type QueryWorkflowsArgs = {
  withInternal?: InputMaybe<Scalars['Boolean']['input']>
}

export type ReconcileEmissionEventsInput = {
  assetReferences: Array<Scalars['ID']['input']>
  month: Scalars['Month']['input']
}

export type ReconcileEmissionEventsMutationError = MutationError & {
  __typename?: 'ReconcileEmissionEventsMutationError'
  assetReference?: Maybe<Scalars['ID']['output']>
  message: Scalars['String']['output']
  month?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
}

export type ReconcileEmissionEventsResult = {
  __typename?: 'ReconcileEmissionEventsResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ReconcileEmissionEventsMutationError>>
}

export type ReconciledDayReport = {
  __typename?: 'ReconciledDayReport'
  day: Scalars['Date']['output']
  siteReference?: Maybe<SiteReference>
  /** Total emission for all virtual meters in ReconciledEmission */
  totalEmission?: Maybe<ReconciledEmissionTotal>
  /** Reconciled emission report for a given virtual meter for a given site and day */
  virtualMeterEmission?: Maybe<ReconciledEmission>
  virtualMeterEmissions?: Maybe<Array<Maybe<ReconciledEmission>>>
}

export type ReconciledDayReportTotalEmissionArgs = {
  unit?: EmissionUnit
}

export type ReconciledDayReportVirtualMeterEmissionArgs = {
  virtualMeterId: Scalars['ID']['input']
}

export type ReconciledDecision = {
  __typename?: 'ReconciledDecision'
  day: Scalars['Date']['output']
  latestDecisionByApprover?: Maybe<Decision>
  latestDecisionByReviewer?: Maybe<Decision>
  siteReference?: Maybe<SiteReference>
  virtualMeterId: Scalars['ID']['output']
}

export type ReconciledDecisionConnection = {
  __typename?: 'ReconciledDecisionConnection'
  edges?: Maybe<Array<Maybe<ReconciledDecisionEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type ReconciledDecisionEdge = {
  __typename?: 'ReconciledDecisionEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: ReconciledDecision
}

export type ReconciledDecisions = {
  __typename?: 'ReconciledDecisions'
  /** Reconciliation decisions that are manually reconciled and approved for all sites for a given month */
  all?: Maybe<ReconciledDecisionConnection>
  /** Reconciliation decisions that are manually reconciled and approved for all sites for the latest closed month */
  byLatestClosedMonth?: Maybe<ReconciledDecisionConnection>
}

export type ReconciledDecisionsAllArgs = {
  month: Scalars['Date']['input']
}

export type ReconciledDetection = {
  __typename?: 'ReconciledDetection'
  detectionReference?: Maybe<DetectionReference>
  emissionEndDate?: Maybe<Scalars['DateTime']['output']>
  emissionStartDate?: Maybe<Scalars['DateTime']['output']>
  totalVolume?: Maybe<Scalars['Float']['output']>
}

export type ReconciledEmission = {
  __typename?: 'ReconciledEmission'
  cmsDetections?: Maybe<Array<Maybe<ReconciledDetection>>>
  day: Scalars['Date']['output']
  decisions?: Maybe<Array<Maybe<Decision>>>
  equipmentReference?: Maybe<EquipmentReference>
  initialDecision?: Maybe<Decision>
  knownEvents?: Maybe<Array<Maybe<EmissionObservationReference>>>
  latestDecision?: Maybe<Decision>
  measurementDesignation?: Maybe<MeasurementDesignation>
  ogiDetections?: Maybe<Array<Maybe<ReconciledDetection>>>
  reconciliationRule?: Maybe<Scalars['String']['output']>
  siteReference?: Maybe<SiteReference>
  timeAdjustedDetections?: Maybe<Array<Maybe<ReconciledDetection>>>
  totalEmission?: Maybe<ReconciledEmissionTotal>
  unallocatedCmsDetections?: Maybe<Array<Maybe<ReconciledDetection>>>
  unallocatedKnownEvents?: Maybe<Array<Maybe<EmissionObservationReference>>>
  unallocatedOgiDetections?: Maybe<Array<Maybe<ReconciledDetection>>>
  unallocatedTimeAdjustedDetections?: Maybe<Array<Maybe<ReconciledDetection>>>
  virtualMeterId: Scalars['ID']['output']
}

export type ReconciledEmissionTotalEmissionArgs = {
  unit?: EmissionUnit
}

export type ReconciledEmissionConnection = {
  __typename?: 'ReconciledEmissionConnection'
  edges?: Maybe<Array<Maybe<ReconciledEmissionEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type ReconciledEmissionEdge = {
  __typename?: 'ReconciledEmissionEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: ReconciledEmission
}

export type ReconciledEmissionEvent = {
  __typename?: 'ReconciledEmissionEvent'
  camAssetReference?: Maybe<CamAssetReference>
  camSourceAssetReference?: Maybe<CamAssetReference>
  duration?: Maybe<Scalars['Float']['output']>
  emissionEventId: Scalars['ID']['output']
  reconciliationMonth: Scalars['Month']['output']
  siteMeasuredEmission?: Maybe<ReconciliationReportEmission>
  sourceCategory?: Maybe<Scalars['String']['output']>
  sourceMeasuredEmission?: Maybe<ReconciliationReportEmission>
}

export type ReconciledEmissionEventDurationArgs = {
  unit?: InputMaybe<EmissionEventDurationUnit>
}

export type ReconciledEmissionEventSiteMeasuredEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type ReconciledEmissionEventSourceMeasuredEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type ReconciledEmissionTotal = {
  __typename?: 'ReconciledEmissionTotal'
  bottomUpPortionTotal?: Maybe<Scalars['Float']['output']>
  bottomUpTotal?: Maybe<Scalars['Float']['output']>
  cmsTotal?: Maybe<Scalars['Float']['output']>
  emissionTotal?: Maybe<Scalars['Float']['output']>
  equipmentKnownEventsTotal?: Maybe<Scalars['Float']['output']>
  equipmentTopDownTotal?: Maybe<Scalars['Float']['output']>
  knownEventsPortionTotal?: Maybe<Scalars['Float']['output']>
  knownEventsTotal?: Maybe<Scalars['Float']['output']>
  manualEmissionTotal?: Maybe<Scalars['Float']['output']>
  measuredTotal?: Maybe<Scalars['Float']['output']>
  ogiTotal?: Maybe<Scalars['Float']['output']>
  reconciledEmissionTotal?: Maybe<Scalars['Float']['output']>
  siteAllocatedKnownEventsTotal?: Maybe<Scalars['Float']['output']>
  siteAllocatedTopDownTotal?: Maybe<Scalars['Float']['output']>
  siteKnownEventsTotal?: Maybe<Scalars['Float']['output']>
  siteMeasurementTotal?: Maybe<Scalars['Float']['output']>
  siteRemainderTotal?: Maybe<Scalars['Float']['output']>
  siteTopDownTotal?: Maybe<Scalars['Float']['output']>
  siteUnallocatedKnownEventsTotal?: Maybe<Scalars['Float']['output']>
  siteUnallocatedTopDownTotal?: Maybe<Scalars['Float']['output']>
  sourceQuantificationTotal?: Maybe<Scalars['Float']['output']>
  timeAdjustedTotal?: Maybe<Scalars['Float']['output']>
  topDownKnownEventsTotal?: Maybe<Scalars['Float']['output']>
  topDownPortionTotal?: Maybe<Scalars['Float']['output']>
  unallocatedCmsTotal?: Maybe<Scalars['Float']['output']>
  unallocatedOgiTotal?: Maybe<Scalars['Float']['output']>
  unallocatedTimeAdjustedTotal?: Maybe<Scalars['Float']['output']>
  unit?: Maybe<EmissionUnit>
}

export type ReconciledEmissions = {
  __typename?: 'ReconciledEmissions'
  /** URL of the latest reconciled emissions export file */
  exportFileUrl?: Maybe<Scalars['String']['output']>
}

export type ReconciledMonthReport = {
  __typename?: 'ReconciledMonthReport'
  approver?: Maybe<UserReference>
  auditReportUrl?: Maybe<Scalars['String']['output']>
  /** Count of all decisions, made automatically by reconciliation engine */
  automatedDecisionsCount: Scalars['Int']['output']
  /** Count of confirmed manual review decisions */
  confirmedDecisionsCount: Scalars['Int']['output']
  /** Reconciled emission report for a given day for a given site and month */
  dayEmission?: Maybe<ReconciledDayReport>
  daysEmission?: Maybe<Array<Maybe<ReconciledDayReport>>>
  /** Count of all manual and site remainder decisions (broken down into unique known event ids and time adjusted detection ids) whether new, already being reviewed or confirmed */
  decisionsToReviewCount: Scalars['Int']['output']
  errorCount: Scalars['Int']['output']
  forManualReview?: Maybe<Array<Maybe<ReconciledEmission>>>
  gasProductionMcf?: Maybe<Scalars['Float']['output']>
  /** Count of manual review decisions whether new, already being reviewed or confirmed */
  manualDecisionsToReviewCount: Scalars['Int']['output']
  methaneIntensity?: Maybe<Scalars['Float']['output']>
  month: Scalars['Date']['output']
  oilProductionBbl?: Maybe<Scalars['Float']['output']>
  siteReference?: Maybe<SiteReference>
  status: ReconciledSiteMonthStatus
  /** Total emission for all days in ReconciledDayReport */
  totalEmission?: Maybe<ReconciledEmissionTotal>
  /** List of events when the status of the site month updated, in reverse chronological order. */
  updateHistory?: Maybe<Array<Maybe<SiteMonthUpdateRecord>>>
  withAutomatedDecisions?: Maybe<Array<Maybe<ReconciledEmission>>>
}

export type ReconciledMonthReportDayEmissionArgs = {
  day: Scalars['Date']['input']
}

export type ReconciledMonthReportTotalEmissionArgs = {
  unit?: EmissionUnit
}

export type ReconciledMonthReportUpdateHistoryArgs = {
  last?: InputMaybe<Scalars['Int']['input']>
}

export type ReconciledMonthReportConnection = {
  __typename?: 'ReconciledMonthReportConnection'
  edges?: Maybe<Array<Maybe<ReconciledMonthReportEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type ReconciledMonthReportEdge = {
  __typename?: 'ReconciledMonthReportEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: ReconciledMonthReport
}

export type ReconciledReports = {
  __typename?: 'ReconciledReports'
  /** Reconciled emission report for all sites for a given month */
  all?: Maybe<ReconciledMonthReportConnection>
  /** Reconciled emission report for all sites for the latest closed month */
  byLatestClosedMonth?: Maybe<ReconciledMonthReportConnection>
  /** Reconciled emission report for a given site for a given month */
  bySiteMonth?: Maybe<ReconciledMonthReport>
  /** URL of the CSV version of the reconciled emission report for a given site for a given month */
  reportUrl?: Maybe<Scalars['String']['output']>
}

export type ReconciledReportsAllArgs = {
  month: Scalars['Date']['input']
}

export type ReconciledReportsBySiteMonthArgs = {
  month: Scalars['Date']['input']
  siteId: Scalars['ID']['input']
}

export type ReconciledReportsReportUrlArgs = {
  month: Scalars['Date']['input']
}

export type ReconciledSiteMonthStatus = {
  __typename?: 'ReconciledSiteMonthStatus'
  reconciliationStatus: ReconciliationStatus
  updatedTime?: Maybe<Scalars['DateTime']['output']>
}

export type ReconciliationDiscrepancyReviewRequest = {
  __typename?: 'ReconciliationDiscrepancyReviewRequest'
  status?: Maybe<ReconciliationDiscrepancyReviewRequestStatus>
}

export enum ReconciliationDiscrepancyReviewRequestStatus {
  Approved = 'APPROVED',
  Requested = 'REQUESTED',
}

export type ReconciliationError = {
  __typename?: 'ReconciliationError'
  area: DecisionArea
  errorTime: Scalars['DateTime']['output']
  errorType: ErrorLogType
  inventoryData?: Maybe<Scalars['JSONObject']['output']>
  siteId: Scalars['ID']['output']
}

export type ReconciliationErrorConnection = {
  __typename?: 'ReconciliationErrorConnection'
  edges?: Maybe<Array<Maybe<ReconciliationErrorEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type ReconciliationErrorEdge = {
  __typename?: 'ReconciliationErrorEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: ReconciliationError
}

export type ReconciliationErrors = {
  __typename?: 'ReconciliationErrors'
  /** Reconciliation errors for a given day for a given site and month */
  bySiteMonth?: Maybe<ReconciliationErrorConnection>
}

export type ReconciliationErrorsBySiteMonthArgs = {
  month: Scalars['Date']['input']
  siteId: Scalars['ID']['input']
}

export type ReconciliationMonth = {
  __typename?: 'ReconciliationMonth'
  isClosed: Scalars['Boolean']['output']
  month: Scalars['Date']['output']
}

export type ReconciliationMonths = {
  __typename?: 'ReconciliationMonths'
  /** Closed months */
  closed?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Open months */
  open?: Maybe<Array<Maybe<Scalars['String']['output']>>>
}

export enum ReconciliationReportDiscrepancyType {
  SiteLevelVsSourceLevel = 'SITE_LEVEL_VS_SOURCE_LEVEL',
  SiteMeasuredVsSourceLevel = 'SITE_MEASURED_VS_SOURCE_LEVEL',
}

export type ReconciliationReportEmission = {
  __typename?: 'ReconciliationReportEmission'
  emission?: Maybe<Scalars['Float']['output']>
  lowerBound?: Maybe<Scalars['Float']['output']>
  lowerUncertainty?: Maybe<Scalars['Float']['output']>
  upperBound?: Maybe<Scalars['Float']['output']>
  upperUncertainty?: Maybe<Scalars['Float']['output']>
}

export type ReconciliationReports = {
  __typename?: 'ReconciliationReports'
  /** OGMP2 Reconciliation report based on all/filtered assets for a given month */
  assetBasedByMonth?: Maybe<AssetBasedReconciliationReportConnection>
  /** OGMP2 Reconciliation report based on the most recent month within the same group */
  assetBasedByMostRecentMonth?: Maybe<AssetBasedReconciliationReportConnection>
  /** OGMP2 Reconciliation report based on all/filtered assets for a given year */
  assetBasedByYear?: Maybe<AssetBasedReconciliationReportConnection>
  /** OGMP2 Reconciliation report based on all/filtered emission events for a given asset and month */
  emissionEventsByAssetMonth?: Maybe<Array<Maybe<ReconciledEmissionEvent>>>
  /** OGMP2 Reconciliation report based on all/filtered source categories for a given asset and month */
  sourceCategoryBasedByAssetMonth?: Maybe<SourceCategoryBasedReconciliationReportConnection>
  /** OGMP2 Reconciliation report based on all source categories for a given asset and year */
  sourceCategoryBasedByAssetYear?: Maybe<SourceCategoryBasedReconciliationReportConnection>
}

export type ReconciliationReportsAssetBasedByMonthArgs = {
  assetReference?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<AssetBasedReconciliationReportFilter>
  month: Scalars['Month']['input']
  rule?: InputMaybe<ReconciliationRule>
}

export type ReconciliationReportsAssetBasedByMostRecentMonthArgs = {
  group?: InputMaybe<Scalars['String']['input']>
  rule?: InputMaybe<ReconciliationRule>
}

export type ReconciliationReportsAssetBasedByYearArgs = {
  assetReference?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<AssetBasedReconciliationReportFilter>
  year: Scalars['Year']['input']
}

export type ReconciliationReportsEmissionEventsByAssetMonthArgs = {
  assetReference: Scalars['ID']['input']
  month: Scalars['Month']['input']
  rule?: InputMaybe<ReconciliationRule>
}

export type ReconciliationReportsSourceCategoryBasedByAssetMonthArgs = {
  assetReference: Scalars['ID']['input']
  month: Scalars['Month']['input']
  rule?: InputMaybe<ReconciliationRule>
}

export type ReconciliationReportsSourceCategoryBasedByAssetYearArgs = {
  assetReference: Scalars['ID']['input']
  year: Scalars['Year']['input']
}

export enum ReconciliationRule {
  UserDefined = 'USER_DEFINED',
}

export type ReconciliationSiteMonth = {
  __typename?: 'ReconciliationSiteMonth'
  isClosed: Scalars['Boolean']['output']
  month: Scalars['String']['output']
  siteId: Scalars['ID']['output']
}

export type ReconciliationSiteMonthReference = {
  __typename?: 'ReconciliationSiteMonthReference'
  month: Scalars['String']['output']
  reconciliationSiteMonth?: Maybe<ReconciliationSiteMonth>
  siteId: Scalars['ID']['output']
}

export type ReconciliationSiteMonths = {
  __typename?: 'ReconciliationSiteMonths'
  /** Reconciliation information for a site and month */
  bySiteMonth?: Maybe<ReconciliationSiteMonth>
}

export type ReconciliationSiteMonthsBySiteMonthArgs = {
  month: Scalars['Date']['input']
  siteId: Scalars['ID']['input']
}

export enum ReconciliationStatus {
  ReconciliationStatusClosed = 'RECONCILIATION_STATUS_CLOSED',
  ReconciliationStatusOpen = 'RECONCILIATION_STATUS_OPEN',
  ReconciliationStatusPendingApproval = 'RECONCILIATION_STATUS_PENDING_APPROVAL',
  ReconciliationStatusReopened = 'RECONCILIATION_STATUS_REOPENED',
}

export type RelevantToTargetEmissionEventsFilter = {
  targetEmissionEventId: Scalars['ID']['input']
  timeBoxInDays?: InputMaybe<Scalars['Int']['input']>
}

export type RelevantToTargetFilter = {
  targetObservationId: Scalars['ID']['input']
  timeBoxInDays?: InputMaybe<Scalars['Int']['input']>
}

/** Implies closed -> open */
export type ReopenIssueInput = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  /** If a string literal is used, it is attempted to be parsed as JSON first. Note: Workato does not escape quotes (`"`) in string literals, so prefix with backspace (`\"`) */
  contextData?: InputMaybe<Scalars['JSONObject']['input']>
  issueId: Scalars['ID']['input']
}

export type ReopenReconciliationSiteMonthError = MutationError & {
  __typename?: 'ReopenReconciliationSiteMonthError'
  message: Scalars['String']['output']
  month?: Maybe<Scalars['String']['output']>
  siteId?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type ReopenReconciliationSiteMonthInput = {
  month: Scalars['Date']['input']
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ReopenReconciliationSiteMonthResult = {
  __typename?: 'ReopenReconciliationSiteMonthResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<ReopenReconciliationSiteMonthError>>
}

export type RequestDecisionsReviewForApprovalError = MutationError & {
  __typename?: 'RequestDecisionsReviewForApprovalError'
  message: Scalars['String']['output']
  month?: Maybe<Scalars['String']['output']>
  siteId?: Maybe<Scalars['ID']['output']>
  type: Scalars['String']['output']
}

export type RequestDecisionsReviewForApprovalInput = {
  approverUsername: Scalars['String']['input']
  month: Scalars['Date']['input']
  siteIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type RequestDecisionsReviewForApprovalResult = {
  __typename?: 'RequestDecisionsReviewForApprovalResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<RequestDecisionsReviewForApprovalError>>
}

/** @deprecated in favour of IssueResolutionContext extended from su-backend */
export type ResolutionContext = {
  reference: Scalars['ID']['output']
  type: ResolutionContextType
}

/** @deprecated moved to su-backend */
export enum ResolutionContextType {
  UserAction = 'userAction',
  WorkflowExecuteDag = 'workflowExecuteDag',
}

export enum ResolutionMode {
  DetectionResolvedCorrelated = 'DETECTION_RESOLVED_CORRELATED',
  DetectionResolvedFalseAlarm = 'DETECTION_RESOLVED_FALSE_ALARM',
  DetectionResolvedReadyToReconcile = 'DETECTION_RESOLVED_READY_TO_RECONCILE',
}

export type ResolveDetectionInput = {
  detectionId: Scalars['ID']['input']
  resolutionCorrelatedEmissionObservationId?: InputMaybe<Scalars['ID']['input']>
  /** Required if resolutionMode is DETECTION_RESOLVED_CORRELATED or DETECTION_RESOLVED_FALSE_ALARM */
  resolutionExplanation?: InputMaybe<Scalars['String']['input']>
  resolutionMode?: InputMaybe<ResolutionMode>
}

/** @deprecated moved to su-backend */
export type RuleExecutionPathItem = {
  __typename?: 'RuleExecutionPathItem'
  nodeId: Scalars['ID']['output']
  widgetId: Scalars['ID']['output']
}

/**
 * Rule and RuleReference are here for backwards compatibility with su-backend
 * @todo eventually replace Rule with Workflow
 */
export type RuleReference = {
  __typename?: 'RuleReference'
  rule?: Maybe<Workflow>
  ruleId: Scalars['ID']['output']
}

/** @deprecated in favour of IssueResolutionContext extended from su-backend / rules */
export type RulesEngineResolutionContext = ResolutionContext & {
  __typename?: 'RulesEngineResolutionContext'
  data?: Maybe<Scalars['JSONObject']['output']>
  executionPath: Array<RuleExecutionPathItem>
  reference: Scalars['ID']['output']
  ruleReference?: Maybe<RuleReference>
  type: ResolutionContextType
}

/** @deprecated in favour of IssueTriggerContext extended from su-backend */
export type RulesEngineTriggerContext = TriggerContext & {
  __typename?: 'RulesEngineTriggerContext'
  data?: Maybe<Scalars['JSONObject']['output']>
  executionPath: Array<RuleExecutionPathItem>
  reference: Scalars['ID']['output']
  ruleReference?: Maybe<RuleReference>
  type: TriggerContextType
}

export type Scope2Emission = {
  __typename?: 'Scope2Emission'
  audit: Scope2EmissionAudit
  emission: Scalars['Float']['output']
  month: Scalars['Date']['output']
  /** @deprecated Use siteReference.siteId instead */
  siteId: Scalars['ID']['output']
  siteReference?: Maybe<SiteReference>
}

export type Scope2EmissionEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type Scope2EmissionAudit = {
  __typename?: 'Scope2EmissionAudit'
  createdBy?: Maybe<UserReference>
  createdTime: Scalars['DateTime']['output']
  /**
   * List of previous updates made to the Scope 2 emission record, in reverse chronological order. The current emission
   * value (i.e. most recent update) is not included in the list.
   */
  updateHistory: Array<Maybe<Scope2EmissionUpdateRecord>>
  updatedBy?: Maybe<UserReference>
  updatedTime: Scalars['DateTime']['output']
}

export type Scope2EmissionAuditUpdateHistoryArgs = {
  last?: InputMaybe<Scalars['Int']['input']>
}

export type Scope2EmissionUpdateRecord = {
  __typename?: 'Scope2EmissionUpdateRecord'
  emission: Scalars['Float']['output']
  updatedBy?: Maybe<UserReference>
  updatedTime?: Maybe<Scalars['DateTime']['output']>
}

export type Scope2EmissionUpdateRecordEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type Scope2Emissions = {
  __typename?: 'Scope2Emissions'
  /** Scope 2 emission for a site and month */
  bySiteMonth?: Maybe<Scope2Emission>
}

export type Scope2EmissionsBySiteMonthArgs = {
  month: Scalars['Date']['input']
  siteId: Scalars['ID']['input']
}

export type SetupAssetTypesExtensionsInput = {
  assetTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>
  backendComponents: Array<Scalars['ID']['input']>
  datasetIds?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SetupSystemAssetTypesInput = {
  assetTypeIds?: InputMaybe<Array<SystemAssetTypeId>>
}

/**
 * A Site is an abstract modeling concept whose only tangible elements are the associated descriptive properties, and the other feature types that lay within it which model physical items such as buildings, levels and units.
 *
 * category can be: university, factory, etc
 */
export type Site = SiteFeature &
  SitePlace & {
    __typename?: 'Site'
    active: Scalars['Boolean']['output']
    audit?: Maybe<Audit>
    beacons?: Maybe<SiteBeaconConnection>
    buildings?: Maybe<SiteBuildingConnection>
    featureType: SiteFeatureType
    geoJSONFeature?: Maybe<GeoJsonFeature>
    geometry?: Maybe<GeoJsonGeometryInterface>
    id: Scalars['ID']['output']
    locations?: Maybe<SiteLocationConnection>
    observation?: Maybe<Scalars['JSONObject']['output']>
    observations?: Maybe<Array<Scalars['JSONObject']['output']>>
    owner?: Maybe<EntityOwner>
    pathways?: Maybe<SitePathwayConnection>
    properties?: Maybe<SiteProperties>
    thumbUrl?: Maybe<Scalars['String']['output']>
    type: Scalars['String']['output']
  }

/**
 * A Site is an abstract modeling concept whose only tangible elements are the associated descriptive properties, and the other feature types that lay within it which model physical items such as buildings, levels and units.
 *
 * category can be: university, factory, etc
 */
export type SiteGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

/**
 * A Site is an abstract modeling concept whose only tangible elements are the associated descriptive properties, and the other feature types that lay within it which model physical items such as buildings, levels and units.
 *
 * category can be: university, factory, etc
 */
export type SiteObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

/**
 * A Site is an abstract modeling concept whose only tangible elements are the associated descriptive properties, and the other feature types that lay within it which model physical items such as buildings, levels and units.
 *
 * category can be: university, factory, etc
 */
export type SiteObservationsArgs = {
  observationsFilter?: InputMaybe<SiteObservationsFilter>
  select?: InputMaybe<SiteObservationSelector>
}

/** A Beacon is a small Bluetooth radio transmitter that could be placed anywhere inside a site. */
export type SiteBeacon = SiteFeature & {
  __typename?: 'SiteBeacon'
  featureType: SiteFeatureType
  geoJSONFeature?: Maybe<GeoJsonFeature>
  geometry: GeoJsonGeometryInterface
  id: Scalars['ID']['output']
  observation?: Maybe<Scalars['JSONObject']['output']>
  properties?: Maybe<SiteBeaconProperties>
  type: Scalars['String']['output']
}

/** A Beacon is a small Bluetooth radio transmitter that could be placed anywhere inside a site. */
export type SiteBeaconGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

/** A Beacon is a small Bluetooth radio transmitter that could be placed anywhere inside a site. */
export type SiteBeaconObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SiteBeaconConnection = {
  __typename?: 'SiteBeaconConnection'
  edges?: Maybe<Array<Maybe<SiteBeaconEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SiteBeaconEdge = {
  __typename?: 'SiteBeaconEdge'
  cursor: Scalars['ID']['output']
  node: SiteBeacon
}

export type SiteBeaconMutationError = MutationError & {
  __typename?: 'SiteBeaconMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SiteBeaconMutationResponse = MutationResponse & {
  __typename?: 'SiteBeaconMutationResponse'
  beacon?: Maybe<SiteBeacon>
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<SiteBeaconMutationError>>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
}

export type SiteBeaconProperties = SiteFeatureProperties & {
  __typename?: 'SiteBeaconProperties'
  UUID?: Maybe<Scalars['String']['output']>
  active?: Maybe<Scalars['Boolean']['output']>
  brand?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  deviceId?: Maybe<Scalars['String']['output']>
  macAddress?: Maybe<Scalars['String']['output']>
  major?: Maybe<Scalars['Int']['output']>
  minor?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  parentId: Scalars['ID']['output']
  sensitivity?: Maybe<Scalars['Float']['output']>
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

/**
 * A Building describes a physical building structure associated with a Site.
 * category can be: Business, Residential, Industrial, etc.
 */
export type SiteBuilding = SiteFeature &
  SitePlace & {
    __typename?: 'SiteBuilding'
    beacons?: Maybe<SiteBeaconConnection>
    featureType: SiteFeatureType
    geoJSONFeature?: Maybe<GeoJsonFeature>
    geometry: GeoJsonGeometryInterface
    id: Scalars['ID']['output']
    levels?: Maybe<SiteLevelConnection>
    observation?: Maybe<Scalars['JSONObject']['output']>
    pathways?: Maybe<SitePathwayConnection>
    properties?: Maybe<SiteBuildingProperties>
    type: Scalars['String']['output']
  }

/**
 * A Building describes a physical building structure associated with a Site.
 * category can be: Business, Residential, Industrial, etc.
 */
export type SiteBuildingGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

/**
 * A Building describes a physical building structure associated with a Site.
 * category can be: Business, Residential, Industrial, etc.
 */
export type SiteBuildingObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SiteBuildingConnection = {
  __typename?: 'SiteBuildingConnection'
  edges?: Maybe<Array<Maybe<SiteBuildingEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SiteBuildingEdge = {
  __typename?: 'SiteBuildingEdge'
  cursor: Scalars['ID']['output']
  node: SiteBuilding
}

export type SiteBuildingMutationError = MutationError & {
  __typename?: 'SiteBuildingMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SiteBuildingMutationResponse = MutationResponse & {
  __typename?: 'SiteBuildingMutationResponse'
  building?: Maybe<SiteBuilding>
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<SiteBuildingMutationError>>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
}

export type SiteBuildingProperties = SiteFeatureProperties & {
  __typename?: 'SiteBuildingProperties'
  category?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  height?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  parentId: Scalars['ID']['output']
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

export type SiteBuildingPropertiesHeightArgs = {
  unit?: InputMaybe<HeightUnit>
}

export type SiteBulkExportInput = {
  asyncTrigger?: InputMaybe<Scalars['Boolean']['input']>
}

export type SiteBulkExportResponse = {
  __typename?: 'SiteBulkExportResponse'
  message?: Maybe<Scalars['String']['output']>
}

export type SiteConnection = {
  __typename?: 'SiteConnection'
  edges?: Maybe<Array<Maybe<SiteEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SiteEdge = {
  __typename?: 'SiteEdge'
  cursor: Scalars['ID']['output']
  node: Site
}

export type SiteFeature = {
  featureType: SiteFeatureType
  geoJSONFeature?: Maybe<GeoJsonFeature>
  geometry?: Maybe<GeoJsonGeometryInterface>
  id: Scalars['ID']['output']
  observation?: Maybe<Scalars['JSONObject']['output']>
  properties?: Maybe<SiteFeatureProperties>
  type: Scalars['String']['output']
}

export type SiteFeatureGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SiteFeatureObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SiteFeatureCategory = {
  __typename?: 'SiteFeatureCategory'
  value: Scalars['String']['output']
}

export type SiteFeatureProperties = {
  description?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

export enum SiteFeatureType {
  Beacon = 'beacon',
  Building = 'building',
  Level = 'level',
  Location = 'location',
  Pathway = 'pathway',
  Site = 'site',
  Unit = 'unit',
}

export type SiteFilter = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>
  /** The groups of the site */
  group?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** The ids of the site */
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
  /** Any properties value of the site */
  search?: InputMaybe<SiteSearchFilter>
  /** The owner user names of the site */
  username?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

/**
 * A Level models the presence, location and approximate physical extent of a level area in a physical building
 * category can be: parking, etc
 */
export type SiteLevel = SiteFeature &
  SitePlace & {
    __typename?: 'SiteLevel'
    beacons?: Maybe<SiteBeaconConnection>
    featureType: SiteFeatureType
    geoJSONFeature?: Maybe<GeoJsonFeature>
    geometry: GeoJsonGeometryInterface
    id: Scalars['ID']['output']
    observation?: Maybe<Scalars['JSONObject']['output']>
    pathways?: Maybe<SitePathwayConnection>
    properties?: Maybe<SiteLevelProperties>
    type: Scalars['String']['output']
    units?: Maybe<SiteUnitConnection>
  }

/**
 * A Level models the presence, location and approximate physical extent of a level area in a physical building
 * category can be: parking, etc
 */
export type SiteLevelGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

/**
 * A Level models the presence, location and approximate physical extent of a level area in a physical building
 * category can be: parking, etc
 */
export type SiteLevelObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SiteLevelConnection = {
  __typename?: 'SiteLevelConnection'
  edges?: Maybe<Array<Maybe<SiteLevelEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SiteLevelEdge = {
  __typename?: 'SiteLevelEdge'
  cursor: Scalars['ID']['output']
  node: SiteLevel
}

export type SiteLevelMutationError = MutationError & {
  __typename?: 'SiteLevelMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SiteLevelMutationResponse = MutationResponse & {
  __typename?: 'SiteLevelMutationResponse'
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<SiteLevelMutationError>>
  level?: Maybe<SiteLevel>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
}

export type SiteLevelProperties = SiteFeatureProperties & {
  __typename?: 'SiteLevelProperties'
  category?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  /** FloorHeight: altitude of the bottom surface */
  floorHeight?: Maybe<Scalars['Float']['output']>
  /** Height: height of the entity */
  height?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  ordinal: Scalars['Int']['output']
  parentId: Scalars['ID']['output']
  /** UserProperties: user arbitrary properties and has to the complete userProperties object */
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

export type SiteLevelPropertiesFloorHeightArgs = {
  unit?: InputMaybe<HeightUnit>
}

export type SiteLevelPropertiesHeightArgs = {
  unit?: InputMaybe<HeightUnit>
}

/**
 * A location models the presence, location and approximate extent of a space.
 * Can be any geometry type or any category
 */
export type SiteLocation = SiteFeature &
  SitePlace & {
    __typename?: 'SiteLocation'
    beacons?: Maybe<SiteBeaconConnection>
    featureType: SiteFeatureType
    geoJSONFeature?: Maybe<GeoJsonFeature>
    geometry?: Maybe<GeoJsonGeometryInterface>
    id: Scalars['ID']['output']
    locations?: Maybe<SiteLocationConnection>
    observation?: Maybe<Scalars['JSONObject']['output']>
    properties?: Maybe<SiteLocationProperties>
    type: Scalars['String']['output']
  }

/**
 * A location models the presence, location and approximate extent of a space.
 * Can be any geometry type or any category
 */
export type SiteLocationGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

/**
 * A location models the presence, location and approximate extent of a space.
 * Can be any geometry type or any category
 */
export type SiteLocationObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SiteLocationConnection = {
  __typename?: 'SiteLocationConnection'
  edges?: Maybe<Array<Maybe<SiteLocationEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SiteLocationEdge = {
  __typename?: 'SiteLocationEdge'
  cursor: Scalars['ID']['output']
  node: SiteLocation
}

export type SiteLocationMutationError = MutationError & {
  __typename?: 'SiteLocationMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SiteLocationMutationResponse = MutationResponse & {
  __typename?: 'SiteLocationMutationResponse'
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<SiteLocationMutationError>>
  location?: Maybe<SiteLocation>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
}

export type SiteLocationProperties = SiteFeatureProperties & {
  __typename?: 'SiteLocationProperties'
  category?: Maybe<Scalars['String']['output']>
  department?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  parentId: Scalars['ID']['output']
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

export type SiteMonthReference = {
  __typename?: 'SiteMonthReference'
  month: Scalars['String']['output']
  reconciliationSiteMonth?: Maybe<ReconciliationSiteMonth>
  siteId: Scalars['ID']['output']
}

export type SiteMonthUpdateRecord = {
  __typename?: 'SiteMonthUpdateRecord'
  approver?: Maybe<UserReference>
  status: ReconciliationStatus
  updatedBy?: Maybe<UserReference>
  updatedTime?: Maybe<Scalars['DateTime']['output']>
}

export type SiteMutationError = MutationError & {
  __typename?: 'SiteMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SiteMutationResponse = MutationResponse & {
  __typename?: 'SiteMutationResponse'
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<SiteMutationError>>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  site?: Maybe<Site>
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
}

export type SiteObservationSelector = {
  pickProperties?: InputMaybe<Array<Scalars['String']['input']>>
  pickUserProperties?: InputMaybe<Array<Scalars['String']['input']>>
}

export type SiteObservationsFilter = {
  active?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>
  entityType?: InputMaybe<Array<InputMaybe<SiteFeatureType>>>
}

export type SitePathway = SiteFeature &
  SitePlace & {
    __typename?: 'SitePathway'
    beacons?: Maybe<SiteBeaconConnection>
    featureType: SiteFeatureType
    geoJSONFeature?: Maybe<GeoJsonFeature>
    geometry: GeoJsonGeometryInterface
    id: Scalars['ID']['output']
    observation?: Maybe<Scalars['JSONObject']['output']>
    properties?: Maybe<SitePathwayProperties>
    type: Scalars['String']['output']
  }

export type SitePathwayGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SitePathwayObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SitePathwayConnection = {
  __typename?: 'SitePathwayConnection'
  edges?: Maybe<Array<Maybe<SitePathwayEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SitePathwayEdge = {
  __typename?: 'SitePathwayEdge'
  cursor: Scalars['ID']['output']
  node: SitePathway
}

export type SitePathwayMutationError = MutationError & {
  __typename?: 'SitePathwayMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SitePathwayMutationResponse = MutationResponse & {
  __typename?: 'SitePathwayMutationResponse'
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<SitePathwayMutationError>>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  pathway?: Maybe<SitePathway>
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
}

export type SitePathwayProperties = SiteFeatureProperties & {
  __typename?: 'SitePathwayProperties'
  description?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  parentId: Scalars['ID']['output']
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

export type SitePlace = {
  beacons?: Maybe<SiteBeaconConnection>
}

export type SitePlacement =
  | Site
  | SiteBuilding
  | SiteLevel
  | SitePathway
  | SiteUnit

export type SiteProperties = SiteFeatureProperties & {
  __typename?: 'SiteProperties'
  category?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

export type SiteReference = {
  __typename?: 'SiteReference'
  site?: Maybe<Site>
  siteId: Scalars['ID']['output']
}

export type SiteSearchFilter = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  query?: InputMaybe<Scalars['String']['input']>
}

/**
 * A Unit models the presence, location and approximate extent of a space.
 * category: Room, Elevator, Stairs, Ramp. etc.
 */
export type SiteUnit = SiteFeature &
  SitePlace & {
    __typename?: 'SiteUnit'
    beacons?: Maybe<SiteBeaconConnection>
    featureType: SiteFeatureType
    geoJSONFeature?: Maybe<GeoJsonFeature>
    geometry: GeoJsonGeometryInterface
    id: Scalars['ID']['output']
    observation?: Maybe<Scalars['JSONObject']['output']>
    pathways?: Maybe<SitePathwayConnection>
    properties?: Maybe<SiteUnitProperties>
    type: Scalars['String']['output']
  }

/**
 * A Unit models the presence, location and approximate extent of a space.
 * category: Room, Elevator, Stairs, Ramp. etc.
 */
export type SiteUnitGeoJsonFeatureArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

/**
 * A Unit models the presence, location and approximate extent of a space.
 * category: Room, Elevator, Stairs, Ramp. etc.
 */
export type SiteUnitObservationArgs = {
  select?: InputMaybe<SiteObservationSelector>
}

export type SiteUnitConnection = {
  __typename?: 'SiteUnitConnection'
  edges?: Maybe<Array<Maybe<SiteUnitEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SiteUnitEdge = {
  __typename?: 'SiteUnitEdge'
  cursor: Scalars['ID']['output']
  node: SiteUnit
}

export type SiteUnitMutationError = MutationError & {
  __typename?: 'SiteUnitMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type SiteUnitMutationResponse = MutationResponse & {
  __typename?: 'SiteUnitMutationResponse'
  /** @deprecated No longer supported */
  code: Scalars['String']['output']
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<SiteUnitMutationError>>
  /** @deprecated Use errors.message for error messages. */
  message: Scalars['String']['output']
  /** @deprecated See https://github.com/SensorUp/SoftwareDevelopment/pull/9 */
  success: Scalars['Boolean']['output']
  unit?: Maybe<SiteUnit>
}

export type SiteUnitProperties = SiteFeatureProperties & {
  __typename?: 'SiteUnitProperties'
  category?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  height?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  parentId: Scalars['ID']['output']
  /** UserProperties: user arbitrary properties and has to the complete userProperties object */
  userProperties?: Maybe<Scalars['JSONObject']['output']>
}

export type SiteUnitPropertiesHeightArgs = {
  unit?: InputMaybe<HeightUnit>
}

export type SourceAttribution = {
  __typename?: 'SourceAttribution'
  attributionExplanation?: Maybe<Scalars['String']['output']>
  attributionType: SourceAttributionType
}

export enum SourceAttributionType {
  Component = 'COMPONENT',
  Equipment = 'EQUIPMENT',
  Pipeline = 'PIPELINE',
  Site = 'SITE',
  Well = 'WELL',
}

export type SourceCategoryBasedReconciliationReport = {
  __typename?: 'SourceCategoryBasedReconciliationReport'
  camAssetReference?: Maybe<CamAssetReference>
  discrepancyPercent?: Maybe<Scalars['Float']['output']>
  duration?: Maybe<Scalars['Float']['output']>
  emissionEventsCount?: Maybe<Scalars['Int']['output']>
  reviewRequest?: Maybe<ReconciliationDiscrepancyReviewRequest>
  siteLevelEmission?: Maybe<ReconciliationReportEmission>
  siteMeasuredEmission?: Maybe<ReconciliationReportEmission>
  siteSimulatedEmission?: Maybe<ReconciliationReportEmission>
  sourceBottomUpEmission?: Maybe<ReconciliationReportEmission>
  sourceCategory?: Maybe<Scalars['String']['output']>
  sourceLevelEmission?: Maybe<ReconciliationReportEmission>
  sourceMeasuredEmission?: Maybe<ReconciliationReportEmission>
  unattributedEmissionEventsCount?: Maybe<Scalars['Int']['output']>
}

export type SourceCategoryBasedReconciliationReportDiscrepancyPercentArgs = {
  type: ReconciliationReportDiscrepancyType
}

export type SourceCategoryBasedReconciliationReportDurationArgs = {
  unit?: InputMaybe<EmissionEventDurationUnit>
}

export type SourceCategoryBasedReconciliationReportSiteLevelEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type SourceCategoryBasedReconciliationReportSiteMeasuredEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type SourceCategoryBasedReconciliationReportSiteSimulatedEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type SourceCategoryBasedReconciliationReportSourceBottomUpEmissionArgs =
  {
    unit?: InputMaybe<EmissionUnit>
  }

export type SourceCategoryBasedReconciliationReportSourceLevelEmissionArgs = {
  unit?: InputMaybe<EmissionUnit>
}

export type SourceCategoryBasedReconciliationReportSourceMeasuredEmissionArgs =
  {
    unit?: InputMaybe<EmissionUnit>
  }

export type SourceCategoryBasedReconciliationReportConnection = {
  __typename?: 'SourceCategoryBasedReconciliationReportConnection'
  edges?: Maybe<Array<Maybe<SourceCategoryBasedReconciliationReportEdge>>>
  pageInfo?: Maybe<PageInfo>
}

export type SourceCategoryBasedReconciliationReportEdge = {
  __typename?: 'SourceCategoryBasedReconciliationReportEdge'
  cursor?: Maybe<Scalars['ID']['output']>
  node: SourceCategoryBasedReconciliationReport
}

export type SourceCategoryEmissionsInput = {
  siteMeasuredEmission?: InputMaybe<EmissionInput>
  siteSimulatedEmission?: InputMaybe<EmissionInput>
  sourceBottomUpEmission?: InputMaybe<EmissionInput>
  sourceCategory: Scalars['String']['input']
  sourceMeasuredEmission?: InputMaybe<EmissionInput>
}

export type SourceType = {
  __typename?: 'SourceType'
  camSourceAssetReference?: Maybe<CamAssetReference>
  explanation?: Maybe<Scalars['String']['output']>
  sourceAssetReference: Scalars['String']['output']
  sourceAttribution: SourceAttributionType
  sourceCategory?: Maybe<Scalars['String']['output']>
}

export enum SpeedUnitType {
  MilesPerHour = 'MILES_PER_HOUR',
}

export type StateUpdateDataset = {
  __typename?: 'StateUpdateDataset'
  catalogId: Scalars['ID']['output']
  dataset: Scalars['String']['output']
  properties?: Maybe<Array<Scalars['String']['output']>>
}

export enum StatusReason {
  AutoClosedOgiObservation = 'AUTO_CLOSED_OGI_OBSERVATION',
  ContainsAlarmId = 'CONTAINS_ALARM_ID',
  MissingEndTime = 'MISSING_END_TIME',
  MissingSiteAttribution = 'MISSING_SITE_ATTRIBUTION',
  MissingStartTime = 'MISSING_START_TIME',
  MissingSubSiteAttribution = 'MISSING_SUB_SITE_ATTRIBUTION',
}

export type StringOperand = PropertyOperand | StringValueOperand

export type StringOperandInput = {
  property?: InputMaybe<Scalars['String']['input']>
  unionType: OperandUnionType
  value?: InputMaybe<Scalars['String']['input']>
}

export type StringValueOperand = {
  __typename?: 'StringValueOperand'
  value: Scalars['String']['output']
}

export enum SystemAssetTypeId {
  Component = 'COMPONENT',
  Equipment = 'EQUIPMENT',
  Pipeline = 'PIPELINE',
  Site = 'SITE',
  Well = 'WELL',
}

export enum TemperatureUnitType {
  Fahrenheit = 'FAHRENHEIT',
}

export type TransitionIssueStateInput = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  /** If a string literal is used, it is attempted to be parsed as JSON first. Note: Workato does not escape quotes (`"`) in string literals, so prefix with backspace (`\"`) */
  contextData?: InputMaybe<Scalars['JSONObject']['input']>
  issueId: Scalars['ID']['input']
  statusFrom: IssueStatus
  statusTo: IssueStatus
}

export type TriggerAssetTypesStateInput = {
  assetTypeIds?: InputMaybe<Array<SystemAssetTypeId>>
  chunkSize?: InputMaybe<Scalars['Int']['input']>
  defaultGroup?: InputMaybe<Scalars['ID']['input']>
  onlyGroup?: InputMaybe<Scalars['ID']['input']>
  onlyProperties?: InputMaybe<Array<Scalars['ID']['input']>>
  seedLimit?: InputMaybe<Scalars['Int']['input']>
  stateSyncProperties?: InputMaybe<
    Array<AssetTypeExtensionStateSyncPropertyInput>
  >
  steps?: InputMaybe<Array<AssetTypeStateManageMethod>>
}

/** @deprecated in favour of IssueTriggerContext */
export type TriggerContext = {
  reference: Scalars['ID']['output']
  type: TriggerContextType
}

/** @deprecated moved to su-backend */
export enum TriggerContextType {
  /** @deprecated  */
  UserAction = 'userAction',
  /** @deprecated  */
  WorkflowExecuteDag = 'workflowExecuteDag',
}

export type TriggerIssueAssigneeInput = {
  username?: InputMaybe<Scalars['String']['input']>
}

export type TriggerIssueInput = {
  assignee?: InputMaybe<TriggerIssueAssigneeInput>
  /** If a string literal is used, it is attempted to be parsed as JSON first. Note: Workato does not escape quotes (`"`) in string literals, so prefix with backspace (`\"`) */
  contextData?: InputMaybe<Scalars['JSONObject']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  issueId?: InputMaybe<Scalars['ID']['input']>
  severity: Scalars['Int']['input']
  statesData?: InputMaybe<Array<IssueStateDataInput>>
  statesParameter?: InputMaybe<Array<IssueStateParameterInput>>
  subject: IssueSubjectInput
  taskType?: InputMaybe<IssueTaskType>
  title: Scalars['String']['input']
  type: IssueType
  watchers?: InputMaybe<Array<TriggerIssueWatcherInput>>
}

/** Experimental input */
export type TriggerIssueMultitaskInput = {
  assignee?: InputMaybe<TriggerIssueAssigneeInput>
  /** If a string literal is used, it is attempted to be parsed as JSON first. Note: Workato does not escape quotes (`"`) in string literals, so prefix with backspace (`\"`) */
  contextData?: InputMaybe<Scalars['JSONObject']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  issueId?: InputMaybe<Scalars['ID']['input']>
  multitaskProcedureId: Scalars['ID']['input']
  /** The group can be set so that super admins can define which group the Issue belongs to. The username is ignored until the system supports creating entities owned by other users. */
  owner?: InputMaybe<EntityOwnerInput>
  severity?: InputMaybe<Scalars['Int']['input']>
  subTasks?: InputMaybe<Array<TriggerIssueSubTaskInput>>
  subTasksComplete?: InputMaybe<Scalars['Boolean']['input']>
  subTasksIds?: InputMaybe<Array<Scalars['ID']['input']>>
  subTasksLastModifiedAt?: InputMaybe<Scalars['DateTime']['input']>
  subject: IssueSubjectInput
  title: Scalars['String']['input']
  watchers?: InputMaybe<Array<TriggerIssueWatcherInput>>
}

/** Experimental input */
export type TriggerIssueSubTaskInput = {
  assignee?: InputMaybe<TriggerIssueAssigneeInput>
  /** If a string literal is used, it is attempted to be parsed as JSON first. Note: Workato does not escape quotes (`"`) in string literals, so prefix with backspace (`\"`) */
  contextData?: InputMaybe<Scalars['JSONObject']['input']>
  dataCollectionFormReference: Scalars['XFormReference']['input']
  dataCollectionFormResult?: InputMaybe<Scalars['XFormResult']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  issueId?: InputMaybe<Scalars['ID']['input']>
  parentIssueId: Scalars['ID']['input']
  severity?: InputMaybe<Scalars['Int']['input']>
  subject: IssueSubjectInput
  taskType?: InputMaybe<IssueTaskType>
  title: Scalars['String']['input']
  watchers?: InputMaybe<Array<TriggerIssueWatcherInput>>
}

/** Experimental input */
export type TriggerIssueTaskInput = {
  assignee?: InputMaybe<TriggerIssueAssigneeInput>
  /** If a string literal is used, it is attempted to be parsed as JSON first. Note: Workato does not escape quotes (`"`) in string literals, so prefix with backspace (`\"`) */
  contextData?: InputMaybe<Scalars['JSONObject']['input']>
  dataCollectionFormComplete?: InputMaybe<Scalars['Boolean']['input']>
  dataCollectionFormReference: Scalars['XFormReference']['input']
  dataCollectionFormResult?: InputMaybe<Scalars['XFormResult']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  issueId?: InputMaybe<Scalars['ID']['input']>
  /** The group can be set so that super admins can define which group the Issue belongs to. The username is ignored until the system supports creating entities owned by other users. */
  owner?: InputMaybe<EntityOwnerInput>
  severity?: InputMaybe<Scalars['Int']['input']>
  subject: IssueSubjectInput
  taskType?: InputMaybe<IssueTaskType>
  title: Scalars['String']['input']
  watchers?: InputMaybe<Array<TriggerIssueWatcherInput>>
}

export type TriggerIssueWatcherInput = {
  email?: InputMaybe<Scalars['String']['input']>
}

export type TriggerReconciliationInput = {
  month: Scalars['Date']['input']
}

export type TriggerReconciliationMutationError = MutationError & {
  __typename?: 'TriggerReconciliationMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type TriggerReconciliationResult = {
  __typename?: 'TriggerReconciliationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<TriggerReconciliationMutationError>>
}

export type UndeleteIssueInput = {
  issueId: Scalars['ID']['input']
}

export type UpDataset = {
  baseSpecification?: Maybe<Scalars['JSONObject']['output']>
  dataset: Scalars['ID']['output']
  displayName?: Maybe<Scalars['String']['output']>
  metadataSource?: Maybe<Scalars['String']['output']>
  metadataVersion?: Maybe<Scalars['String']['output']>
  properties?: Maybe<Array<UpSpecPropertyDefinition>>
  specificationParameters?: Maybe<Array<UpSpecificationParameter>>
  timeliness?: Maybe<UpSpecificationTimeliness>
}

export enum UpSpecPropertyCompleteness {
  Exhaustive = 'exhaustive',
  Suggested = 'suggested',
}

export type UpSpecPropertyDefinition = {
  __typename?: 'UpSpecPropertyDefinition'
  displayName?: Maybe<Scalars['String']['output']>
  format?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  range?: Maybe<Array<Scalars['Int']['output']>>
  terms?: Maybe<Array<UpSpecPropertyTerm>>
  termsCompleteness?: Maybe<UpSpecPropertyCompleteness>
  type?: Maybe<UpSpecPropertyType>
}

export type UpSpecPropertyDefinitionInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  format?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  range?: InputMaybe<Array<Scalars['Int']['input']>>
  terms?: InputMaybe<Array<UpSpecPropertyTermInput>>
  termsCompleteness?: InputMaybe<UpSpecPropertyCompleteness>
  type?: InputMaybe<UpSpecPropertyType>
}

export enum UpSpecPropertyNecessity {
  Optional = 'optional',
  Required = 'required',
}

export type UpSpecPropertyOption = {
  __typename?: 'UpSpecPropertyOption'
  displayName?: Maybe<Scalars['String']['output']>
  key?: Maybe<Scalars['String']['output']>
}

export type UpSpecPropertyOptionInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  key?: InputMaybe<Scalars['String']['input']>
}

export type UpSpecPropertyTerm = {
  __typename?: 'UpSpecPropertyTerm'
  displayName?: Maybe<Scalars['String']['output']>
  key: Scalars['String']['output']
}

export type UpSpecPropertyTermInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  key: Scalars['String']['input']
}

export enum UpSpecPropertyType {
  Array = 'array',
  Boolean = 'boolean',
  Number = 'number',
  Object = 'object',
  String = 'string',
}

export type UpSpecification = UpDataset & {
  __typename?: 'UpSpecification'
  assetDisplayName?: Maybe<Scalars['String']['output']>
  assetProfile?: Maybe<Scalars['ID']['output']>
  baseSpecification?: Maybe<Scalars['JSONObject']['output']>
  catalogId: Scalars['ID']['output']
  catalogRoleArn?: Maybe<Scalars['String']['output']>
  dataset: Scalars['ID']['output']
  displayName?: Maybe<Scalars['String']['output']>
  dynamicMetadata?: Maybe<Scalars['JSONObject']['output']>
  endpoint?: Maybe<Scalars['String']['output']>
  /** Backwards compatibility */
  hints?: Maybe<Scalars['JSONObject']['output']>
  identityProperty: Scalars['String']['output']
  isInternal: Scalars['Boolean']['output']
  isProvisioned: Scalars['Boolean']['output']
  metadataSource?: Maybe<Scalars['String']['output']>
  metadataVersion?: Maybe<Scalars['String']['output']>
  properties?: Maybe<Array<UpSpecPropertyDefinition>>
  specificationParameters?: Maybe<Array<UpSpecificationParameter>>
  timeliness?: Maybe<UpSpecificationTimeliness>
}

export type UpSpecificationConnection = {
  __typename?: 'UpSpecificationConnection'
  edges?: Maybe<Array<Maybe<UpSpecificationEdge>>>
  pageInfo: PageInfo
}

export type UpSpecificationEdge = {
  __typename?: 'UpSpecificationEdge'
  node?: Maybe<UpSpecification>
}

export type UpSpecificationMutationError = MutationError & {
  __typename?: 'UpSpecificationMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type UpSpecificationMutationResult = {
  __typename?: 'UpSpecificationMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<UpSpecificationMutationError>>
  upSpecification?: Maybe<UpSpecification>
}

export type UpSpecificationParameter = {
  __typename?: 'UpSpecificationParameter'
  displayName?: Maybe<Scalars['String']['output']>
  format?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  necessity?: Maybe<UpSpecPropertyNecessity>
  options?: Maybe<Array<UpSpecPropertyOption>>
  type?: Maybe<UpSpecificationParameterType>
}

export type UpSpecificationParameterInput = {
  displayName?: InputMaybe<Scalars['String']['input']>
  format?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  necessity?: InputMaybe<UpSpecPropertyNecessity>
  options?: InputMaybe<Array<UpSpecPropertyOptionInput>>
  type?: InputMaybe<UpSpecificationParameterType>
}

export enum UpSpecificationParameterType {
  Array = 'array',
  Boolean = 'boolean',
  Enum = 'enum',
  Number = 'number',
  Object = 'object',
  String = 'string',
}

export type UpSpecificationReferenceInput = {
  catalogId: Scalars['ID']['input']
  dataset: Scalars['String']['input']
}

export enum UpSpecificationTimeliness {
  Feature = 'feature',
  Historical = 'historical',
  Live = 'live',
}

export type UpSpecifications = {
  __typename?: 'UpSpecifications'
  all?: Maybe<UpSpecificationConnection>
  byId?: Maybe<UpSpecification>
  byReferences: Array<UpSpecification>
}

export type UpSpecificationsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type UpSpecificationsByIdArgs = {
  catalogId: Scalars['ID']['input']
  dataset: Scalars['String']['input']
}

export type UpSpecificationsByReferencesArgs = {
  upSpecificationReferences: Array<UpSpecificationReferenceInput>
}

export type UpdateAssetComponentInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  componentId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parent?: InputMaybe<ParentInput>
  siteId?: InputMaybe<Scalars['ID']['input']>
  upsert?: Scalars['Boolean']['input']
}

export type UpdateAssetEquipmentInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  equipmentId: Scalars['ID']['input']
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parent?: InputMaybe<ParentInput>
  siteId?: InputMaybe<Scalars['ID']['input']>
  upsert?: Scalars['Boolean']['input']
}

export type UpdateAssetGeometryInput = {
  assetId: Scalars['ID']['input']
  assetTypeId: Scalars['ID']['input']
  geometry?: InputMaybe<GeoJsonGeometryInput>
}

export type UpdateAssetInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  assetId: Scalars['ID']['input']
  assetTypeId: Scalars['ID']['input']
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parent?: InputMaybe<ParentInput>
  parentAssetReference?: InputMaybe<Scalars['ID']['input']>
  propertyValues?: InputMaybe<Array<CamAssetPropertyValueInput>>
  propertyValuesJson?: InputMaybe<Scalars['JSONObject']['input']>
  upsert?: Scalars['Boolean']['input']
}

export type UpdateAssetPipelineInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parent?: InputMaybe<ParentInput>
  pipelineId: Scalars['ID']['input']
  upsert?: Scalars['Boolean']['input']
}

export type UpdateAssetProfileInput = {
  displayNameTemplate?: InputMaybe<Scalars['String']['input']>
  profile: Scalars['ID']['input']
  viewConfigurations?: InputMaybe<
    Array<InputMaybe<AssetProfileViewConfigurationInput>>
  >
}

export type UpdateAssetProfileViewConfigurationInput = {
  id: Scalars['ID']['input']
  layouts?: InputMaybe<Array<AssetProfileViewLayoutInput>>
  map?: InputMaybe<AssetProfileViewMapInput>
  name?: InputMaybe<Scalars['String']['input']>
  profile: Scalars['ID']['input']
}

export type UpdateAssetProfileViewLayoutInput = {
  id: Scalars['ID']['input']
  mediaType: AssetProfileViewLayoutMediaType
  profile: Scalars['ID']['input']
  widgets: Array<AssetProfileViewWidgetInput>
}

export type UpdateAssetPropertyInput = {
  assetId: Scalars['ID']['input']
  assetTypeId: Scalars['ID']['input']
  property: Scalars['ID']['input']
  valueJson?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateAssetSiteInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parent?: InputMaybe<ParentInput>
  propertyValues?: InputMaybe<Array<CamAssetPropertyValueInput>>
  propertyValuesJson?: InputMaybe<Scalars['JSONObject']['input']>
  siteId: Scalars['ID']['input']
  upsert?: Scalars['Boolean']['input']
}

export type UpdateAssetTypeInput = {
  assetTypeId: Scalars['ID']['input']
  group?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  resetDraft?: InputMaybe<Scalars['Boolean']['input']>
  resetDraftProperty?: InputMaybe<Scalars['ID']['input']>
  withExtensions?: InputMaybe<Array<AssetTypeExtensionInput>>
  withProperties?: InputMaybe<Array<AssetPropertyDefinitionInput>>
}

export type UpdateAssetWellInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parent?: InputMaybe<ParentInput>
  siteId?: InputMaybe<Scalars['ID']['input']>
  upsert?: Scalars['Boolean']['input']
  wellId: Scalars['ID']['input']
}

export type UpdateBackendComponentsInput = {
  withBackendComponents?: InputMaybe<Array<Scalars['ID']['input']>>
  withoutBackendComponents?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type UpdateClueInput = {
  data?: InputMaybe<Scalars['JSONObject']['input']>
  detectionId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  type?: InputMaybe<ClueType>
  upsert?: Scalars['Boolean']['input']
}

export type UpdateDetectionInput = {
  additionalData?: InputMaybe<Scalars['JSONObject']['input']>
  assetReference?: InputMaybe<Scalars['ID']['input']>
  detectedAt?: InputMaybe<Scalars['DateTime']['input']>
  detectionId: Scalars['ID']['input']
  detectionSource?: InputMaybe<Scalars['String']['input']>
  detectionType?: InputMaybe<DetectionType>
  emissionsRate?: InputMaybe<Scalars['Float']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  equipmentComponentName?: InputMaybe<Scalars['String']['input']>
  equipmentExplanation?: InputMaybe<Scalars['String']['input']>
  equipmentParentId?: InputMaybe<Scalars['ID']['input']>
  /** Currently only falseAlarm: true is supported */
  falseAlarm?: InputMaybe<Scalars['Boolean']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  measurementType?: InputMaybe<MeasurementType>
  /** S3 references for downloadable media. E.g. s3://bucket-name/kairos/media/2023/08/28/p-55286-es-100005918-job-1493.jpg */
  s3MediaUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  screeningId?: InputMaybe<Scalars['String']['input']>
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  upsert?: Scalars['Boolean']['input']
}

export type UpdateEmissionAlarmInput = {
  contributingEmissionObservations: Array<ContributingEmissionObservation>
  externalId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
}

export type UpdateEmissionEventFalseAlarmStatusInput = {
  emissionEventId: Scalars['ID']['input']
  explanation?: InputMaybe<Scalars['String']['input']>
  isFalseAlarm: Scalars['Boolean']['input']
}

export type UpdateEmissionEventStatusInput = {
  action: EmissionEventAction
  emissionEventId: Scalars['ID']['input']
  explanation?: InputMaybe<Scalars['String']['input']>
}

export type UpdateFormListInput = {
  /**
   * **description** is the description of the form list to be updated if provided.
   *
   * **Note**:
   *
   * 1. **description** cannot be longer than 4096 characters if provided.
   */
  description?: InputMaybe<Scalars['String']['input']>
  /**
   * **id** is the identification of the form list to be updated. For example, "game-of-thrones-characters" and "breaking-bad-characters".
   *
   * **Note**:
   *
   * 1. **id** must be the same as the one of a certain existing form list.
   */
  id: Scalars['ID']['input']
  /** **items** is the new items of the form list to be updated if provided. */
  items?: InputMaybe<Array<FormListItemInput>>
  /**
   * **name** is the new name of the form list to be updated if provided.
   *
   * **Note**:
   *
   * 1. **name** must be a string less than 4096 chars.
   */
  name?: InputMaybe<Scalars['String']['input']>
}

/**
 * Update group information.
 *
 * Only label is supported as other aspects of the groups are deployed via the suGroup terraform module.
 */
export type UpdateGroupInput = {
  label?: InputMaybe<Scalars['String']['input']>
  name: Scalars['ID']['input']
  parentGroup?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateHistoricalDetectionInput = {
  additionalData?: InputMaybe<Scalars['JSONObject']['input']>
  assetReference?: InputMaybe<Scalars['ID']['input']>
  detectedAt?: InputMaybe<Scalars['DateTime']['input']>
  detectionId: Scalars['ID']['input']
  detectionSource?: InputMaybe<Scalars['String']['input']>
  detectionType?: InputMaybe<DetectionType>
  emissionsRate?: InputMaybe<Scalars['Float']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  equipmentComponentName?: InputMaybe<Scalars['String']['input']>
  equipmentExplanation?: InputMaybe<Scalars['String']['input']>
  equipmentParentId?: InputMaybe<Scalars['ID']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  measurementType?: InputMaybe<MeasurementType>
  resolutionExplanation?: InputMaybe<Scalars['String']['input']>
  /** S3 references for downloadable media. E.g. s3://bucket-name/kairos/media/2023/08/28/p-55286-es-100005918-job-1493.jpg */
  s3MediaUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  screeningId?: InputMaybe<Scalars['String']['input']>
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  upsert?: Scalars['Boolean']['input']
}

export type UpdateInspectionRequestInput = {
  /** ID of the corresponding inspection request in the external source */
  externalReferenceId?: InputMaybe<Scalars['String']['input']>
  /** Name of the external source containing a corresponding inspection request */
  externalSourceName?: InputMaybe<InspectionRequestExternalSourceName>
  id: Scalars['ID']['input']
  /** The new status of the inspection request */
  status?: InputMaybe<InspectionStatus>
  /** Time of the update */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateInspectionRequestStatusInput = {
  detectionId: Scalars['ID']['input']
  /** The new status */
  status: InspectionStatus
  /** Time of the status change */
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateIssueInput = {
  commentWith?: InputMaybe<Scalars['String']['input']>
  deleted?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<Scalars['DateTime']['input']>
  issueId: Scalars['ID']['input']
  severity?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  watchers?: InputMaybe<Array<TriggerIssueWatcherInput>>
}

export type UpdateIssueStateDataInput = {
  dataCollectionFormComplete?: InputMaybe<Scalars['Boolean']['input']>
  dataCollectionFormResult?: InputMaybe<Scalars['XFormResult']['input']>
  issueId: Scalars['ID']['input']
  subTasksComplete?: InputMaybe<Scalars['Boolean']['input']>
  subTasksIds?: InputMaybe<Array<Scalars['ID']['input']>>
  subTasksLastModifiedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateIssueTaskDataCollectionFormInput = {
  cloneFormResponsesOfFormId?: InputMaybe<Scalars['ID']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  id: Scalars['XFormReference']['input']
  jsonFormBody?: InputMaybe<JsonFormBodyInput>
  /** The group can be set so that super admins can define which group the Form belongs to. The username is ignored until the system supports creating entities owned by other users. */
  owner?: InputMaybe<EntityOwnerInput>
  subjectAssetProfile?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type UpdateMapInput = {
  id: Scalars['ID']['input']
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>
  layers?: InputMaybe<Array<Scalars['JSONObject']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  owner?: InputMaybe<EntityOwnerInput>
  selectedDateTimeRange?: InputMaybe<DateTimeRangeInput>
  spatialFilterEnabled?: InputMaybe<Scalars['Boolean']['input']>
  style?: InputMaybe<Scalars['String']['input']>
  /** During create or update, the client pass the content of thumbnail, and for read the server return the S3 url for that content */
  thumbnailContent?: InputMaybe<Scalars['String']['input']>
  timelineIntervalUnit?: InputMaybe<Scalars['String']['input']>
  viewState?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateNoDetectionInput = {
  additionalData?: InputMaybe<Scalars['JSONObject']['input']>
  assetReference?: InputMaybe<Scalars['ID']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  noDetectionId: Scalars['ID']['input']
  noDetectionTime: Scalars['DateTime']['input']
  noDetectionType: NoDetectionType
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateReconciledEmissionInput = {
  bottomUpKilograms?: InputMaybe<Scalars['Float']['input']>
  bottomUpPortionKilograms?: InputMaybe<Scalars['Float']['input']>
  cmsDetections?: InputMaybe<Array<DetectionInput>>
  cmsKilograms?: InputMaybe<Scalars['Float']['input']>
  /** PK#2 */
  day: Scalars['Date']['input']
  decisions?: InputMaybe<Array<InputMaybe<DecisionInput>>>
  emissionKilograms?: InputMaybe<Scalars['Float']['input']>
  equipmentId?: InputMaybe<Scalars['ID']['input']>
  equipmentKnownEventsKilograms?: InputMaybe<Scalars['Float']['input']>
  equipmentTopDownKilograms?: InputMaybe<Scalars['Float']['input']>
  knownEventIds?: InputMaybe<Array<Scalars['ID']['input']>>
  knownEventsKilograms?: InputMaybe<Scalars['Float']['input']>
  knownEventsPortionKilograms?: InputMaybe<Scalars['Float']['input']>
  manualEmissionKilograms?: InputMaybe<Scalars['Float']['input']>
  measuredKilograms?: InputMaybe<Scalars['Float']['input']>
  measurementDesignation?: InputMaybe<MeasurementDesignation>
  ogiDetections?: InputMaybe<Array<DetectionInput>>
  ogiKilograms?: InputMaybe<Scalars['Float']['input']>
  reconciledEmissionKilograms?: InputMaybe<Scalars['Float']['input']>
  siteAllocatedKnownEventsKilograms?: InputMaybe<Scalars['Float']['input']>
  siteAllocatedTopDownKilograms?: InputMaybe<Scalars['Float']['input']>
  /** PK#1 */
  siteId: Scalars['ID']['input']
  siteRemainderKilograms?: InputMaybe<Scalars['Float']['input']>
  siteUnallocatedKnownEventsKilograms?: InputMaybe<Scalars['Float']['input']>
  siteUnallocatedTopDownKilograms?: InputMaybe<Scalars['Float']['input']>
  timeAdjustedDetections?: InputMaybe<Array<DetectionInput>>
  timeAdjustedKilograms?: InputMaybe<Scalars['Float']['input']>
  topDownPortionKilograms?: InputMaybe<Scalars['Float']['input']>
  unallocatedCmsDetections?: InputMaybe<Array<DetectionInput>>
  unallocatedCmsKilograms?: InputMaybe<Scalars['Float']['input']>
  unallocatedKnownEventIds?: InputMaybe<Array<Scalars['ID']['input']>>
  unallocatedOgiDetections?: InputMaybe<Array<DetectionInput>>
  unallocatedOgiKilograms?: InputMaybe<Scalars['Float']['input']>
  unallocatedTimeAdjustedDetections?: InputMaybe<Array<DetectionInput>>
  unallocatedTimeAdjustedKilograms?: InputMaybe<Scalars['Float']['input']>
  /** false is not supported..should throw an error */
  upsert?: Scalars['Boolean']['input']
  /** PK#3 VM-FLOC or VM-E-FLOC */
  virtualMeterId: Scalars['ID']['input']
}

export type UpdateReconciledEmissionResult = {
  __typename?: 'UpdateReconciledEmissionResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<UpdateReconciledEmissionResultError>>
}

export type UpdateReconciledEmissionResultError = MutationError & {
  __typename?: 'UpdateReconciledEmissionResultError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type UpdateScope2EmissionInput = {
  emissionUnit?: InputMaybe<EmissionUnit>
  emissionValue: Scalars['Float']['input']
  month: Scalars['Date']['input']
  siteId: Scalars['ID']['input']
}

export type UpdateScope2EmissionMutationError = MutationError & {
  __typename?: 'UpdateScope2EmissionMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type UpdateScope2EmissionResult = {
  __typename?: 'UpdateScope2EmissionResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<UpdateScope2EmissionMutationError>>
  scope2Emission?: Maybe<Scope2Emission>
}

export type UpdateSiteBeaconInput = {
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id: Scalars['ID']['input']
  properties?: InputMaybe<UpdateSiteBeaconPropertiesInput>
}

export type UpdateSiteBeaconPropertiesInput = {
  UUID?: InputMaybe<Scalars['String']['input']>
  active?: InputMaybe<Scalars['Boolean']['input']>
  brand?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  deviceId?: InputMaybe<Scalars['String']['input']>
  macAddress?: InputMaybe<Scalars['String']['input']>
  major?: InputMaybe<Scalars['Int']['input']>
  minor?: InputMaybe<Scalars['Int']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  sensitivity?: InputMaybe<Scalars['Float']['input']>
  /** UserProperties: user arbitrary properties and has to the complete userProperties object */
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateSiteBuildingInput = {
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id: Scalars['ID']['input']
  properties?: InputMaybe<UpdateSiteBuildingPropertiesInput>
}

export type UpdateSiteBuildingPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** UserProperties: user arbitrary properties and has to the complete userProperties object */
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateSiteInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>
  geometry?: InputMaybe<GeoJsonGeometryInput>
  group?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  properties?: InputMaybe<UpdateSitePropertiesInput>
  thumbnail?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
}

export type UpdateSiteLevelInput = {
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id: Scalars['ID']['input']
  properties?: InputMaybe<UpdateSiteLevelPropertiesInput>
}

export type UpdateSiteLevelPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  floorHeight?: InputMaybe<Scalars['Float']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  ordinal?: InputMaybe<Scalars['Int']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateSiteLocationInput = {
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id: Scalars['ID']['input']
  properties?: InputMaybe<UpdateSiteLocationPropertiesInput>
}

export type UpdateSiteLocationPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  department?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** UserProperties: user arbitrary properties and has to the complete userProperties object */
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateSitePathwayInput = {
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id: Scalars['ID']['input']
  properties?: InputMaybe<UpdateSitePathwayPropertiesInput>
}

export type UpdateSitePathwayPropertiesInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  /** UserProperties: user arbitrary properties and has to the complete userProperties object */
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateSitePropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** UserProperties: user arbitrary properties and has to the complete userProperties object */
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateSiteUnitInput = {
  geometry?: InputMaybe<GeoJsonGeometryInput>
  id: Scalars['ID']['input']
  properties?: InputMaybe<UpdateSiteUnitPropertiesInput>
}

export type UpdateSiteUnitPropertiesInput = {
  category?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  height?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  parentId?: InputMaybe<Scalars['ID']['input']>
  userProperties?: InputMaybe<Scalars['JSONObject']['input']>
}

export type UpdateUserConsentInput = {
  application: Scalars['String']['input']
  /** A reference to the material or document that the User has given their consent to */
  subjects: Array<Scalars['String']['input']>
}

/** Update the fields provided in this input. */
export type UpdateUserInput = {
  avatar?: InputMaybe<Scalars['String']['input']>
  clearAvatar?: InputMaybe<Scalars['Boolean']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>
  enable?: InputMaybe<Scalars['Boolean']['input']>
  familyName?: InputMaybe<Scalars['String']['input']>
  givenName?: InputMaybe<Scalars['String']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  phoneVerified?: InputMaybe<Scalars['Boolean']['input']>
  preferences?: InputMaybe<Array<UpdateUserPreferenceInput>>
  resetMfa?: InputMaybe<Scalars['Boolean']['input']>
  resetPassword?: InputMaybe<Scalars['Boolean']['input']>
  role?: InputMaybe<UserRole>
  timezone?: InputMaybe<Scalars['String']['input']>
  username: Scalars['ID']['input']
}

export type UpdateUserPreferenceInput = {
  preference: Scalars['ID']['input']
  username: Scalars['ID']['input']
  value: Scalars['JSONObject']['input']
}

export type UpdateUserRegisterNotificationTokenInput = {
  notificationToken: Scalars['String']['input']
}

export type UpdateVentingEventError = MutationError & {
  __typename?: 'UpdateVentingEventError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type UpdateVentingEventInput = {
  additionalData?: InputMaybe<Scalars['JSONObject']['input']>
  assetReference?: InputMaybe<Scalars['ID']['input']>
  blowdown?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  detectedAt?: InputMaybe<Scalars['DateTime']['input']>
  emissionsRate?: InputMaybe<Scalars['Float']['input']>
  endTime?: InputMaybe<Scalars['DateTime']['input']>
  equipmentParentId?: InputMaybe<Scalars['ID']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  measurementType?: InputMaybe<MeasurementType>
  ongoingStatus?: InputMaybe<Scalars['Boolean']['input']>
  requiredFieldsMissing?: InputMaybe<Array<Scalars['String']['input']>>
  siteId?: InputMaybe<Scalars['ID']['input']>
  sourceAssetReference?: InputMaybe<Scalars['ID']['input']>
  sourceLatitude?: InputMaybe<Scalars['Float']['input']>
  sourceLongitude?: InputMaybe<Scalars['Float']['input']>
  startTime?: InputMaybe<Scalars['DateTime']['input']>
  type?: InputMaybe<VentingEventType>
  upsert?: Scalars['Boolean']['input']
  ventingId: Scalars['ID']['input']
}

export type UpdateVentingEventResult = {
  __typename?: 'UpdateVentingEventResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<UpdateVentingEventError>>
}

export type UpdateWorkflowInput = {
  description?: InputMaybe<Scalars['String']['input']>
  diagram?: InputMaybe<Scalars['JSONObject']['input']>
  group?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  isInternal?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  username?: InputMaybe<Scalars['String']['input']>
  widgets?: InputMaybe<Array<WorkflowWidgetInput>>
}

export type UpsertRolledUpReconciledEmissionsInput = {
  assetReference: Scalars['ID']['input']
  bySourceCategory?: InputMaybe<Array<SourceCategoryEmissionsInput>>
  group: Scalars['String']['input']
  methodology: EmissionSimulationMethodology
  reconciliationSpan: Scalars['String']['input']
  siteMeasuredEmission?: InputMaybe<EmissionInput>
  siteSimulatedEmission?: InputMaybe<EmissionInput>
  sourceBottomUpEmission?: InputMaybe<EmissionInput>
  sourceMeasuredEmission?: InputMaybe<EmissionInput>
}

export type UpsertRolledUpReconciledEmissionsResult = {
  __typename?: 'UpsertRolledUpReconciledEmissionsResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<UpsertRolledUpReconciledEmissionsResultError>>
}

export type UpsertRolledUpReconciledEmissionsResultError = MutationError & {
  __typename?: 'UpsertRolledUpReconciledEmissionsResultError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type User = {
  __typename?: 'User'
  audit?: Maybe<Audit>
  avatarUrl?: Maybe<Scalars['String']['output']>
  caslRules?: Maybe<Scalars['JSONObject']['output']>
  consents: Array<UserConsent>
  defaultIssueFilter?: Maybe<IssueFilterPreset>
  devices?: Maybe<Array<AuthDevice>>
  email: Scalars['String']['output']
  emailVerified: Scalars['Boolean']['output']
  enabled: Scalars['Boolean']['output']
  familyName: Scalars['String']['output']
  givenName: Scalars['String']['output']
  group: Scalars['String']['output']
  /** Describe if the User has consented to documents for a specific application or not */
  hasConsent: Scalars['Boolean']['output']
  hasLoginCredentials: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  initials: Scalars['String']['output']
  mfaPreferred?: Maybe<UserMfaSetting>
  mfaRequired: Scalars['Boolean']['output']
  mfaSettingsEnabled?: Maybe<Array<UserMfaSetting>>
  /** Full name calculated in the backend from the parts of the name */
  name: Scalars['String']['output']
  notificationEndpointArn?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  phoneVerified: Scalars['Boolean']['output']
  preferences?: Maybe<Array<UserPreference>>
  role: UserRole
  timezone?: Maybe<Scalars['String']['output']>
  type: Scalars['String']['output']
  userGroup: UserGroup
  username: Scalars['ID']['output']
}

export type UserConsentsArgs = {
  application?: InputMaybe<Scalars['String']['input']>
}

export type UserHasConsentArgs = {
  application?: InputMaybe<Scalars['String']['input']>
}

/** @deprecated in favour of IssueResolutionContext extended from su-backend / users */
export type UserActionResolutionContext = ResolutionContext & {
  __typename?: 'UserActionResolutionContext'
  data?: Maybe<Scalars['JSONObject']['output']>
  reference: Scalars['ID']['output']
  type: ResolutionContextType
  userReference?: Maybe<UserReference>
}

/** @deprecated in favour of IssueTriggerContext extended from su-backend */
export type UserActionTriggerContext = TriggerContext & {
  __typename?: 'UserActionTriggerContext'
  data?: Maybe<Scalars['JSONObject']['output']>
  reference: Scalars['ID']['output']
  type: TriggerContextType
  userReference?: Maybe<UserReference>
}

/** Describes the User's acceptance of some condition necessary to use features of the product or platform */
export type UserConsent = {
  __typename?: 'UserConsent'
  /** The application that this consent is related to (explorer, sensorhub, sensorhub-wearable/watch) */
  application: Scalars['ID']['output']
  /** A human readable description of what was consented to */
  description?: Maybe<Scalars['String']['output']>
  givenAt: Scalars['DateTime']['output']
  /** A reference to the material or document that the User has given their consent to (privacy, T&C, etc.) */
  subject: Scalars['ID']['output']
  /** Version of the document that the User has given their consent to */
  version: Scalars['String']['output']
}

/** Describes the condition necessary to use features of the product or platform */
export type UserConsentDocument = {
  __typename?: 'UserConsentDocument'
  /** The applications that this document applies to (explorer, sensorhub, sensorhub-wearable/watch) */
  applications: Array<Scalars['String']['output']>
  content?: Maybe<Scalars['String']['output']>
  /** S3 signed URL for accessing the document content in MD format */
  contentUrl: Scalars['String']['output']
  /** A human readable summary description of the document */
  description?: Maybe<Scalars['String']['output']>
  /** Describe if the current User needs to give their consent for this document on a specific application or not */
  needMeConsent?: Maybe<Scalars['Boolean']['output']>
  /** The subject of the the document (privacy, T&C, etc.) */
  subject: Scalars['ID']['output']
  /** The version of the document (retrieved from S3 bucket version) */
  version: Scalars['String']['output']
}

/** Describes the condition necessary to use features of the product or platform */
export type UserConsentDocumentNeedMeConsentArgs = {
  application: Scalars['String']['input']
}

export type UserEdge = {
  __typename?: 'UserEdge'
  node?: Maybe<User>
}

export type UserFilter = {
  group?: InputMaybe<Array<Scalars['String']['input']>>
  username?: InputMaybe<Array<Scalars['String']['input']>>
}

export type UserGroup = {
  __typename?: 'UserGroup'
  hierarchy?: Maybe<UserGroupHierarchy>
  label: Scalars['String']['output']
  name: Scalars['String']['output']
  parent?: Maybe<UserGroup>
  parentGroup?: Maybe<Scalars['ID']['output']>
  preferredRole: Scalars['String']['output']
  /** Retrieve a session token to switch to this groups iam role */
  upAuthSuSession?: Maybe<Scalars['JSONObject']['output']>
}

export type UserGroupHierarchy = {
  __typename?: 'UserGroupHierarchy'
  children: Array<UserGroup>
  parent?: Maybe<UserGroup>
  parents: Array<UserGroup>
  subTree: Array<UserGroup>
}

export type UserGroups = {
  __typename?: 'UserGroups'
  all: Array<UserGroup>
  byName?: Maybe<UserGroup>
}

export type UserGroupsByNameArgs = {
  name: Scalars['String']['input']
}

export type UserInput = {
  property?: InputMaybe<Scalars['String']['input']>
  unionType: UserUnionType
  userId?: InputMaybe<Scalars['ID']['input']>
}

export enum UserMfaSetting {
  Nomfa = 'NOMFA',
  SmsMfa = 'SMS_MFA',
  SoftwareTokenMfa = 'SOFTWARE_TOKEN_MFA',
}

export type UserMutationError = MutationError & {
  __typename?: 'UserMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type UserMutationResult = {
  __typename?: 'UserMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<UserMutationError>>
  user?: Maybe<User>
}

export type UserOperand = PropertyOperand | UserReferenceOperand

export type UserPreference = {
  __typename?: 'UserPreference'
  preference: Scalars['ID']['output']
  value: Scalars['JSONObject']['output']
}

/** Supported preference id values @TODO use in UserPreference.preference and UserPreferenceInput: preference */
export enum UserPreferenceId {
  EnableTimezone = 'enableTimezone',
  FavoriteFormLists = 'favoriteFormLists',
  FavoriteMaps = 'favoriteMaps',
  Filters = 'filters',
  GalleryOptions = 'galleryOptions',
  SidebarRoutes = 'sidebarRoutes',
}

/** UserReference is a copy of a shared implementation across schema and components */
export type UserReference = {
  __typename?: 'UserReference'
  user?: Maybe<User>
  userId: Scalars['ID']['output']
}

export type UserReferenceOperand = {
  __typename?: 'UserReferenceOperand'
  userReference?: Maybe<UserReference>
}

/**  Role of the user */
export enum UserRole {
  Admin = 'admin',
  Groupadmin = 'groupadmin',
  User = 'user',
}

export enum UserUnionType {
  Property = 'PROPERTY',
  UserId = 'USER_ID',
}

export type Users = {
  __typename?: 'Users'
  all?: Maybe<UsersConnection>
  byId?: Maybe<User>
}

export type UsersAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<UserFilter>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type UsersByIdArgs = {
  id: Scalars['ID']['input']
}

export type UsersConnection = {
  __typename?: 'UsersConnection'
  edges?: Maybe<Array<Maybe<UserEdge>>>
  pageInfo: PageInfo
}

export type VentingEvent = EmissionObservation & {
  __typename?: 'VentingEvent'
  acknowledged: Scalars['Boolean']['output']
  additionalData?: Maybe<Scalars['JSONObject']['output']>
  audit: EmissionObservationAudit
  blowdown?: Maybe<Scalars['Boolean']['output']>
  camAssetReference?: Maybe<CamAssetReference>
  camSourceAssetReference?: Maybe<CamAssetReference>
  closed: Scalars['Boolean']['output']
  complianceDeadline?: Maybe<Scalars['DateTime']['output']>
  complianceSeverity?: Maybe<ComplianceSeverityType>
  correlatedEmissionObservationCount: Scalars['Int']['output']
  correlatedEmissionObservations?: Maybe<Array<Maybe<EmissionObservation>>>
  description?: Maybe<Scalars['String']['output']>
  detectedAt: Scalars['DateTime']['output']
  emissionEventId?: Maybe<Scalars['ID']['output']>
  emissionsRate?: Maybe<EmissionObservationEmissionRateValue>
  endTime?: Maybe<Scalars['DateTime']['output']>
  equipmentExplanation?: Maybe<Scalars['String']['output']>
  equipmentParentId?: Maybe<Scalars['ID']['output']>
  /** @deprecated Use camSourceAssetReference instead */
  equipmentReference?: Maybe<EquipmentReference>
  group?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  inputType: EmissionObservationInputType
  issues?: Maybe<Array<IssueReference>>
  lastComplianceActionAt?: Maybe<Scalars['DateTime']['output']>
  measurementType?: Maybe<MeasurementType>
  ongoingStatus?: Maybe<Scalars['Boolean']['output']>
  /** @deprecated Removing Priority Feature */
  priority?: Maybe<EmissionObservationPriority>
  procedure?: Maybe<Procedure>
  requiredFieldsMissing?: Maybe<Array<Scalars['String']['output']>>
  shortId?: Maybe<Scalars['ID']['output']>
  siteMonthReference?: Maybe<SiteMonthReference>
  /** @deprecated Use camAssetReference instead */
  siteReference?: Maybe<SiteReference>
  sourceAttribution?: Maybe<SourceAttribution>
  sourceLatitude?: Maybe<Scalars['Float']['output']>
  sourceLongitude?: Maybe<Scalars['Float']['output']>
  startTime?: Maybe<Scalars['DateTime']['output']>
  uncertainty?: Maybe<Scalars['Float']['output']>
  ventingEventType?: Maybe<VentingEventType>
  wip: Scalars['Boolean']['output']
}

export type VentingEventMutationError = {
  __typename?: 'VentingEventMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type VentingEventMutationResult = {
  __typename?: 'VentingEventMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<VentingEventMutationError>>
  ventingEvent?: Maybe<VentingEvent>
}

export enum VentingEventType {
  Flaring = 'FLARING',
  Venting = 'VENTING',
}

export enum WeekDay {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type WipDetectionFilter = {
  acknowledged?: InputMaybe<Scalars['Boolean']['input']>
  assetFilters?: InputMaybe<EmissionAssetFilter>
  assetReference?: InputMaybe<Array<Scalars['ID']['input']>>
  closed?: InputMaybe<Scalars['Boolean']['input']>
  customFilters?: InputMaybe<Array<EmissionCustomFilter>>
  detectedAfter?: InputMaybe<Scalars['DateTime']['input']>
  detectedBefore?: InputMaybe<Scalars['DateTime']['input']>
  detectionType?: InputMaybe<Array<DetectionType>>
  group?: InputMaybe<Array<Scalars['String']['input']>>
  inputType?: InputMaybe<Array<EmissionObservationInputType>>
  orFilter?: InputMaybe<WipDetectionFilter>
  screeningId?: InputMaybe<Array<Scalars['String']['input']>>
  shortId?: InputMaybe<Array<Scalars['ID']['input']>>
  siteId?: InputMaybe<Array<Scalars['ID']['input']>>
  siteName?: InputMaybe<Array<Scalars['String']['input']>>
  sourceAssetReference?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** @todo determine whether ownedByRole: RoleArn will still be needed in this type */
export type Workflow = {
  __typename?: 'Workflow'
  activated?: Maybe<WorkflowEventAudit>
  audit: Audit
  deactivated?: Maybe<WorkflowEventAudit>
  debugDag: WorkflowNodeDag
  /** @deprecated This is here until we have a better way to expose it */
  debugProvision: Scalars['JSONObject']['output']
  description?: Maybe<Scalars['String']['output']>
  diagram?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  isInternal: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  owner: EntityOwner
  /** @deprecated No longer supported */
  type: Scalars['String']['output']
  widgets?: Maybe<Array<WorkflowWidget>>
}

/** @todo determine whether ownedByRole: RoleArn will still be needed in this type */
export type WorkflowWidgetsArgs = {
  widgetId?: InputMaybe<Scalars['ID']['input']>
}

export type WorkflowDagEdge = {
  __typename?: 'WorkflowDagEdge'
  fromNodeId: Scalars['ID']['output']
  toNodeId: Scalars['ID']['output']
}

export type WorkflowDagEdgeInput = {
  fromNodeId: Scalars['ID']['input']
  toNodeId: Scalars['ID']['input']
}

export type WorkflowDagNode = {
  __typename?: 'WorkflowDagNode'
  details?: Maybe<Scalars['JSONObject']['output']>
  nodeId: Scalars['ID']['output']
  nodeType: Scalars['String']['output']
  provisionPlan?: Maybe<Scalars['JSONObject']['output']>
  state?: Maybe<WorkflowDagNodeState>
  toNodeIds?: Maybe<Array<Scalars['ID']['output']>>
  widgetId: Scalars['ID']['output']
}

export type WorkflowDagNodeStateArgs = {
  assetReference?: InputMaybe<Scalars['ID']['input']>
  eventTime?: InputMaybe<Scalars['DateTime']['input']>
  executionId?: InputMaybe<Scalars['ID']['input']>
}

export type WorkflowDagNodeToNodeIdsArgs = {
  includeExternal?: InputMaybe<Scalars['Boolean']['input']>
}

export type WorkflowDagNodeInput = {
  details?: InputMaybe<Scalars['JSONObject']['input']>
  nodeId: Scalars['ID']['input']
  nodeType: Scalars['String']['input']
}

export type WorkflowDagNodeState = {
  __typename?: 'WorkflowDagNodeState'
  state?: Maybe<Scalars['JSONObject']['output']>
  stateKey?: Maybe<Scalars['String']['output']>
}

export type WorkflowDatasetQuery = {
  __typename?: 'WorkflowDatasetQuery'
  /** AssetProfile is only available for asset data source */
  assetProfile?: Maybe<Scalars['String']['output']>
  catalogId: Scalars['String']['output']
  dataset: Scalars['String']['output']
  /** The identityProperty may consistently be the constant 'name' */
  identityProperty?: Maybe<Scalars['String']['output']>
  specParams?: Maybe<Scalars['JSONObject']['output']>
}

export type WorkflowDatasetQueryInput = {
  assetProfile?: InputMaybe<Scalars['String']['input']>
  catalogId: Scalars['String']['input']
  dataset: Scalars['String']['input']
  /** The identityProperty may consistently be the constant 'name' */
  identityProperty?: InputMaybe<Scalars['String']['input']>
  specParams?: InputMaybe<Scalars['JSONObject']['input']>
}

export type WorkflowEdge = {
  __typename?: 'WorkflowEdge'
  node?: Maybe<Workflow>
}

export type WorkflowEventAudit = {
  __typename?: 'WorkflowEventAudit'
  eventBy?: Maybe<UserReference>
  eventTime: Scalars['DateTime']['output']
}

export type WorkflowExecutionEmitCount = {
  __typename?: 'WorkflowExecutionEmitCount'
  count: Scalars['Int']['output']
  /** Workflow widget ID of outgoing edge Widget */
  id: Scalars['ID']['output']
}

export type WorkflowFilter = {
  group?: InputMaybe<Array<Scalars['String']['input']>>
  /**
   * Filters workflows based on their `isActive` status. This filter is a list, and can have these values:
   *
   * - `null` - does not take into account the `isActive` status of the workflows, when filtering.
   * - `[true]` - only workflows where the `isActive` status is `true` are returned.
   * - `[false]` - only workflows where the `isActive` status is `false` are returned.
   * - `[true, false]` - workflows are returned regardless if the `isActive` status is `true` or `false`.
   * - `[]` - no workflows are returned since workflows either have an `isActive` status of `true` or `false`.
   */
  isActive?: InputMaybe<Array<Scalars['Boolean']['input']>>
  username?: InputMaybe<Array<Scalars['String']['input']>>
}

export type WorkflowMutationError = MutationError & {
  __typename?: 'WorkflowMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type WorkflowMutationResult = {
  __typename?: 'WorkflowMutationResult'
  correlationId: Scalars['ID']['output']
  errors?: Maybe<Array<WorkflowMutationError>>
  workflow?: Maybe<Workflow>
}

export type WorkflowNodeDag = {
  __typename?: 'WorkflowNodeDag'
  dagEdges?: Maybe<Array<WorkflowDagEdge>>
  dagNodes?: Maybe<Array<WorkflowDagNode>>
}

export type WorkflowNodeDagDagEdgesArgs = {
  includeExternal?: InputMaybe<Scalars['Boolean']['input']>
  widgetId?: InputMaybe<Scalars['ID']['input']>
}

export type WorkflowNodeDagDagNodesArgs = {
  widgetId?: InputMaybe<Scalars['ID']['input']>
}

export type WorkflowReference = {
  __typename?: 'WorkflowReference'
  id: Scalars['ID']['output']
  workflow?: Maybe<Workflow>
}

export enum WorkflowScheduleType {
  RepeatCustom = 'REPEAT_CUSTOM',
  RepeatDaily = 'REPEAT_DAILY',
  RepeatMonthly = 'REPEAT_MONTHLY',
  RepeatWeekly = 'REPEAT_WEEKLY',
  RepeatYearly = 'REPEAT_YEARLY',
}

export type WorkflowWidget = {
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export enum WorkflowWidgetAggregateFunction {
  /** Average or Mean */
  Avg = 'AVG',
  /** Maximum */
  Max = 'MAX',
  /** Minimum */
  Min = 'MIN',
  /** Standard Deviation (Not implemented) */
  Stddev = 'STDDEV',
  /** Variance (Not implemented) */
  Var = 'VAR',
}

export type WorkflowWidgetBooleanComparison = {
  __typename?: 'WorkflowWidgetBooleanComparison'
  leftOperand: BooleanOperand
  operator: WorkflowWidgetConditionFilterBooleanOperators
  rightOperand: BooleanOperand
}

export type WorkflowWidgetBooleanComparisonInput = {
  leftOperand: BooleanOperandInput
  operator: WorkflowWidgetConditionFilterBooleanOperators
  rightOperand: BooleanOperandInput
}

export enum WorkflowWidgetCategory {
  /** Produces a side effect. Outflow is currently undefined */
  Action = 'ACTION',
  /** Assist service developers with creating widgets not directly supported by the User Interface */
  Development = 'DEVELOPMENT',
  /** Adds more information to the observation without changing structure. */
  Enrich = 'ENRICH',
  /** Conditionally stops flow (based on a property in the observation?) */
  Filter = 'FILTER',
  /** Extra visual guides for widget connecting widgets */
  Flow = 'FLOW',
  /** Producer of observations */
  Source = 'SOURCE',
  /** Changes the structure of the observation. For example multiple JSONs to a CSV */
  Transform = 'TRANSFORM',
}

export type WorkflowWidgetChangeIssueSeverity = WorkflowWidget & {
  __typename?: 'WorkflowWidgetChangeIssueSeverity'
  annotateTemplate?: Maybe<Scalars['String']['output']>
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  onlyIfDecreasing?: Maybe<Scalars['Boolean']['output']>
  onlyIfIncreasing?: Maybe<Scalars['Boolean']['output']>
  severity: Scalars['Int']['output']
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetChangeIssueSeverityExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

/** Specialized properties for ChangeIssueSeverity widget */
export type WorkflowWidgetChangeIssueSeverityInput = {
  annotateTemplate?: InputMaybe<Scalars['String']['input']>
  onlyIfDecreasing?: InputMaybe<Scalars['Boolean']['input']>
  onlyIfIncreasing?: InputMaybe<Scalars['Boolean']['input']>
  severity: Scalars['Int']['input']
}

export type WorkflowWidgetCloseIssue = WorkflowWidget & {
  __typename?: 'WorkflowWidgetCloseIssue'
  annotateTemplate?: Maybe<Scalars['String']['output']>
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetCloseIssueExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

/** Specialized properties for CloseIssue widget */
export type WorkflowWidgetCloseIssueInput = {
  annotateTemplate?: InputMaybe<Scalars['String']['input']>
}

export type WorkflowWidgetComparison =
  | WorkflowWidgetBooleanComparison
  | WorkflowWidgetDateTimeComparison
  | WorkflowWidgetNumericComparison
  | WorkflowWidgetNumericRangeComparison
  | WorkflowWidgetStringComparison

export enum WorkflowWidgetComparisonUnionType {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Numeric = 'NUMERIC',
  NumericRange = 'NUMERIC_RANGE',
  String = 'STRING',
}

export type WorkflowWidgetCondition = WorkflowWidget & {
  __typename?: 'WorkflowWidgetCondition'
  category: WorkflowWidgetCategory
  comparison: WorkflowWidgetComparison
  complementComparison: Scalars['Boolean']['output']
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetConditionExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export enum WorkflowWidgetConditionFilterBooleanOperators {
  DoesNotEqual = 'DOES_NOT_EQUAL',
  Equals = 'EQUALS',
}

export enum WorkflowWidgetConditionFilterDateTimeOperators {
  IsBetween = 'IS_BETWEEN',
  /** Is between comparison with inclusive right operand */
  IsBetweenRi = 'IS_BETWEEN_RI',
}

export enum WorkflowWidgetConditionFilterNumericOperators {
  BecameGreaterThan = 'BECAME_GREATER_THAN',
  BecameLessThan = 'BECAME_LESS_THAN',
  DoesNotEqual = 'DOES_NOT_EQUAL',
  Equals = 'EQUALS',
  IsGreaterThan = 'IS_GREATER_THAN',
  IsGreaterThanOrEquals = 'IS_GREATER_THAN_OR_EQUALS',
  IsLessThan = 'IS_LESS_THAN',
  IsLessThanOrEquals = 'IS_LESS_THAN_OR_EQUALS',
}

export enum WorkflowWidgetConditionFilterNumericRangeOperators {
  IsBetween = 'IS_BETWEEN',
}

export enum WorkflowWidgetConditionFilterStringOperators {
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  DoesNotEqual = 'DOES_NOT_EQUAL',
  Equals = 'EQUALS',
}

export enum WorkflowWidgetConditionGeofenceOperators {
  Entered = 'ENTERED',
  Exited = 'EXITED',
  Inside = 'INSIDE',
  Outside = 'OUTSIDE',
}

/** Specialized properties for Condition widget */
export type WorkflowWidgetConditionInput = {
  booleanComparison?: InputMaybe<WorkflowWidgetBooleanComparisonInput>
  comparisonUnionType: WorkflowWidgetComparisonUnionType
  complementComparison?: InputMaybe<Scalars['Boolean']['input']>
  datetimeComparison?: InputMaybe<WorkflowWidgetDateTimeComparisonInput>
  datetimeComparisonEditor?: InputMaybe<WorkflowWidgetDateTimeComparisonEditorInput>
  numericComparison?: InputMaybe<WorkflowWidgetNumericComparisonInput>
  numericRangeComparison?: InputMaybe<WorkflowWidgetNumericRangeComparisonInput>
  stringComparison?: InputMaybe<WorkflowWidgetStringComparisonInput>
}

export type WorkflowWidgetCustomNodeDag = WorkflowWidget & {
  __typename?: 'WorkflowWidgetCustomNodeDag'
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetCustomNodeDagExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetCustomNodeDagInput = {
  dagEdges?: InputMaybe<Array<WorkflowDagEdgeInput>>
  dagNodes?: InputMaybe<Array<WorkflowDagNodeInput>>
}

export type WorkflowWidgetDataCollection =
  | WorkflowWidgetMultitaskProcedureReference
  | WorkflowWidgetTaskDataCollectionFormReference

export type WorkflowWidgetDataCollectionInput = {
  inputType: WorkflowWidgetDataCollectionType
  issueType?: InputMaybe<WorkflowWidgetIssueType>
  multitaskProcedureId?: InputMaybe<Scalars['ID']['input']>
  taskDataCollectionFormId?: InputMaybe<Scalars['ID']['input']>
  taskDataCollectionJSONForm?: InputMaybe<TaskDataCollectionJsonFormInput>
  taskType?: InputMaybe<IssueTaskType>
}

export enum WorkflowWidgetDataCollectionType {
  Multitask = 'MULTITASK',
  Task = 'TASK',
  TaskJsonFormBody = 'TASK_JSON_FORM_BODY',
}

/** A datasource widget has */
export type WorkflowWidgetDataSource = WorkflowWidget & {
  __typename?: 'WorkflowWidgetDataSource'
  category: WorkflowWidgetCategory
  /** The definition of the details of the query */
  datasetQuery: WorkflowDatasetQuery
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  /** Determines how the other widgets interact with the result of the query */
  queryType: WorkflowWidgetDataSourceQueryType
  /** Is a parameter of the SCHEDULED queryType */
  schedule?: Maybe<WorkflowWidgetSchedule>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

/** A datasource widget has */
export type WorkflowWidgetDataSourceExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

/** Specialized properties for DataSource widget */
export type WorkflowWidgetDataSourceInput = {
  /** The definition of the details of the query */
  datasetQuery: WorkflowDatasetQueryInput
  /** Determines how the other widgets interact with the result of the query */
  queryType: WorkflowWidgetDataSourceQueryType
  /** Is a parameter of the SCHEDULED queryType */
  schedule?: InputMaybe<WorkflowWidgetScheduleInput>
}

export enum WorkflowWidgetDataSourceQueryType {
  Live = 'LIVE',
  Scheduled = 'SCHEDULED',
}

export type WorkflowWidgetDateTimeComparison = {
  __typename?: 'WorkflowWidgetDateTimeComparison'
  editor?: Maybe<WorkflowWidgetDateTimeComparisonEditor>
  leftOperand: DateTimeOperand
  leftOperandManipulations?: Maybe<Array<DateTimeManipulation>>
  operator: WorkflowWidgetConditionFilterDateTimeOperators
  rightOperandEnd: DateTimeOperand
  rightOperandEndManipulations?: Maybe<Array<DateTimeManipulation>>
  rightOperandStart: DateTimeOperand
  rightOperandStartManipulations?: Maybe<Array<DateTimeManipulation>>
}

/**
 * If the workflow trigger time is Oct 25 4:30pm and the company start of the day offset is -300 which means the company day starts at 5am UTC:
 * last 24 hours: 10/24/2022 4:30PM - 10/25/2022 4:30PM.
 * last 1 day:    10/24/2022 5:00AM - 10/25/2022 4:30PM.
 * last 2 days:   10/23/2022 5:00AM - 10/25/2022 4:30PM.
 * last 1 week:   10/17/2022 5:00AM - 10/25/2022 4:30PM.
 * last 2 weeks:  10/10/2022 5:00AM - 10/25/2022 4:30PM.
 * last 1 month:  09/01/2022 5:00AM - 10/25/2022 4:30PM.
 * last 2 months: 08/01/2022 5:00AM - 10/25/2022 4:30PM.
 * last 1 year:   01/01/2021 5:00AM - 10/25/2022 4:30PM.
 * last 2 year:   01/01/2020 5:00AM - 10/25/2022 4:30PM.
 * current day:   10/25/2022 5:00AM - 10/26/2022 5:00AM (inclusive start and exclusive end).
 * previous day:  10/24/2022 5:00AM - 10/25/2022 5:00AM (inclusive start and exclusive end).
 * current week:  10/24/2022 5:00AM - 10/31/2022 5:00AM (inclusive start and exclusive end).
 */
export type WorkflowWidgetDateTimeComparisonEditor = {
  __typename?: 'WorkflowWidgetDateTimeComparisonEditor'
  /** If the precision is DAY, for value use T00:00:00Z at the end of Date to make it ISO DateTime */
  afterDateTime?: Maybe<DateTimeOperand>
  afterPrecision?: Maybe<DateTimePrecision>
  /** If the precision is DAY, for value use T00:00:00Z at the end of Date to make it ISO DateTime */
  beforeDateTime?: Maybe<DateTimeOperand>
  beforePrecision?: Maybe<DateTimePrecision>
  betweenEndPrecision?: Maybe<DateTimePrecision>
  /** If the precision is DAY, for value use T00:00:00Z at the end of Date to make it ISO DateTime */
  betweenEndTime?: Maybe<DateTimeOperand>
  betweenStartPrecision?: Maybe<DateTimePrecision>
  /** If the precision is DAY, for value use T00:00:00Z at the end of Date to make it ISO DateTime */
  betweenStartTime?: Maybe<DateTimeOperand>
  comparisonType: DateTimeComparisonEditorType
  lastUnit?: Maybe<DateTimeComparisonEditorLastUnit>
  lastValue?: Maybe<Scalars['Int']['output']>
  leftOperand: DateTimeOperand
  leftOperandManipulations?: Maybe<Array<DateTimeManipulation>>
  /** If the precision is DAY, for value use T00:00:00Z at the end of Date to make it ISO DateTime */
  onDateTime?: Maybe<DateTimeOperand>
  onPrecision?: Maybe<DateTimePrecision>
}

/**
 * If the workflow trigger time is Oct 25 4:30pm and the company start of the day offset is -300 which means the company day starts at 5am UTC:
 * last 24 hours: 10/24/2022 4:30PM - 10/25/2022 4:30PM.
 * last 1 day:    10/24/2022 5:00AM - 10/25/2022 4:30PM.
 * last 2 days:   10/23/2022 5:00AM - 10/25/2022 4:30PM.
 * last 1 week:   10/17/2022 5:00AM - 10/25/2022 4:30PM.
 * last 2 weeks:  10/10/2022 5:00AM - 10/25/2022 4:30PM.
 * last 1 month:  09/01/2022 5:00AM - 10/25/2022 4:30PM.
 * last 2 months: 08/01/2022 5:00AM - 10/25/2022 4:30PM.
 * last 1 year:   01/01/2021 5:00AM - 10/25/2022 4:30PM.
 * last 2 year:   01/01/2020 5:00AM - 10/25/2022 4:30PM.
 * current day:   10/25/2022 5:00AM - 10/26/2022 5:00AM (inclusive start and exclusive end).
 * previous day:  10/24/2022 5:00AM - 10/25/2022 5:00AM (inclusive start and exclusive end).
 * current week:  10/24/2022 5:00AM - 10/31/2022 5:00AM (inclusive start and exclusive end).
 */
export type WorkflowWidgetDateTimeComparisonEditorInput = {
  afterDateTime?: InputMaybe<DateTimeInput>
  afterPrecision?: InputMaybe<DateTimePrecision>
  beforeDateTime?: InputMaybe<DateTimeInput>
  beforePrecision?: InputMaybe<DateTimePrecision>
  betweenEndPrecision?: InputMaybe<DateTimePrecision>
  betweenEndTime?: InputMaybe<DateTimeInput>
  betweenStartPrecision?: InputMaybe<DateTimePrecision>
  betweenStartTime?: InputMaybe<DateTimeInput>
  comparisonType: DateTimeComparisonEditorType
  lastUnit?: InputMaybe<DateTimeComparisonEditorLastUnit>
  lastValue?: InputMaybe<Scalars['Int']['input']>
  leftOperand: DateTimeInput
  leftOperandManipulations?: InputMaybe<Array<DateTimeManipulationInput>>
  onDateTime?: InputMaybe<DateTimeInput>
  onPrecision?: InputMaybe<DateTimePrecision>
}

export type WorkflowWidgetDateTimeComparisonInput = {
  leftOperand: DateTimeInput
  leftOperandManipulations?: InputMaybe<Array<DateTimeManipulationInput>>
  operator: WorkflowWidgetConditionFilterDateTimeOperators
  rightOperandEnd: DateTimeInput
  rightOperandEndManipulations?: InputMaybe<Array<DateTimeManipulationInput>>
  rightOperandStart: DateTimeInput
  rightOperandStartManipulations?: InputMaybe<Array<DateTimeManipulationInput>>
}

export type WorkflowWidgetDelay = WorkflowWidget & {
  __typename?: 'WorkflowWidgetDelay'
  category: WorkflowWidgetCategory
  delayDateTime: DateTimeOperand
  delayManipulations?: Maybe<Array<DateTimeManipulation>>
  editor?: Maybe<WorkflowWidgetDelayEditor>
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetDelayExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetDelayEditor = {
  __typename?: 'WorkflowWidgetDelayEditor'
  delayDateTime: DateTimeOperand
  delayDurationUnit?: Maybe<DateTimeDelayUnit>
  delayDurationValue?: Maybe<Scalars['Int']['output']>
}

export type WorkflowWidgetDelayEditorInput = {
  delayDateTime: DateTimeInput
  delayDurationUnit?: InputMaybe<DateTimeDelayUnit>
  delayDurationValue?: InputMaybe<Scalars['Int']['input']>
}

export type WorkflowWidgetDelayInput = {
  delayDateTime: DateTimeInput
  delayManipulations?: InputMaybe<Array<DateTimeManipulationInput>>
}

export type WorkflowWidgetEnd = WorkflowWidget & {
  __typename?: 'WorkflowWidgetEnd'
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetEndExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetExecutionCount = {
  __typename?: 'WorkflowWidgetExecutionCount'
  emit?: Maybe<Array<WorkflowExecutionEmitCount>>
  endTime?: Maybe<Scalars['DateTime']['output']>
  error?: Maybe<Scalars['Int']['output']>
  startTime?: Maybe<Scalars['DateTime']['output']>
}

export type WorkflowWidgetExecutionLogParameters = {
  timeForBucket: Scalars['DateTime']['input']
  widgetId: Scalars['ID']['input']
}

export type WorkflowWidgetGeofence = WorkflowWidget & {
  __typename?: 'WorkflowWidgetGeofence'
  category: WorkflowWidgetCategory
  complementComparison: Scalars['Boolean']['output']
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  geofenceComparison: WorkflowWidgetGeofenceComparison
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetGeofenceExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetGeofenceComparison = {
  __typename?: 'WorkflowWidgetGeofenceComparison'
  leftOperand?: Maybe<GeofenceOperand>
  operator: WorkflowWidgetConditionGeofenceOperators
  rightOperandGeofence: GeoJsonInterface
  rightOperandReference?: Maybe<GeofenceReference>
}

export type WorkflowWidgetGeofenceComparisonInput = {
  leftOperand?: InputMaybe<GeofenceOperandInput>
  operator: WorkflowWidgetConditionGeofenceOperators
  rightOperandGeofence: GeoJsonInput
  rightOperandReference?: InputMaybe<GeofenceReferenceInput>
}

/** Specialized properties for Geofence widget */
export type WorkflowWidgetGeofenceInput = {
  complementComparison?: InputMaybe<Scalars['Boolean']['input']>
  geofenceComparison: WorkflowWidgetGeofenceComparisonInput
}

/** Specify the interface properties and pass the specialized properties in the designated widgetProperties */
export type WorkflowWidgetInput = {
  changeIssueSeverityWidgetProperties?: InputMaybe<WorkflowWidgetChangeIssueSeverityInput>
  closeIssueWidgetProperties?: InputMaybe<WorkflowWidgetCloseIssueInput>
  conditionWidgetProperties?: InputMaybe<WorkflowWidgetConditionInput>
  customNodeDagWidgetProperties?: InputMaybe<WorkflowWidgetCustomNodeDagInput>
  datasourceWidgetProperties?: InputMaybe<WorkflowWidgetDataSourceInput>
  delayWidgetProperties?: InputMaybe<WorkflowWidgetDelayInput>
  delayWidgetPropertiesEditor?: InputMaybe<WorkflowWidgetDelayEditorInput>
  geofenceWidgetProperties?: InputMaybe<WorkflowWidgetGeofenceInput>
  id: Scalars['ID']['input']
  label?: InputMaybe<Scalars['String']['input']>
  /** Input properties for Monitor widget */
  monitorWidgetProperties?: InputMaybe<WorkflowWidgetMonitorInput>
  openIssueWidgetProperties?: InputMaybe<WorkflowWidgetOpenIssueInput>
  reportGeneratorWidgetProperties?: InputMaybe<WorkflowWidgetReportGeneratorInput>
  sendEmailWidgetProperties?: InputMaybe<WorkflowWidgetSendEmailInput>
  sendPushNotificationWidgetProperties?: InputMaybe<WorkflowWidgetSendPushNotificationInput>
  sendSmsWidgetProperties?: InputMaybe<WorkflowWidgetSendSmsInput>
  toWidgetIds?: InputMaybe<Array<Scalars['ID']['input']>>
  type: WorkflowWidgetType
}

export enum WorkflowWidgetIssueType {
  Multitask = 'MULTITASK',
  Task = 'TASK',
}

export type WorkflowWidgetMonitor = WorkflowWidget & {
  __typename?: 'WorkflowWidgetMonitor'
  /** Category the widget belongs to */
  category: WorkflowWidgetCategory
  /** Monitoring comparison */
  comparison: WorkflowWidgetMonitorComparison
  /** Is monitoring comparison to be complemented */
  complementComparison: Scalars['Boolean']['output']
  /** Emitting behavior of the widget based on the aggregated comparison result */
  emitWhen: WorkflowWidgetMonitorEmitWhenType
  /** Workflow widget execution emit counts */
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  /** Workflow widget execution logs */
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  /** Widget ID */
  id: Scalars['ID']['output']
  /** Widget label in UI */
  label?: Maybe<Scalars['String']['output']>
  /** List of widget IDs of outward connections from monitor widget */
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  /** Widget type */
  type: WorkflowWidgetType
}

export type WorkflowWidgetMonitorExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetMonitorComparison = {
  __typename?: 'WorkflowWidgetMonitorComparison'
  /** Aggregate function to be applied to monitored values */
  aggregateFunction: WorkflowWidgetAggregateFunction
  /** Monitoring comparison operator */
  comparisonOperator: WorkflowWidgetConditionFilterNumericOperators
  /** Value to be compared against aggregated function result */
  comparisonRightOperand: Scalars['Float']['output']
  /** Time unit of duration for which to monitor incoming observations */
  monitorDurationUnit: DateTimeMonitorDurationUnit
  /** Time value of duration for which to monitor incoming observations */
  monitorDurationValue: Scalars['Int']['output']
  /** Property to be monitored */
  monitorProperty: Scalars['String']['output']
}

export enum WorkflowWidgetMonitorEmitWhenType {
  ComparisonBecomesFalse = 'COMPARISON_BECOMES_FALSE',
  ComparisonBecomesTrue = 'COMPARISON_BECOMES_TRUE',
  ComparisonTrue = 'COMPARISON_TRUE',
}

/** Specialized properties for Monitor widget */
export type WorkflowWidgetMonitorInput = {
  /** Aggregate function to be applied to monitored values */
  aggregateFunction: WorkflowWidgetAggregateFunction
  /** Monitoring comparison operator */
  comparisonOperator: WorkflowWidgetConditionFilterNumericOperators
  /** Value to be compared against aggregated function result */
  comparisonRightOperand: Scalars['Float']['input']
  /** Is monitoring comparison to be complemented */
  complementComparison?: InputMaybe<Scalars['Boolean']['input']>
  /** Emitting behavior of the widget based on the aggregated comparison result */
  emitWhen?: WorkflowWidgetMonitorEmitWhenType
  /** Time unit of duration for which to monitor incoming observations */
  monitorDurationUnit: DateTimeMonitorDurationUnit
  /** Time value of duration for which to monitor incoming observations */
  monitorDurationValue: Scalars['Int']['input']
  /** Property to be monitored */
  monitorProperty: Scalars['String']['input']
}

export type WorkflowWidgetMultitaskProcedureReference = {
  __typename?: 'WorkflowWidgetMultitaskProcedureReference'
  multitaskProcedureReference?: Maybe<IssueMultitaskProcedureReference>
}

export type WorkflowWidgetNumericComparison = {
  __typename?: 'WorkflowWidgetNumericComparison'
  leftOperand: FloatOperand
  operator: WorkflowWidgetConditionFilterNumericOperators
  rightOperand: FloatOperand
}

export type WorkflowWidgetNumericComparisonInput = {
  leftOperand: NumericOperandInput
  operator: WorkflowWidgetConditionFilterNumericOperators
  rightOperand: NumericOperandInput
}

export type WorkflowWidgetNumericRangeComparison = {
  __typename?: 'WorkflowWidgetNumericRangeComparison'
  leftOperand: FloatOperand
  operator: WorkflowWidgetConditionFilterNumericRangeOperators
  rightOperandEnd: FloatOperand
  rightOperandStart: FloatOperand
}

export type WorkflowWidgetNumericRangeComparisonInput = {
  leftOperand: NumericOperandInput
  operator: WorkflowWidgetConditionFilterNumericRangeOperators
  rightOperandEnd: NumericOperandInput
  rightOperandStart: NumericOperandInput
}

export type WorkflowWidgetOpenIssue = WorkflowWidget & {
  __typename?: 'WorkflowWidgetOpenIssue'
  assignToUser?: Maybe<UserOperand>
  category: WorkflowWidgetCategory
  dataCollection?: Maybe<WorkflowWidgetDataCollection>
  dataCollectionInputType?: Maybe<WorkflowWidgetDataCollectionType>
  descriptionFormatter?: Maybe<Scalars['String']['output']>
  dueAt?: Maybe<DateTimeOperand>
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  severity: Scalars['Int']['output']
  titleFormatter: Scalars['String']['output']
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetOpenIssueExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

/** Specialized properties for OpenIssue widget */
export type WorkflowWidgetOpenIssueInput = {
  assignToUser?: InputMaybe<UserInput>
  dataCollection?: InputMaybe<WorkflowWidgetDataCollectionInput>
  descriptionFormatter?: InputMaybe<Scalars['String']['input']>
  dueAt?: InputMaybe<DateTimeInput>
  severity: Scalars['Int']['input']
  titleFormatter: Scalars['String']['input']
}

export enum WorkflowWidgetReportFormats {
  Csv = 'CSV',
  Pdf = 'PDF',
}

export type WorkflowWidgetReportGenerator = WorkflowWidget & {
  __typename?: 'WorkflowWidgetReportGenerator'
  /** Filename attachment (without extension) */
  attachmentBasenameFormatter?: Maybe<Scalars['String']['output']>
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  format: WorkflowWidgetReportFormats
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  /** Required for CSV format */
  reportProperties?: Maybe<Array<Scalars['String']['output']>>
  schedule?: Maybe<WorkflowWidgetSchedule>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetReportGeneratorExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

/** Specialized properties for ReportGenerator widget */
export type WorkflowWidgetReportGeneratorInput = {
  /** Filename attachment (without extension) */
  attachmentBasenameFormatter?: InputMaybe<Scalars['String']['input']>
  format: WorkflowWidgetReportFormats
  /** Required for CSV format */
  reportProperties?: InputMaybe<Array<Scalars['String']['input']>>
  schedule?: InputMaybe<WorkflowWidgetScheduleInput>
}

export type WorkflowWidgetSchedule = {
  __typename?: 'WorkflowWidgetSchedule'
  cronFields: WorkflowWidgetScheduleRepeatCronFields
  dailyIncrement?: Maybe<Scalars['Int']['output']>
  monthlyIncrement?: Maybe<Scalars['Int']['output']>
  monthlyOnDay?: Maybe<Scalars['Int']['output']>
  monthlyOnWeek?: Maybe<Scalars['Int']['output']>
  monthlyOnWeekWeekday?: Maybe<WeekDay>
  monthlyType?: Maybe<MonthlyScheduleType>
  /**
   * Number of occurences
   * @deprecated Not supported yet.
   */
  repeatEndAfter?: Maybe<Scalars['Int']['output']>
  /**
   * Absolute date
   * @deprecated Not supported yet.
   */
  repeatEndAt?: Maybe<Scalars['DateTime']['output']>
  /**
   * Type of the end of the repeat (count or absolute time)
   * @deprecated Not supported yet.
   */
  repeatEndType?: Maybe<WorkflowWidgetScheduleRepeatEndType>
  repeatType: WorkflowScheduleType
  runAtUtcTime: Scalars['Time']['output']
  weeklyWeekdays?: Maybe<Array<WeekDay>>
  yearlyIncrement?: Maybe<Scalars['Int']['output']>
  yearlyOnDate?: Maybe<Scalars['Date']['output']>
}

export type WorkflowWidgetScheduleInput = {
  cronFields?: InputMaybe<WorkflowWidgetScheduleRepeatCronFieldsInput>
  dailyIncrement?: InputMaybe<Scalars['Int']['input']>
  monthlyIncrement?: InputMaybe<Scalars['Int']['input']>
  monthlyOnDay?: InputMaybe<Scalars['Int']['input']>
  monthlyOnWeek?: InputMaybe<Scalars['Int']['input']>
  monthlyOnWeekWeekday?: InputMaybe<WeekDay>
  monthlyType?: InputMaybe<MonthlyScheduleType>
  repeatType: WorkflowScheduleType
  runAtUtcTime: Scalars['Time']['input']
  weeklyWeekdays?: InputMaybe<Array<WeekDay>>
  yearlyIncrement?: InputMaybe<Scalars['Int']['input']>
  yearlyOnDate?: InputMaybe<Scalars['Date']['input']>
}

export type WorkflowWidgetScheduleRepeatCronField = {
  all?: Maybe<Scalars['Boolean']['output']>
  rangeEnd?: Maybe<Scalars['Int']['output']>
  rangeStart?: Maybe<Scalars['Int']['output']>
  value?: Maybe<Scalars['Int']['output']>
  values?: Maybe<Array<Scalars['Int']['output']>>
}

export type WorkflowWidgetScheduleRepeatCronFieldDayOfMonth =
  WorkflowWidgetScheduleRepeatCronField & {
    __typename?: 'WorkflowWidgetScheduleRepeatCronFieldDayOfMonth'
    all?: Maybe<Scalars['Boolean']['output']>
    any?: Maybe<Scalars['Boolean']['output']>
    increment?: Maybe<Scalars['Int']['output']>
    lastDay?: Maybe<Scalars['Boolean']['output']>
    rangeEnd?: Maybe<Scalars['Int']['output']>
    rangeStart?: Maybe<Scalars['Int']['output']>
    value?: Maybe<Scalars['Int']['output']>
    values?: Maybe<Array<Scalars['Int']['output']>>
  }

export type WorkflowWidgetScheduleRepeatCronFieldDayOfMonthInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>
  any?: InputMaybe<Scalars['Boolean']['input']>
  increment?: InputMaybe<Scalars['Int']['input']>
  lastDay?: InputMaybe<Scalars['Boolean']['input']>
  rangeEnd?: InputMaybe<Scalars['Int']['input']>
  rangeStart?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
  values?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type WorkflowWidgetScheduleRepeatCronFieldDayOfWeek =
  WorkflowWidgetScheduleRepeatCronField & {
    __typename?: 'WorkflowWidgetScheduleRepeatCronFieldDayOfWeek'
    all?: Maybe<Scalars['Boolean']['output']>
    any?: Maybe<Scalars['Boolean']['output']>
    rangeEnd?: Maybe<Scalars['Int']['output']>
    rangeStart?: Maybe<Scalars['Int']['output']>
    value?: Maybe<Scalars['Int']['output']>
    values?: Maybe<Array<Scalars['Int']['output']>>
    weekOfMonth?: Maybe<Scalars['Int']['output']>
  }

export type WorkflowWidgetScheduleRepeatCronFieldDayOfWeekInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>
  any?: InputMaybe<Scalars['Boolean']['input']>
  rangeEnd?: InputMaybe<Scalars['Int']['input']>
  rangeStart?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
  values?: InputMaybe<Array<Scalars['Int']['input']>>
  weekOfMonth?: InputMaybe<Scalars['Int']['input']>
}

export type WorkflowWidgetScheduleRepeatCronFieldHours =
  WorkflowWidgetScheduleRepeatCronField & {
    __typename?: 'WorkflowWidgetScheduleRepeatCronFieldHours'
    all?: Maybe<Scalars['Boolean']['output']>
    increment?: Maybe<Scalars['Int']['output']>
    rangeEnd?: Maybe<Scalars['Int']['output']>
    rangeStart?: Maybe<Scalars['Int']['output']>
    value?: Maybe<Scalars['Int']['output']>
    values?: Maybe<Array<Scalars['Int']['output']>>
  }

export type WorkflowWidgetScheduleRepeatCronFieldHoursInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>
  increment?: InputMaybe<Scalars['Int']['input']>
  rangeEnd?: InputMaybe<Scalars['Int']['input']>
  rangeStart?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
  values?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type WorkflowWidgetScheduleRepeatCronFieldMinutes =
  WorkflowWidgetScheduleRepeatCronField & {
    __typename?: 'WorkflowWidgetScheduleRepeatCronFieldMinutes'
    all?: Maybe<Scalars['Boolean']['output']>
    increment?: Maybe<Scalars['Int']['output']>
    rangeEnd?: Maybe<Scalars['Int']['output']>
    rangeStart?: Maybe<Scalars['Int']['output']>
    value?: Maybe<Scalars['Int']['output']>
    values?: Maybe<Array<Scalars['Int']['output']>>
  }

export type WorkflowWidgetScheduleRepeatCronFieldMinutesInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>
  increment?: InputMaybe<Scalars['Int']['input']>
  rangeEnd?: InputMaybe<Scalars['Int']['input']>
  rangeStart?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
  values?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type WorkflowWidgetScheduleRepeatCronFieldMonth =
  WorkflowWidgetScheduleRepeatCronField & {
    __typename?: 'WorkflowWidgetScheduleRepeatCronFieldMonth'
    all?: Maybe<Scalars['Boolean']['output']>
    increment?: Maybe<Scalars['Int']['output']>
    rangeEnd?: Maybe<Scalars['Int']['output']>
    rangeStart?: Maybe<Scalars['Int']['output']>
    value?: Maybe<Scalars['Int']['output']>
    values?: Maybe<Array<Scalars['Int']['output']>>
  }

export type WorkflowWidgetScheduleRepeatCronFieldMonthInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>
  increment?: InputMaybe<Scalars['Int']['input']>
  rangeEnd?: InputMaybe<Scalars['Int']['input']>
  rangeStart?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
  values?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type WorkflowWidgetScheduleRepeatCronFieldYear =
  WorkflowWidgetScheduleRepeatCronField & {
    __typename?: 'WorkflowWidgetScheduleRepeatCronFieldYear'
    all?: Maybe<Scalars['Boolean']['output']>
    increment?: Maybe<Scalars['Int']['output']>
    rangeEnd?: Maybe<Scalars['Int']['output']>
    rangeStart?: Maybe<Scalars['Int']['output']>
    value?: Maybe<Scalars['Int']['output']>
    values?: Maybe<Array<Scalars['Int']['output']>>
  }

export type WorkflowWidgetScheduleRepeatCronFieldYearInput = {
  all?: InputMaybe<Scalars['Boolean']['input']>
  increment?: InputMaybe<Scalars['Int']['input']>
  rangeEnd?: InputMaybe<Scalars['Int']['input']>
  rangeStart?: InputMaybe<Scalars['Int']['input']>
  value?: InputMaybe<Scalars['Int']['input']>
  values?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type WorkflowWidgetScheduleRepeatCronFields = {
  __typename?: 'WorkflowWidgetScheduleRepeatCronFields'
  dayOfMonth?: Maybe<WorkflowWidgetScheduleRepeatCronFieldDayOfMonth>
  dayOfWeek?: Maybe<WorkflowWidgetScheduleRepeatCronFieldDayOfWeek>
  hours?: Maybe<WorkflowWidgetScheduleRepeatCronFieldHours>
  minutes?: Maybe<WorkflowWidgetScheduleRepeatCronFieldMinutes>
  month?: Maybe<WorkflowWidgetScheduleRepeatCronFieldMonth>
  year?: Maybe<WorkflowWidgetScheduleRepeatCronFieldYear>
}

export type WorkflowWidgetScheduleRepeatCronFieldsInput = {
  dayOfMonth?: InputMaybe<WorkflowWidgetScheduleRepeatCronFieldDayOfMonthInput>
  dayOfWeek?: InputMaybe<WorkflowWidgetScheduleRepeatCronFieldDayOfWeekInput>
  hours?: InputMaybe<WorkflowWidgetScheduleRepeatCronFieldHoursInput>
  minutes?: InputMaybe<WorkflowWidgetScheduleRepeatCronFieldMinutesInput>
  month?: InputMaybe<WorkflowWidgetScheduleRepeatCronFieldMonthInput>
  year?: InputMaybe<WorkflowWidgetScheduleRepeatCronFieldYearInput>
}

export enum WorkflowWidgetScheduleRepeatEndType {
  EndAt = 'END_AT',
  EndCount = 'END_COUNT',
}

export type WorkflowWidgetSendEmail = WorkflowWidget & {
  __typename?: 'WorkflowWidgetSendEmail'
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  /**
   * **Description**
   * The holder of [IANA timezone](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones),
   * by which all ISO 8601 timestamp properties found within message will be converted into its
   * proper form.
   *
   * **Example**
   *
   * 1. if this field is set as **America/Toronto**, then **2020-02-10T20:33:44Z** will be converted
   *   to **2020-02-10T15:33:44 EST** or **2020-02-10T15:33:44 EDT**
   * 2. if it is not set or set with an invalid IANA timezone, all ISO 8601 timestamp properties
   *   if any will be kept as is.
   */
  formatterTimezone?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  messageFormatter: Scalars['String']['output']
  recipients: Array<Scalars['String']['output']>
  subjectFormatter: Scalars['String']['output']
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetSendEmailExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetSendEmailInput = {
  /**
   * **Description**
   * The holder of [IANA timezone](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones),
   * by which all ISO 8601 timestamp properties found within message will be converted into its
   * proper form.
   *
   * **Example**
   *
   * 1. if this field is set as **America/Toronto**, then **2020-02-10T20:33:44Z** will be converted
   *   to **2020-02-10T15:33:44 EST** or **2020-02-10T15:33:44 EDT**
   * 2. if it is not set or set with an invalid IANA timezone, all ISO 8601 timestamp properties
   *   if any will be kept as is.
   */
  formatterTimezone?: InputMaybe<Scalars['String']['input']>
  messageFormatter: Scalars['String']['input']
  recipients: Array<Scalars['String']['input']>
  subjectFormatter: Scalars['String']['input']
}

export type WorkflowWidgetSendPushNotification = WorkflowWidget & {
  __typename?: 'WorkflowWidgetSendPushNotification'
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  messageFormatter: Scalars['String']['output']
  recipients: Array<UserOperand>
  titleFormatter: Scalars['String']['output']
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetSendPushNotificationExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

/** Specialized properties for SendPushNotification widget */
export type WorkflowWidgetSendPushNotificationInput = {
  messageFormatter: Scalars['String']['input']
  recipients: Array<UserInput>
  titleFormatter: Scalars['String']['input']
}

export type WorkflowWidgetSendSms = WorkflowWidget & {
  __typename?: 'WorkflowWidgetSendSms'
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  messageFormatter: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetSendSmsExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

/** Specialized properties for SendSms widget */
export type WorkflowWidgetSendSmsInput = {
  messageFormatter: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export type WorkflowWidgetSplit = WorkflowWidget & {
  __typename?: 'WorkflowWidgetSplit'
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetSplitExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetStart = WorkflowWidget & {
  __typename?: 'WorkflowWidgetStart'
  category: WorkflowWidgetCategory
  executionCounts?: Maybe<Array<WorkflowWidgetExecutionCount>>
  executionLogs?: Maybe<Scalars['JSONObject']['output']>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  toWidgetIds?: Maybe<Array<Scalars['ID']['output']>>
  type: WorkflowWidgetType
}

export type WorkflowWidgetStartExecutionLogsArgs = {
  timeForBucket: Scalars['DateTime']['input']
}

export type WorkflowWidgetStringComparison = {
  __typename?: 'WorkflowWidgetStringComparison'
  leftOperand: StringOperand
  operator: WorkflowWidgetConditionFilterStringOperators
  rightOperand: StringOperand
}

export type WorkflowWidgetStringComparisonInput = {
  leftOperand: StringOperandInput
  operator: WorkflowWidgetConditionFilterStringOperators
  rightOperand: StringOperandInput
}

export type WorkflowWidgetTaskDataCollectionFormReference = {
  __typename?: 'WorkflowWidgetTaskDataCollectionFormReference'
  taskDataCollectionFormReference?: Maybe<IssueTaskDataCollectionFormReference>
}

export enum WorkflowWidgetType {
  ActionChangeIssueSeverity = 'ACTION_CHANGE_ISSUE_SEVERITY',
  ActionCloseIssue = 'ACTION_CLOSE_ISSUE',
  ActionDelay = 'ACTION_DELAY',
  ActionOpenIssue = 'ACTION_OPEN_ISSUE',
  ActionSendEmail = 'ACTION_SEND_EMAIL',
  ActionSendPushNotification = 'ACTION_SEND_PUSH_NOTIFICATION',
  ActionSendSms = 'ACTION_SEND_SMS',
  DevelopmentCustomNodeDag = 'DEVELOPMENT_CUSTOM_NODE_DAG',
  FilterCondition = 'FILTER_CONDITION',
  FilterGeofence = 'FILTER_GEOFENCE',
  /** Monitor widget */
  FilterMonitor = 'FILTER_MONITOR',
  FlowEnd = 'FLOW_END',
  FlowSplit = 'FLOW_SPLIT',
  FlowStart = 'FLOW_START',
  SourceDatasource = 'SOURCE_DATASOURCE',
  TransformReportGenerator = 'TRANSFORM_REPORT_GENERATOR',
}

export type Workflows = {
  __typename?: 'Workflows'
  all?: Maybe<WorkflowsConnection>
  byId?: Maybe<Workflow>
}

export type WorkflowsAllArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<WorkflowFilter>
  first?: InputMaybe<Scalars['Int']['input']>
}

export type WorkflowsByIdArgs = {
  id: Scalars['ID']['input']
}

export type WorkflowsConnection = {
  __typename?: 'WorkflowsConnection'
  edges?: Maybe<Array<Maybe<WorkflowEdge>>>
  pageInfo: PageInfo
}

export type XFormBind = {
  __typename?: 'XFormBind'
  calculate?: Maybe<Scalars['XFormXPath']['output']>
  constraint?: Maybe<Scalars['XFormXPath']['output']>
  nodeset: Scalars['String']['output']
  readonly?: Maybe<Scalars['XFormXPath']['output']>
  relevant?: Maybe<Scalars['XFormXPath']['output']>
  required?: Maybe<Scalars['XFormXPath']['output']>
  saveIncomplete?: Maybe<Scalars['XFormXPath']['output']>
  type: XFormDataType
}

export type XFormBodyControlNode = XFormBodyNode & {
  __typename?: 'XFormBodyControlNode'
  /** For the <upload> element. As from the XForms 2.0 wiki: “comma-separated list of suggested media types and file extensions used to determine the possible sources of data to upload.” */
  accept?: Maybe<Array<Scalars['String']['output']>>
  appearance?: Maybe<Scalars['String']['output']>
  control?: Maybe<XFormFormControl>
  eventHandlers?: Maybe<Array<XFormEventHandler>>
  hint?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  items?: Maybe<Array<XFormItem>>
  itemset?: Maybe<XFormItemset>
  label?: Maybe<Scalars['String']['output']>
  /** For the <upload> element. The string value specifies the kind of media picker that will be displayed. Unlike in XForms 1.0, only one value can be specified. Possible values vary by client and examples include image/*, audio/* and video/*. Ignored if accept is also specified. */
  mediatype?: Maybe<Scalars['String']['output']>
  nodeset?: Maybe<Scalars['XFormRef']['output']>
  ref?: Maybe<Scalars['XFormRef']['output']>
}

export type XFormBodyGroupNode = XFormBodyNode & {
  __typename?: 'XFormBodyGroupNode'
  appearance?: Maybe<Scalars['String']['output']>
  childNodeIds?: Maybe<Array<Scalars['ID']['output']>>
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  nodeset?: Maybe<Scalars['XFormRef']['output']>
  ref?: Maybe<Scalars['XFormRef']['output']>
}

export type XFormBodyNode = {
  id: Scalars['ID']['output']
  nodeset?: Maybe<Scalars['XFormRef']['output']>
  ref?: Maybe<Scalars['XFormRef']['output']>
}

export enum XFormDataType {
  Barcode = 'barcode',
  Binary = 'binary',
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'dateTime',
  Decimal = 'decimal',
  Geopoint = 'geopoint',
  Geoshape = 'geoshape',
  Geotrace = 'geotrace',
  Int = 'int',
  Intent = 'intent',
  String = 'string',
  Time = 'time',
}

export type XFormDetails = {
  __typename?: 'XFormDetails'
  binds?: Maybe<Array<XFormBind>>
  body?: Maybe<Array<XFormBodyNode>>
  eventHandlers?: Maybe<Array<XFormEventHandler>>
  /** @deprecated Use model instead */
  instances: Array<Scalars['XFormXMLNode']['output']>
  model: Scalars['XFormXMLNode']['output']
}

export enum XFormEvent {
  OdkInstanceFirstLoad = 'ODK_INSTANCE_FIRST_LOAD',
  XformsValueChanged = 'XFORMS_VALUE_CHANGED',
}

export type XFormEventHandler = {
  __typename?: 'XFormEventHandler'
  eventType?: Maybe<XFormEvent>
  ref: Scalars['String']['output']
  staticValue?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['XFormXPath']['output']>
}

export enum XFormFormControl {
  Input = 'INPUT',
  OdkRank = 'ODK_RANK',
  Range = 'RANGE',
  Select = 'SELECT',
  Select1 = 'SELECT1',
  Trigger = 'TRIGGER',
  Upload = 'UPLOAD',
}

export type XFormItem = {
  __typename?: 'XFormItem'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type XFormItemset = {
  __typename?: 'XFormItemset'
  labelRef: Scalars['String']['output']
  nodeset?: Maybe<Scalars['XFormXPath']['output']>
  valueRef: Scalars['String']['output']
}

export type CreateIssueTaskResponsesViewMutationError = MutationError & {
  __typename?: 'createIssueTaskResponsesViewMutationError'
  message: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type CreateIssueTaskResponsesViewMutationResult = {
  __typename?: 'createIssueTaskResponsesViewMutationResult'
  correlationId: Scalars['ID']['output']
  downloadUrl: Scalars['String']['output']
  errors?: Maybe<Array<CreateIssueTaskResponsesViewMutationError>>
}

export type DataCollectionFormFilter = {
  isActive?: InputMaybe<Array<Scalars['Boolean']['input']>>
  subjectAssetProfile?: InputMaybe<Scalars['ID']['input']>
}

/** Type defining a list source that is used to resolve values for list type property in the data collection form */
export type PropertyValueListSource = {
  __typename?: 'propertyValueListSource'
  /** Id of the source that is used to resolve values for list type property in the data collection form */
  formListId: Scalars['ID']['output']
  /** Id of a list type property that is referenced in the data collection form */
  property: Scalars['String']['output']
  /** Type of the source that is used to resolve values for list type property in the data collection form */
  type?: Maybe<PropertyValueListSourceType>
}

/** Input type defining a list source that is used to resolve values for list type property in the data collection form */
export type PropertyValueListSourceInput = {
  /** Id of the source that is used to resolve values for list type property in the data collection form */
  formListId: Scalars['ID']['input']
  /** Id of a list type property that is referenced in the data collection form */
  property: Scalars['String']['input']
  /** Type of the source that is used to resolve values for list type property in the data collection form */
  type?: InputMaybe<PropertyValueListSourceType>
}

export type PutAssetProfileExtensionInput = {
  catalogId: Scalars['ID']['input']
  commandId?: InputMaybe<Scalars['String']['input']>
  dataset: Scalars['String']['input']
  profile: Scalars['ID']['input']
  properties: Array<Scalars['String']['input']>
}

export type RemoveAssetProfileExtensionInput = {
  catalogId: Scalars['ID']['input']
  commandId?: InputMaybe<Scalars['String']['input']>
  dataset: Scalars['String']['input']
  profile: Scalars['ID']['input']
}

export type SiteFeatureUserPropertiesKey = {
  __typename?: 'siteFeatureUserPropertiesKey'
  value: Scalars['String']['output']
}

export type TaskDataCollectionJsonFormInput = {
  schema: Scalars['JSONObject']['input']
  title: Scalars['String']['input']
  uischema?: InputMaybe<Scalars['JSONObject']['input']>
}
